import { processingJobs as processingJobsAPI, stroot } from '@birdi/js-sdk';
import { format, parseISO } from 'date-fns';
import { omitBy } from 'lodash-es';
import {
  action, autorun, computed, makeObservable, observable,
} from 'mobx';
import { createContext } from 'react';
import { Subject } from 'rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';

export interface ProcessingJobTableItem {
  id: string,
  selected: boolean,
  missionName: string,
  missionId: string,
  name: string,
  processingDate: string,
  gpCount: number,
  gcpIncluded: string,
}

export interface ProcessingJobTableFilter {
  start: number,
  limit: number,
  mission: string | null,
  objective: string | null,
  state?: string,
  dates: [string, string] | null,
  billable: boolean,
}

export class ProcessingState {
  fetching = false;
  items: ProcessingJobTableItem[] = [];
  totalCount = 0;
  totalMissions = 0;
  totalGpCount = 0;
  filters: ProcessingJobTableFilter;
  filteredFetchStream = new Subject<void>();

  constructor() {
    this.filters = {
      start: 1,
      limit: 10,
      mission: null,
      objective: null,
      dates: this.getMonthStartAndEndDates(),
      billable: true,
    };
    makeObservable(
      this,
      {
        fetching: observable,
        items: observable,
        totalCount: observable,
        totalMissions: observable,
        totalGpCount: observable,
        filters: observable,
        updateFilters: action,
        toggleTableItemsSelection: action,
        areAllItemsSelected: computed,
        someItemsSelected: computed,
      },
    );

    this.filteredFetchStream.pipe(
      debounceTime(250), // fires when 250ms passes with no additional calls
      switchMap(() => {
        this.fetching = true;
        return this.fetchProcessingJobs();
      }), // emits the resolved latest value only
      map((response) => {
        this.items = this.formatProcessingJobs(response.body.items);
        this.totalMissions = response.body.totalMissions;
        this.totalCount = response.body.totalCount;
        this.totalGpCount = response.body.totalGpCount;
        this.fetching = false;
      }), // perform side effect
    ).subscribe();

    // trigger immediately and whenever filters changes
    autorun(() => {
      if (this.filters) {
        this.filteredFetchStream.next();
      }
    });
  }

  fetchProcessingJobs = async (filters?: Partial<ProcessingJobTableFilter>) => {
    const cleanedFilters = omitBy(filters || this.filters, (v) => v === '' || v === null);
    const response = await processingJobsAPI.getJobs(stroot('bae8zi'), cleanedFilters);
    return response;
  };

  formatProcessingJobs = (items: processingJobsAPI.ProcessingJob[]): ProcessingJobTableItem[] => {
    const formattedData = items.map((d) => {
      let gcpIncluded = 'No';
      /*
      * requestConfig is for newer jobs. Older ones use processingConfig.
      */
      if (d.requestConfig?.config) {
        gcpIncluded = d.requestConfig.config.useReferences ? 'Yes' : 'No';
      } else {
        gcpIncluded = d.processingConfig.useGroundReferencePoints ? 'Yes' : 'No';
      }
      let name = `${d.name} | ${d.id}`;
      if (d.objective) {
        name = `${d.objective.name} | ${name}`;
      }
      return {
        id: d.id,
        selected: false,
        missionName: d.mission.name,
        missionId: d.mission.id,
        publishableState: d.publishableState,
        name,
        processingDate: format(parseISO(d.stateUpdatedAt), 'dd/MM/yy HH:mm:ss'),
        gpCount: Number(d.gigapixelCount),
        gcpIncluded,
      };
    });
    return formattedData;
  };

  updateFilters = (newFilters: Partial<ProcessingJobTableFilter>): void => {
    this.filters = {
      ...this.filters,
      ...newFilters,
    };
  };

  get areAllItemsSelected(): boolean {
    return this.items.every((item) => item.selected);
  }

  get someItemsSelected(): boolean {
    return this.items.some((item) => item.selected);
  }

  toggleTableItemsSelection = (): void => {
    const { areAllItemsSelected } = this;
    this.items = this.items.map((item) => ({
      ...item,
      selected: !areAllItemsSelected,
    }));
  };

  updateTableItem = (
    id: string,
    values: Partial<ProcessingJobTableItem>,
  ): void => {
    this.items = this.items.map((item) => {
      if (id === item.id) {
        return {
          ...item,
          ...values,
        };
      }
      return item;
    });
  };

  getMonthStartAndEndDates = (): [string, string] => {
    const now = new Date();
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1);
    const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    return [format(firstDay, 'yyyy/MM/dd'), format(lastDay, 'yyyy/MM/dd')];
  };
}

export const ProcessingContext = createContext<ProcessingState>(null);

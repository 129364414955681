import React from 'react';
import { css } from '@emotion/react';
import AmexSVG from '@birdi/icons/card-brands/amex.svg';
import MastercardSVG from '@birdi/icons/card-brands/mastercard.svg';
import VisaSVG from '@birdi/icons/card-brands/visa.svg';

const icons = {
  mastercard: MastercardSVG,
  visa: VisaSVG,
  amex: AmexSVG,
};

export interface CardBrandProps {
  brand: string
}

export const CardBrand = ({ brand }: CardBrandProps) => {
  const Icon = icons[brand as keyof typeof icons];
  if (Icon) return <Icon height="24px" width="35px" />;

  return (
    <span
      css={css`
        background-color: #11249B;
        color: white;
        padding: 0.25em 0.5em;
        border-radius: 4px;
        font-size: 0.8em;
        text-transform: uppercase;
        font-weight: 700;
      `}
    >
      {brand}
    </span>
  );
};

/* global confirm */

import React from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { ContextMenu, contextStyles } from '@birdi/context-menu/ContextMenu';
import TrashSVG from '@birdi/icons/trash.svg';
import { EquipmentIcon } from './equipment';
import { useDispatch } from 'react-redux';

export const types = {
  Drone: 'Drone',
  Battery: 'Battery',
  Camera: 'Camera',
  'Surveying': 'Surveying',
};

const cardCss = `
  position: relative;
  display: inline-block;
  width: 100%;
  box-sizing: border-box;
  margin: 0 1rem 1rem 0;
  box-shadow: rgba(9, 30, 66, 0.1) 0px 1px 1px, rgba(9, 30, 66, 0.16) 0px 0px 1px 0px;
  border-radius: 4px;
  transition: 0.15s shadow, 0.15s transform;
  @media (min-width: ${breakpoints.xs}) {
    width: calc((100% / 2) - 1rem);
  }
  @media (min-width: ${breakpoints.s}) {
    width: calc((100% / 3) - 1rem);
  }
  @media (min-width: ${breakpoints.l}) {
    width: calc((100% / 4) - 1rem);
  }
`;

function getModelName(item) {
  if (item.model && !item.model.manufacturer) return item.model.model; // custom equipment
  return `${item.model.manufacturer} ${item.model.model}`;
}

export const EquipmentCard = ({
  item,
  deleteEquipment,
  handleCardClick,
  linkToEquipmentPage = true,
}) => {
  const dispatch = useDispatch();
  return (
    <div css={css(cardCss)}>
      <div
        css={css`
          height: 25rem;
          display: flex;
          position: relative;
          flex-direction: column;
          justify-content: flex-start;
          cursor: pointer;`}
        onClick={(event) => {
          if (event.preventCardClick === true || !linkToEquipmentPage) {
            // This is needed because it seems the property on the event persists
            event.preventCardClick = false;
            return;
          }
          handleCardClick();
        }}
      >
        <EquipmentIcon equipment={item} />
        <div css={css`position: relative;`}>
          <div
            css={theme => css`
            margin: 1em;
            overflow: hidden;
            h3, p {
              margin: 0;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            h3 { line-height: 1.5em; }
            p {
              color: ${theme.mono50};
              line-height: 2em;
            }
            width: 85%;
            `}
          >
            <h3>{getModelName(item)}</h3>
            <p>{types[item.model.type]}</p>
            {item.batteryCount
              && <p>{`Batteries: ${item.batteryCount}`}</p>}
            {item.organisation && item.organisation.name
              && <p>{`Organisation: ${item.organisation.name}`}</p>}
            {item.model.attributes && item.model.attributes.length > 0 && (
              item.model.attributes.map((attribute) =>
            <p key={attribute}>{attribute}</p>)
            )}
          </div>
          <div css={css`
          position: absolute;
          top: 0.7rem;
          right: 0.5rem;`}
          onClick={(event) => {
            // See: preventCardClick in this component:
            // Required for opening modal through portal within component
            event.preventCardClick = true;
          }}
          >
            <ContextMenu alignRight>
              {() => (
                <div css={theme => css(contextStyles(theme))}>
                  <button
                    css={css`display: flex;`}
                    type="button"
                    onClick={() => (confirm('Are you sure you want to remove this item?')
                && deleteEquipment(dispatch, item.id))}
                  >
                    <span><TrashSVG /></span>
                    <span>Delete</span>
                  </button>
                </div>
              )}
            </ContextMenu>
          </div>
        </div>
      </div>
    </div>
  );
}

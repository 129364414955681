/* global fetch */
import querystring from 'querystring';
import { config } from '../config';
import { headers, stleaf, fetchAPIJSON, SpanTag } from '../helpers';

export const get = (st: SpanTag, opts) => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  const url = new URL(`${config.API_URL}/v1/organisation/equipment/${query}`);
  return fetchAPIJSON(stleaf(st, 'ohp7sh'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Select from 'react-select';
import { css, useTheme } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import PlayCircleSVG from '@birdi/icons/play-circle.svg';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import VideoMidnightSVG from '@birdi/icons/video-midnight.svg';
import { assessmentType as assessmentTypeAPI, stroot } from '@birdi/js-sdk';
import { contextualHeader, pageDescription } from '@birdi/theme/blocks';
import BackSVG from '@birdi/icons/arrow-left.svg';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import UploadSVG from '@birdi/icons/upload.svg';
import DownloadSVG from '@birdi/icons/download.svg';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { reactSelect } from '@birdi/theme/form';
import { hasPermission } from '@birdi/utils/src/helpers';
import { NewAssessmentTypeModal } from './new-type-modal';
import { CategoryContainer } from './category-container';
import { AssessmentTypeOption, KNOWLEDGE_BASE_LINK } from './utils';
import { CategoryContext } from './index';
import { useMetaSelector } from '../meta/reducer';
import { INTERCOM_TOUR_CODES } from '../common/consts';
import { BackBar } from '../common/HeaderV2/BackBar';

export const AssessmentTypeComponent: React.FunctionComponent = () => {
  const { search } = useLocation();
  const birdiTheme = useTheme();
  const meta = useMetaSelector();
  const uploadFileRef = useRef(null);
  const hasOpsAccess = hasPermission(meta.opsActions, 'access_ops');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [assessmentTypeList, setAssessmentTypeList] = useState<AssessmentTypeOption[]>([]);
  const {
    selectedAssessmentType,
    setSelectedAssessmentType,
    defaultPath,
    setFullPath,
    nameError,
  } = useContext(CategoryContext);

  // get list of assessment types on page load
  useEffect(() => {
    assessmentTypeAPI.getAssessmentTypeList(stroot('hik8ai'))
      .then((res) => {
        if (res.body) {
          const options = res.body.map((item) => (
            { label: item.name, value: item.id }
          ));
          setAssessmentTypeList(options);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    const id = searchParams.get('id');
    if (hasOpsAccess && id) {
      assessmentTypeAPI.getAssessmentTypeById(stroot('uetaw4'), id)
        .then((res) => {
          const item = res.body;
          if (item) {
            setSelectedAssessmentType({
              label: `${item.name} (${item.organisation.name})`,
              value: item.id,
            });
          }
        }).catch((err) => console.error(err));
    }
  }, [hasOpsAccess]);

  const newTypeAdded = (newType: { id: string, name: string }) => {
    const newAssessmentType = {
      label: newType.name,
      value: newType.id,
    };
    setFullPath(defaultPath);
    setAssessmentTypeList((prev) => [...prev, newAssessmentType]);
    setSelectedAssessmentType(newAssessmentType);
  };

  const handleAddAssessmentType = (name: string) => {
    if (assessmentTypeList.some((item) => item.label === name)) {
      window.alert('An Assessment type with that name already exists');
      return;
    }
    assessmentTypeAPI.createAssessmentType(stroot('lai8th'), { name })
      .then((res) => {
        if (!res.body) throw Error('Error getting response when adding new assessment type');
        newTypeAdded(res.body);
      })
      .catch((err) => console.log(err));
  };
  const handleSelectAssessmentType = (option: AssessmentTypeOption) => {
    setSelectedAssessmentType(option);
    // Fully resets path after a new type is selected.
    setFullPath(defaultPath);
  };

  const validateCSV = async (file) => {
    // check file formate
    if (!file || file.type !== 'text/csv') {
      window.alert('Please upload a valid CSV file.');
      return false;
    }
    // check file name
    const fileName: string = file.name.replaceAll('.csv', '');
    if (assessmentTypeList.some((item) => item.label === fileName)) {
      window.alert('An Assessment type with this file name already exists.');
      return false;
    }
    // check columns
    const reader = new FileReader();
    reader.readAsText(file);
    const csvData: string = await new Promise((resolve) => {
      reader.onload = () => resolve(reader.result);
    });
    const rows = csvData.split('\n');
    const headers = rows[0].split(',');
    const expectedHeaders = ['Parent Category', 'Sub 1', 'Sub 2', 'Sub 3', 'Sub 4', 'Sub 5'];
    if (headers.length === 6 && headers.every((value, i) => value.replaceAll('\r', '') === expectedHeaders[i])) {
      // check category names
      const isCategoryNameValid = (value: string) => /^[a-zA-Z0-9\s]+$/ig.test(value);
      for (let i = 1; i < rows.length; i++) {
        const row = rows[i].split(',');
        for (let j = 0; j < 5; j++) {
          const categoryNameValidation = isCategoryNameValid(row[j]);
          if (row[j] && !categoryNameValidation) {
            alert(`Category names can only contain letters and numbers, so '${row[j]}' category name is invalid.`);
            return false;
          }
        }
      }
      return {
        name: fileName,
        categories: csvData,
      };
    }
    window.alert("CSV file needs to have 6 columns, and headers need to be as follow: 'Parent Category', 'Sub 1', 'Sub 2', 'Sub 3', 'Sub 4', 'Sub 5'.");
    return false;
  };

  const uploadCSV = async (event: Blob) => {
    const file = event.target.files[0];
    const validatedCSV = await validateCSV(file);
    if (!validatedCSV) return;
    assessmentTypeAPI.uploadAssessmentCSV(stroot('oosh2s'), validatedCSV)
      .then((res) => {
        if (!res.body) throw Error('Error getting response when uploading new assessment type');
        newTypeAdded(res.body);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div css={css`
      height: calc(100vh - 5rem);
      display: flex;
      flex-direction: column;`}
    >
      <Helmet>
        <style>{`body { background-color: ${birdiTheme.sand || 'hsl(30,11.1%,96.5%)'}; }`}</style>
      </Helmet>
      <BackBar to="/users" />
      <div css={(theme:BirdiTheme) => css`
        ${pageDescription(theme)};
        background-color: ${theme.mono0};
        border: solid 1px ${theme.mono30};
      `}
      >
        <div css={css`display: flex; align-items: center; gap: 2rem;`}>
          <h2 id="assessment-type">Assessment Type</h2>
          <button
            type="button"
            css={(theme) => css`
              ${buttonBlue(theme, { baseSize: 'sm' })};
              display: flex;
              justify-content: center;
              align-items: center;
            `}
            onClick={() => setModalOpen(true)}
          >
            + New assessment type
          </button>
        </div>
        <p>Set up categories to manage faults, defects or areas of interest to be used in an asset assessment.</p>
        <div>
          {/* <button
            type="button"
            onClick={() => {
              window?.Intercom && window.Intercom('startTour', INTERCOM_TOUR_CODES.ASSESSMENT);
            }}
          >
            <PlayCircleSVG />
            <span>Take a quick tour</span>
          </button> */}
          <a
            href="http://help.birdi.io/en/articles/6695800-how-to-create-an-assessment-type-and-start-an-assessment-of-your-assets-images"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn how Asset Assessment works</span>
          </a>
        </div>
      </div>
      <div
        css={(theme: BirdiTheme) => css`
          padding: 0 1.5rem;
          background: ${theme.sand};
          flex: 1;
          display: flex;
          flex-direction: column;
          overflow-y: hidden;
        `}
      >
        {modalOpen && (
          <NewAssessmentTypeModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            addAssessmentType={handleAddAssessmentType}
          />
        )}
        {assessmentTypeList.length === 0 && (
          <div>
            <p>
              You have not created any assessment types. To get started, select
              &nbsp;
              <b>+ New assessment type</b>
            </p>
            <p>
              To learn more about this feature please visit our
              <Link
                to={{ pathname: KNOWLEDGE_BASE_LINK }}
                target="_blank"
                css={(theme: BirdiTheme) => css`
                  color: ${theme.deepOcean};
                `}
              >
                &nbsp;
                knowledge base article.
              </Link>
              <ExternalLinkSVG css={css`font-size: 0.6rem; vertical-align: text-top;`} />
            </p>
          </div>
        )}
        {!!assessmentTypeList.length && (
          <div css={css`display: flex; flex-direction: column; flex: 1; overflow-y: hidden;`}>
            <div css={css`
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              @media (max-width: ${breakpoints.xs}) {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 1em;
              };
            `}
            >
              <div css={css`display: flex; gap: 0.5rem;`}>
                <Select
                  isMulti={false}
                  value={selectedAssessmentType}
                  options={assessmentTypeList}
                  onChange={(option) => {
                    if (option) handleSelectAssessmentType(option);
                  }}
                  placeholder="Select assessment type"
                  styles={{
                    ...reactSelect,
                    container: (base) => ({
                      ...base,
                      marginTop: '1rem',
                      marginBottom: '1rem',
                      width: '400px',
                    }),
                  }}
                />
                {nameError && (
                  <p css={(theme: BirdiTheme) => css`flex: 1; align-self: center; color: ${theme.crimsonSunbird};`}>Category name can only contain letters and numbers.</p>
                )}
              </div>
              <div css={(theme: BirdiTheme) => css`
                display: flex;
                flex-direction: row;
                align-items: center;
                button, a {
                  display: flex;
                  align-items: center;
                  color: ${theme.deepOcean};
                  span {
                    margin-left: 0.25rem;
                  }
                }
              `}
              >
                <input
                  hidden
                  type="file"
                  accept=".csv"
                  ref={uploadFileRef}
                  onChange={uploadCSV}
                  onClick={(event) => event.target.value = null}
                />
                <button
                  type="button"
                  onClick={() => uploadFileRef.current.click()}
                >
                  <UploadSVG />
                  <span>Upload</span>
                </button>
                {selectedAssessmentType && (
                  <a
                    href={`${process.env.API_URL}/v1/assessment-types/${selectedAssessmentType.value}/download-csv`}
                    css={css`margin-left: 2em`}
                  >
                    <DownloadSVG />
                    <span>Download Template</span>
                  </a>
                )}
              </div>
            </div>
            <CategoryContainer />
          </div>
        )}
      </div>
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { css } from '@emotion/react';
import * as Dialog from '@radix-ui/react-dialog';
import { contentCSS, portalCSS } from '@birdi/modal-kit';
import {
  BirdiTheme, buttonBlue, fonts, buttonOutlineBlue,
} from '@birdi/theme';
import { stroot, organisationMissions } from '@birdi/js-sdk';
import { reactSelect } from '@birdi/theme/form';
import CloseSVG from '@birdi/icons/close.svg';
import SpaceshipSVG from '@birdi/icons/illustration-spaceship.svg';
import AssessmentSVG from '@birdi/icons/illustration-assess-image.svg';
import history from '../state/history';

const cardHolderStyle = (theme: BirdiTheme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.mono0};
  border-radius: 7px;
  box-shadow: rgba(9,30,66,0.1) 0px 1px 1px, rgba(9,30,66,0.2) 1px 2px 7px -2px;
  color: inherit;
  margin: 0 0.5em;
  padding: 0.75em;
  width: 17rem;
  height: 19rem;
  h3 { margin: 0 }
`;
const cardInsideStyle = css`
  height: 14.5em;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    line-height: 1.25em;
    font-size: 0.8em;
  }
`;
const buttonStyle = (theme: BirdiTheme, color: string) => css`
  ${color === 'blue' ? buttonBlue(theme) : buttonOutlineBlue(theme)};
  width: 10em;
  height: 2.5em;
  border-radius: 3px;
  font-family: ${fonts.display};
`;

interface AssetAssessmentModalProps {
  modalOpen: boolean;
  setModalOpen: (modalState: boolean) => void;
}

export const AssetAssessmentModal: React.FunctionComponent<AssetAssessmentModalProps> = ({
  modalOpen,
  setModalOpen,
}) => {
  const [missionsList, setMissionsList] = useState([]);
  const [selectedMission, setSelectedMission] = useState(null);
  const [hoverStatus, setHoverStatus] = useState('');

  useEffect(() => {
    // get missions with media
    organisationMissions.getMissions(stroot('tee0fo'), { association: 'organisation' })
      .then((response) => {
        // set mission list state
        const missionWithMedia = response.body.items.filter((mission) => mission.mediaCount !== 0);
        const preparedList = missionWithMedia.map((mission) => ({
          label: mission.name,
          value: mission.id,
        }));
        setMissionsList(preparedList);
        // set last created mission with media as the default selected value
        if (missionWithMedia.length) {
          const sortedMissions = missionWithMedia.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB - dateA;
          });
          const newestMission = sortedMissions[0];
          setSelectedMission({
            label: newestMission.name,
            value: newestMission.id,
          });
        }
      });
  }, []);

  const handleCardHover = (value: string) => {
    if (!missionsList.length) {
      setHoverStatus('');
    } else {
      let status = '';
      switch (value) {
        case 'enter left':
          status = 'opaque right';
          break;
        case 'enter right':
          status = 'opaque left';
          break;
        default: // leave
          status = '';
          break;
      }
      setHoverStatus(status);
    }
  };

  return (
    <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <div css={portalCSS}>
          <Dialog.Content
            css={(theme) => css`
              ${contentCSS(theme)}
              overflow-y: visible;
              box-shadow: none;
              padding: 1em 0 0 0;
              width: 36em;
            `}
          >
            <Dialog.Title css={css`text-align: center; margin: 0`}>
              Create an assessment
            </Dialog.Title>
            <p css={(theme: BirdiTheme) => css`
              margin: 0.5em 0;
              color: ${theme.mono50};
              text-align: center;
            `}
            >
              Select an option below to get started:
            </p>
            <div css={(theme: BirdiTheme) => css`
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              padding: 1.5em;
              background-color: ${theme.mono20};
              border-radius: 0 0 7px 7px;
            `}
            >
              <div
                css={(theme: BirdiTheme) => css`
                  ${cardHolderStyle(theme)};
                  opacity: ${(!missionsList.length || (hoverStatus === 'opaque left')) ? 0.4 : 1}
                `}
                onMouseEnter={() => handleCardHover('enter left')}
                onMouseLeave={() => handleCardHover('leave left')}
              >
                <h3>Select an existing map</h3>
                <div css={cardInsideStyle}>
                  <AssessmentSVG css={css`font-size: 6em`} />
                  <p>Select an existing map below:</p>
                  <Select
                    styles={{
                      ...reactSelect,
                      valueContainer: (base) => ({
                        ...base,
                        paddingTop: 0,
                        paddingBottom: 0,
                        minWidth: '140px',
                        maxWidth: '140px',
                      }),
                      input: (base) => ({
                        ...base,
                        minWidth: '140px',
                        maxWidth: '140px',
                      }),
                      control: (base) => ({
                        ...base,
                        fontSize: '0.9em',
                      }),
                    }}
                    options={missionsList}
                    onChange={(option) => {
                      setSelectedMission(option);
                    }}
                    placeholder="map name ..."
                    value={selectedMission}
                    isDisabled={!missionsList.length || selectedMission === null}
                  />
                </div>
                <button
                  type="button"
                  css={(theme: BirdiTheme) => (hoverStatus === 'opaque left') ? buttonStyle(theme, 'white') : buttonStyle(theme, 'blue')}
                  onClick={() => {
                    history.push(`/map/missions/${selectedMission.value}/new-assessment`);
                  }}
                >
                  Start Assessment
                </button>
              </div>
              <div
                css={(theme: BirdiTheme) => css`
                  ${cardHolderStyle(theme)};
                  opacity: ${(hoverStatus === 'opaque right') ? 0.5 : 1}
                `}
                onMouseEnter={() => handleCardHover('enter right')}
                onMouseLeave={() => handleCardHover('leave right')}
              >
                <h3>Upload a new data set</h3>
                <div css={css`
                  ${cardInsideStyle};
                  max-width: 11em;
                  text-align: center;
                `}
                >
                  <SpaceshipSVG css={css`font-size: 6.5em`} />
                  <p css={css`margin: 0`}>Create a new map and upload your data set.</p>
                  <p>
                    <b>Don’t have drone data?</b>
                    {' '}
                    See how you can use Birdi with
                    {' '}
                    <span css={(theme: BirdiTheme) => css`
                      text-decoration: underline;
                      color: ${theme.deepOcean}
                    `}
                    >
                      <a
                        href="https://help.birdi.io/en/articles/7002460-uploading-media-from-different-capture-devices-for-visualisation"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ground-based photography
                      </a>
                    </span>
                    .
                  </p>
                </div>
                <button
                  type="button"
                  css={(theme: BirdiTheme) => (hoverStatus === 'opaque right') ? buttonStyle(theme, 'white') : buttonStyle(theme, 'blue')}
                  onClick={() => {
                    history.push('/map/missions/plan');
                  }}
                >
                  Upload Now
                </button>
              </div>
            </div>
            <div css={css`
              position: absolute;
              top: 1em;
              right: 1em;
            `}
            >
              <Dialog.Close>
                <CloseSVG />
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag
} from '../helpers';

export const getPilotJobRequests = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/job-requests/incoming`;
  return fetchAPI(stleaf(st, 'yi2iel'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getPilotJobRequest = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/job-requests/${id}/incoming`;
  return fetchAPI(stleaf(st, 'wach7l'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getClientJobRequest = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/job-requests/${id}/outgoing`;
  return fetchAPI(stleaf(st, 'iz0re2'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const pilotReject = (st: SpanTag, id: string, fieldsToUpdate): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/job-requests/${id}/incoming/reject`;
  return fetchAPI(stleaf(st, 'eex5ka'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const pilotAccept = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/job-requests/${id}/incoming/accept`;
  return fetchAPI(stleaf(st, 'aek6yu'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const clientAccept = (st: SpanTag, id: string, fieldsToUpdate): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/contracts/${id}/outgoing/accept`;
  return fetchAPI(stleaf(st, 'seu6et'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const clientReject = (st: SpanTag, id: string, fieldsToUpdate: { rejectionComment: string }): Promise<APIResponse> => {
    const url = `${config.API_URL}/v2/contracts/${id}/outgoing/reject`;
    return fetchAPI(stleaf(st, 'wev8ji'), url, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify(fieldsToUpdate),
    });
};

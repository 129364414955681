import { css } from '@emotion/react';
import { buttons, BirdiTheme } from '@birdi/theme';

export const portalCSS = css`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 9000;
  width: 100%;
  height: 100%;
  padding: 2em;
  box-sizing: border-box;
  background: hsl(0, 0%, 0%, 0.2);`;

// Shadow generated with:
// https://neumorphism.io/#e0e0e0
// Dialog is always in light mode
export const contentCSS = (theme: BirdiTheme) => css`
  position: relative;
  width: 36em;
  padding: 2em;
  max-height: 100%;
  overflow-y: auto;
  border-radius: 0.5em;
  box-sizing: border-box;
  background: white;
  box-shadow: 28px 28px 55px #0006,-28px -28px 55px #ffffff08;
  h1, h2, h3, h4 {
    color: ${theme.displayFont};
    margin-top: 0;
  }
  .error {
    color: ${theme.crimsonSunbird};
  }
  .success {
    color: ${theme.flora};
  }
  background: ${theme.mono0};
  color: ${theme.displayFont};
`;

export const closeButtonCSS = css`
  position: absolute;
  padding: 0;
  right: 2em;
  top: 2em;
`;

export const buttonFooterCSS = css`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
`;

export const paddedButtonFooterCSS = css`
  ${buttonFooterCSS};
  padding-top: 1.5rem;
`

export const closeTextButtonCSS = (theme) => css`
  ${buttons.textButton(theme)};
  min-width: 0;
`;

import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { BirdiTheme } from '@birdi/theme';
import ChevronLeftSVG from '@birdi/icons/chevron-left.svg';
import ChevronRightSVG from '@birdi/icons/chevron-right.svg';

const button = (theme: BirdiTheme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  color: ${theme.displayFont};
  border-radius: 2px;
  font-weight: 500;
  padding: 0.5em 0.75em;
  background: transparent;
  /* transition */

  &:hover {
    background: ${theme.mono0};
  }

  &:disabled {
    background: transparent;
    color: ${theme.midnight};
    cursor: default;
  }
`;

const buttonActive = (theme: BirdiTheme) => css`
  & {
    background: ${theme.mode === 'light' ? theme.lightOcean : theme.mono0};
    color: ${theme.displayFont};
  }
  &:hover {
    background: ${theme.mode === 'light' ? theme.mono20 : theme.mono0};
    color: ${theme.displayFont};
  }
  `;

const PaginationButton = ({
  active = false,
  disabled = false,
  value,
  onClick,
}: {
  active?: boolean;
  disabled?: boolean;
  value: React.ReactNode;
  onClick?(): void;
}) => (
  <button
    css={[button, active && buttonActive]}
    disabled={disabled}
    onClick={onClick}
  >
    {value}
  </button>
);

export const Pagination = ({
  windowSize = 3,
  edgeSize = 1,
  currentPage,
  itemCount,
  itemsPerPage,
  onChange,
}: PaginationProps) => {
  // TODO: fix for `windowSize` values !== 3, it doesn't properly center on the selected page.

  const totalPages = Math.ceil(itemCount / itemsPerPage);
  const pages = useMemo(() => Array.from({ length: totalPages }, (_, i) => i + 1), [totalPages]);
  // TODO: could probably combine this + the finder below, to possibly optimise
  // this? (ie. only create window )
  const pageWindows = useMemo(
    () =>
      Array.from(
        { length: pages.length - (windowSize - 1) },
        (_, i) => pages.slice(i, i + windowSize)
      ),
    [pages, windowSize]
  );
  // Find the current window, preferring the current page in the middle, but
  // falling back to a simple includes if needed.
  let window = useMemo(
    () =>
      pageWindows.find(([_, middle]) => middle === currentPage) ??
      pageWindows.find(arr => arr.includes(currentPage)) ??
      [currentPage],
    [pageWindows, currentPage]
  )

  let start: number[] = pages;
  let end: number[] = [];
  const smallAmount = pages.length <= windowSize;

  if (!smallAmount) {
    start = pages.slice(0, edgeSize);
    end = pages.slice(-edgeSize);
  }

  // Only get values in the window that aren't in the start or end.
  window = window.filter(n => !start.includes(n) && !end.includes(n))

  // Only show left dots if window doesn't intersect start, and isn't close to intersecting.
  const renderLeftDots =
    !!start.length &&
    !start.includes(currentPage) &&
    start[start.length - 1] !== window[0] - 1;
  // Same as `renderLeftDots`, but changed to work for the end values.
  const renderRightDots =
    !smallAmount &&
    !end.includes(currentPage) &&
    end[0] !== window[window.length - 1] + 1;
  const renderAnyDots = start[start.length - 1] !== end[0] - 1
  const canPrevious = currentPage > 1;
  const canNext = currentPage < totalPages;

  return (
    // Force the arrrows to the outside w/ numbers in the middle, so that they
    // don't jump around as the amount of pages shown changes.
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
      `}
    >
      <button
        css={button}
        disabled={!canPrevious}
        onClick={() => onChange(currentPage - 1)}
      >
        <ChevronLeftSVG />
      </button>

      <div css={css`display: flex; align-items: center;`}>
        {start.map((value) => (
          <PaginationButton
            key={value}
            value={value}
            active={value === currentPage}
            onClick={() => onChange(value)}
          />
        ))}

        {renderAnyDots && renderLeftDots && <PaginationButton value='...' disabled />}

        {window.map((value) => (
          <PaginationButton
            key={value}
            value={value}
            active={value === currentPage}
            onClick={() => onChange(value)}
          />
        ))}
        {renderAnyDots && renderRightDots && <PaginationButton value='...' disabled />}

        {end.map((value) => (
          <PaginationButton
            key={value}
            value={value}
            active={value === currentPage}
            onClick={() => onChange(value)}
          />
        ))}
      </div>

      <button
        css={button}
        disabled={!canNext}
        onClick={() => onChange(currentPage + 1)}
      >
        <ChevronRightSVG />
      </button>
    </div>
  );
};

export interface PaginationProps {
  /** Number of values shown around the selection (inclusive). */
  windowSize?: number;
  /** Number of values to show around the edges. */
  edgeSize?: number;
  /** The current page to show. 1-indexed. */
  currentPage: number;
  /** How many items there are in total. */
  itemCount: number;
  /** How many items are shown per page. */
  itemsPerPage: number;
  /** Called when switching pages. */
  onChange(page: number): void;
}

import { css } from '@emotion/react';
import { BirdiTheme } from './theme';
import { fonts } from './variables';

/**
 * This button is used to add resources on pages
 * @deprecated
 */
export const plusButton = (theme: BirdiTheme) => css`
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0.5rem;
  font-size: 1rem;
  color: ${theme.midnight};
  background: ${theme.mono20};
  white-space: nowrap;
  cursor: pointer;
  height: 2.5rem;
  &:focus {
    outline: 2px solid ${theme.mono40};
  }
  &:disabled {
    cursor: not-allowed;
    filter: brightness(0.5) invert(1);
  }
`;

/**
 * @deprecated
 */
export const primaryButton = (theme: BirdiTheme) => css`
  font-family: ${fonts.display};
  font-weight: bold;
  border-radius: 2px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  color: white;
  background: ${theme.deepOcean};
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  &:active {
    transform: translateY(0px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
  &:disabled {
    background: ${theme.midGrey};
    cursor: not-allowed;
  }
  svg {
    margin-right: 0.25rem;
  }
`;

/**
 * This button is used to add resources on pages
 * @deprecated
 */
export const filledButton = (theme: BirdiTheme) => css`
  font-family: ${fonts.display};
  font-weight: bold;
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  padding: 0.5rem;
  font-size: 1rem;
  color: white;
  background: ${theme.deepOcean};
  white-space: nowrap;
  cursor: pointer;
  min-width: 8em;
  &:focus {
    outline: 2px solid ${theme.mono40};
  }
  &:active {
    color: white;
  }
  &:disabled {
    cursor: not-allowed;
    color: white;
    background: ${theme.mono50}
  }
  min-width: 8em;
  justify-content: center;
`;

/**
 * Button with no background
 * @deprecated
 */
export const textButton = (theme: BirdiTheme) => css`
  display: flex;
  align-items: center;
  border-radius: 2px;
  padding: 0.5rem;
  font-size: 1rem;
  color: ${theme.midnight};
  white-space: nowrap;
  cursor: pointer;
  height: 2.5rem;
  border: 1px solid ${theme.mono20};
  &:focus {
    outline: 2px solid ${theme.mono40};
  }
  min-width: 8em;
  justify-content: center;
`;

/**
 * @deprecated
 */
export const outlineButton = (theme: BirdiTheme) => css`
  font-family: ${fonts.display};
  border-radius: 2px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  color: ${theme.deepOcean};
  border: 1px solid ${theme.deepOcean};
  background: transparent;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  font-weight: bold;
  &:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  &:active {
    transform: translateY(0px);
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }
  &:disabled {
    color: ${theme.midGrey};
    border: 1px solid ${theme.midGrey};
  }
  svg {
    margin-right: 0.25rem;
  }
`;

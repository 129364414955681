import { stroot, subscription as subscriptionAPI } from '@birdi/js-sdk';
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { TabNavigation, SubscriptionTabs } from './tab-navigation';

export const BillingInfo = (): React.ReactElement => {
  const [customerInfo, setCustomerInfo] = useState(null);

  useEffect(() => {
    subscriptionAPI.getCustomerInfo(stroot('duwuk8'))
      .then((res) => setCustomerInfo(res.body));
  }, []);

  if (!customerInfo) return <div />;

  return (
    <div>
      <TabNavigation activeTab={SubscriptionTabs.Settings} />
      <div css={css`padding: 0 1em 2em 1em;`}>
        <h3>Billing & Shipping Information</h3>
        {customerInfo.email && (
          <div>
            <span css={(theme) => css`color: ${theme.mono50}`}>Email:&nbsp;</span>
            <span>{customerInfo.email}</span>
          </div>
        )}
        {customerInfo.phone && (
          <div>
            <span css={(theme) => css`color: ${theme.mono50}`}>Phone:&nbsp;</span>
            <span>{customerInfo.phone}</span>
          </div>
        )}
      </div>
    </div>
  );
};

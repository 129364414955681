import React from "react";
import { css } from "@emotion/react";
import DatePicker from 'react-datepicker';
import { datePickerStyle } from '@birdi/theme/form';

interface DateFieldProps {
  selectedDate: Date | null;
  onChange: any;
  label: string;
  endDate: Date | null;
  startDate: Date | null;
}

export const DateField: React.FC<DateFieldProps> = ({
  selectedDate, onChange, label,
  startDate = null, endDate = null,
}) => {
  //
  return (
    <DatePicker
      dateFormat="dd/MM/yyyy"
      minDate={(label === 'endDate' && startDate) ? startDate : null}
      maxDate={(label === 'startDate' && endDate) ? endDate : new Date()}
      selected={selectedDate || null}
      value={selectedDate}
      onChange={(date) => onChange(label, date)}
      css={(theme) => css`${datePickerStyle(theme)}; input& { height: 3em; margin-top: 0; border-radius: 2px; }`}
      placeholderText="dd/mm/yyyy"
    />
  );
};

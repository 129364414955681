import React from 'react';
import { css } from '@emotion/react';

const droneImageMap = new Map([
  ['Mavic Air', 'dji_mavic_air'],
  ['Mavic 2 Pro', 'dji_mavic_2_pro'],
  ['Spark', 'dji_spark'],
  ['Phantom 4', 'dji_phantom_4'],
  ['Phantom 4 Advanced', 'dji_phantom_4_advanced'],
  ['Phantom 4 Pro', 'dji_phantom_4_pro'],
  ['Mavic Pro', 'dji_mavic_pro'],
  ['Inspire 2', 'dji_inspire_2'],
  ['Inspire 1', 'dji_inspire_1'],
  ['Mavic Pro Platinum', 'dji_mavic_pro_platinum'],
  ['Phantom 3 Professional', 'dji_phantom_3_professional'],
  ['Phantom 3 Advanced', 'dji_phantom_3_advanced'],
  ['Phantom 3 Standard', 'dji_phantom_3_standard'],
  ['Phantom 3 4K', 'dji_phantom_3_4k'],
  ['Phantom 2 Vision', 'dji_phantom_2_vision'],
  ['Phantom FC40', 'dji_phantom_fc40'],
  ['Phantom 2 Vision+', 'dji_phantom_2_vision_plus'],
  ['Phantom 1', 'dji_phantom_1'],
  ['Phantom 2', 'dji_phantom_2'],
]);

const getImageURL = (equipment) => {
  const { model } = equipment;
  if (!model.type || typeof model.type !== 'string') return null;
  let image;
  if (model.type === 'Drone') {
    image = droneImageMap.get(model.model);
  } else {
    image = model.type.toLowerCase();
  }
  if (!image) return null;
  return `https://s3-ap-southeast-2.amazonaws.com/birdi-equipment-images/${image}.png`;
};

export const EquipmentIcon = ({ equipment, additionalCss }) => {
  if (!equipment.id) return null;
  return (
    <div css={(theme) => css`
        display: flex;
        flex-wrap: wrap;
        background-color: ${theme.mono20};
        justify-content: center;
        padding: 2em;
        ${additionalCss}
      `}
    >
      <div css={css`
        background-image: url("${getImageURL(equipment)}");
        background-size: ${equipment.model.type === 'Drone' ? '70%' : '30%'};
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 2px;
        width: 160px;
        height: 120px;
      `}
      />
    </div>
  );
};

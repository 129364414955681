import { breakpoints } from "@birdi/theme";
import { css } from "@emotion/react";
import { stroot, sites as sitesAPI } from '@birdi/js-sdk';
import { format, parseISO } from "date-fns";
import React from "react";
import { useMetaSelector } from '../meta/reducer';
import { useDispatch } from 'react-redux';
import { ContextMenu, contextStyles } from '@birdi/context-menu/ContextMenu';
import TrashSVG from '@birdi/icons/trash.svg';

interface SiteCardProps {
  id: string;
  name: string;
  location: { name: string; };
  createdAt: string;
  refreshSites: () => void;
  // todo: createdat
}

export const SiteCard = ({ id, name, location, createdAt, refreshSites }: SiteCardProps) => {
  const meta = useMetaSelector();
  const dispatch = useDispatch();

  const date = createdAt && format(parseISO(createdAt), 'dd MMM yyyy');

  return (
    <article
      css={theme => css`
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 1rem;
        /* padding-top: 4.5rem; */
        background: #fff;
        box-shadow: 0 0 10px ${theme.mono20};
        border: solid 1px ${theme.mono20};
        border-radius: 2px;
        width: 100%;
        @media (min-width: ${breakpoints.xs}) {
          width: calc((100% / 2) - 1.1rem);
        }
        @media (min-width: ${breakpoints.s}) {
          width: calc((100% / 3) - 1.1rem);
        }
      `}
    >
      {/* TODO: map screenshot */}
      <header>
        <div css={css`
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        `}
        >
          <h3
            css={css`
              text-transform: capitalize;
              margin: 0;
              /* margin-bottom: 0px; margin-top: 0; */
            `}
          >
            {name}
          </h3>
          {(meta.permissions.includes('org:manage-org') || meta.opsActions.includes('access_ops')) && (
            <ContextMenu alignRight>
              {() => (
                <div css={theme => css(contextStyles(theme))}>
                  <button
                    css={css`display: flex;`}
                    type="button"
                    onClick={() => {
                      const confirmed = window.confirm('Are you sure you want to delete this site?');
                      if (!confirmed) return false;
                      sitesAPI.deleteSite(stroot('euj4ei'), id)
                        .then(() => refreshSites())
                    }}
                  >
                    <span css={css`margin-right: 0.5em`}><TrashSVG /></span>
                    <span>Delete</span>
                  </button>
                </div>
              )}
            </ContextMenu>
          )}
        </div>
      </header>
      <p css={css`margin: 0;`}>
        <span css={css`font-weight: 700;`}>Location:&nbsp;</span>
        {location.name}
      </p>
      <footer>
        <p css={theme => css`margin: 0; font-size: 14px; color: ${theme.darkGrey};`}>Site created: {date}</p>
      </footer>
    </article>
  );
}

/* globals IntersectionObserver, document */
import {
  stroot, mission as missionAPI,
} from '@birdi/js-sdk';

// Cached cover images
const coverUrlMap = new Map<string, string>();

export const getCover = async (missionId: string) => {
  const existing = coverUrlMap.get(missionId);
  if (existing) return existing;
  const res = await missionAPI.getCover(stroot('ioz1sh'), missionId);
  const coverImageURL = res.body?.url as string || '';
  coverUrlMap.set(missionId, coverImageURL);
  return coverImageURL;
}

const coverImageMap = new Map();
// const existing = coverImageMap.get(missionId);
      // if (existing) return existing

const options = {
  rootMargin: '0px 0px 0px 0px',
  threshold: 0,
};

export function CoverGridObserver(componentGetCover: (any) => void) {
  const resolved = new Map();
  function handleObserver(entries) {
    entries.forEach((entry) => {
      const missionId = entry.target.id;
      const isResolved = resolved.get(missionId);
      if (entry.isIntersecting && !isResolved) {
        observer.unobserve(entry.target); /* eslint-disable-line no-use-before-define */
        componentGetCover(missionId);
      }
    });
  }
  const observer = new IntersectionObserver(handleObserver, options);
  return observer;
}

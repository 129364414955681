import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import * as Dialog from '@radix-ui/react-dialog';
import { contentCSS, portalCSS } from '@birdi/modal-kit';
import { buttons } from '@birdi/theme';

interface NewAssessmentTypeModalProps {
  modalOpen: boolean;
  setModalOpen: (modalState: boolean) => void;
  addAssessmentType: (name: string) => void;
}

export const NewAssessmentTypeModal: React.FunctionComponent<NewAssessmentTypeModalProps> = ({
  modalOpen,
  setModalOpen,
  addAssessmentType,
}) => {
  const [name, setName] = useState('');
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        if (name) addAssessmentType(name);
        setModalOpen(false);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [name]);
  return (
    <Dialog.Root open={modalOpen} onOpenChange={setModalOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />
        <div css={portalCSS}>
          <Dialog.Content
            css={(theme) => css`
              ${contentCSS(theme)}
              overflow-y: hidden;
              box-shadow: none;
            `}
          >
            <Dialog.Title css={css`text-align: center;`}>Create a new assessment type</Dialog.Title>
            <hr />
            <p>Please give your assessment type a name.</p>
            <p>Name:</p>
            <input
              type="text"
              css={css`
                margin-bottom: 2rem;
                width: 100%;
                height: 2.5rem;
              `}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <div
              css={css`
                float: right;
                display: flex;
                gap: 1rem;
              `}
            >
              <Dialog.Close
                css={buttons.outlineButton}
              >
                Cancel
              </Dialog.Close>
              <Dialog.Close
                css={buttons.primaryButton}
                onClick={() => {
                  if (name) addAssessmentType(name);
                }}
              >
                Submit
              </Dialog.Close>
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

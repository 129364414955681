import { stroot, subscription as subscriptionAPI } from '@birdi/js-sdk';
import React, { useEffect, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { LoadingSpinner } from '@birdi/loading';
import { TabNavigation, SubscriptionTabs } from './tab-navigation';
import { CardBrand } from './card-brand';

const capitaliseFirst = (str: string) => `${str[0].toUpperCase()}${str.slice(1)}`;
const names = {
  mastercard: 'Mastercard',
  visa: 'Visa',
  amex: 'American Express',
};

interface StripePaymentMethod {
  type: string;
  id: string;
  card: {
    brand: string;
    exp_month: number;
    exp_year: number;
    last4: string;
  }
}

export const PaymentMethod = withRouter((): React.ReactElement => {
  const theme = useTheme();
  const [paymentMethods, setPaymentMethods] = useState<StripePaymentMethod[]>([]);
  const [state, setState] = useState<'loading' | 'loaded' | 'no-subscription'>('loading');

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    subscriptionAPI.getPaymentMethods(stroot('eegh6i'))
      .then((res) => {
        setPaymentMethods(res.body.data);
        setState('loaded');
      }).catch((err) => {
        console.error(err);
        setState('no-subscription');
      });
  }, []);

  const visitStripePortal = async () => {
    const stripePortal = await subscriptionAPI.getStripePortal(stroot('abeen7'));
    if (!stripePortal.body) return;
    window.location.href = stripePortal.body.data;
  };

  return (
    <div>
      <TabNavigation activeTab={SubscriptionTabs.PaymentDetails} />
      <div
        css={css`
          border-top: solid 1px ${theme.mono30};
          padding: 1em;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          align-items: flex-start;
        `}
      >
        {
          state === 'loading' && <LoadingSpinner color={theme.deepOcean} size="2rem" />
        }

        {
          state === 'no-subscription' && (
          <>
            <div>
              You do not have an active subscription through Stripe.
              <br />
              When you do, you&apos;ll be able to manage your payment
              method here through the Stripe portal.
            </div>
          </>
          )
        }

        {
          state === 'loaded' && (
          <>
            <h3 css={css`margin: 0;`}>Payment Method</h3>

            <div>
              At Birdi we do not save your credit card details, our payment provider
              {' '}
              <a href="https://stripe.com" css={(theme) => css`color: ${theme.ocean} !important;`}>Stripe</a>
              {' '}
              manages all payment methods.
            </div>
            <div
              css={css`
            display: flex;
            flex-direction: column;
            gap: 1rem;
            align-items: stretch;
          `}
            >
              {paymentMethods.map((payment) => (
                <div
                  key={payment.id}
                  css={(theme) => css`
              display: flex;
              align-items: center;
              background: ${theme.lightFlora};
              padding: 24px;
              gap: 24px;
              border-radius: 4px;
            `}
                >
                  <CardBrand brand={payment.card.brand} />
                  <span css={css`font-weight: 700; margin-right: 1rem;`}>{names[payment.card.brand as keyof typeof names] || capitaliseFirst(payment.card.brand)}</span>
                  <div css={css`flex: 1;`} />
                  <span>
                    Ending in
                    {' '}
                    {payment.card.last4}
                  </span>
                  <span>
                    Exp
                    {' '}
                    {payment.card.exp_month}
                    /
                    {payment.card.exp_year}
                  </span>
                </div>
              ))}
            </div>
            <button type="button" css={buttonBlue} onClick={visitStripePortal}>Visit Stripe portal to manage</button>
          </>
          )
        }
      </div>
    </div>
  );
});

import React from 'react';
import { css } from '@emotion/react';
import { BirdiTheme } from '@birdi/theme';
import BirdiLogoWhiteSVG from '@birdi/icons/birdi-logo-white.svg';
import BirdiLogoNavySVG from '@birdi/icons/birdi-logo-navy.svg';

export const PoweredByBirdi = ({ mode }): React.ReactElement => (
  <div css={css`display: flex; flex-direction: row;`}>
    <div css={(theme: BirdiTheme) => css`
      font-size: 0.6em;
      font-weight: 600;
      margin-top: 0.15em;
      color: ${mode === 'light' ? theme.displayFontInverse : theme.displayFont}
    `}
    >
      Powered by
      &nbsp;
    </div>
    {mode === 'light' ? (
      <BirdiLogoWhiteSVG css={css`font-size: 1.2em;`} />
    ) : (
      <BirdiLogoNavySVG css={css`font-size: 1.2em;`} />
    )}
  </div>
);

import React, { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { stroot, mission as missionAPI, organisationMissions as organisationMissionsAPI } from '@birdi/js-sdk';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme';
import { MissionList } from '../missions/List';

const queryClient = new QueryClient();

function Maps() {
  // prev approach => all (org + demo)
  const fetchMissions = async () => {
    const res = await organisationMissionsAPI.getMissions(stroot('tee0fo'), {
      association: 'organisation',
      start: 1,
      limit: 5,
    });
    return res.body?.items || [];
  };

  // prev approach => mine + demo
  // const fetchMissions = async () => {
  //   const res1 = await missionAPI.list(stroot('oon5ge'), {
  //     start: 1,
  //     limit: 5,
  //   });
  //   let myMissions = res1.body?.items || [];
  //   if (myMissions.length < 5) {
  //     const res2 = await organisationMissionsAPI.getMissions(stroot('tee0fo'), {
  //       association: 'sample',
  //       start: 1,
  //       limit: 2,
  //     });
  //     const demoMissions = res2.body?.items || [];
  //     myMissions = [...myMissions, ...demoMissions].slice(0, 5);
  //   }
  //   return myMissions;
  // };

  const { isLoading, data } = useQuery('missionsData', fetchMissions);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <MissionList
        missions={data}
        isDashboard
      />
    </div>
  );
}

export const DashboardMissions = () => (
  <div css={css`margin-top: 1.5em;`}>
    <h3
      id="recent-missions"
      css={(theme) => css`
        background-color: ${theme.sand};
        margin: -0.5em -1.25em 1em -1.25em;
        padding: 0.75em 1.25em;
        @media (min-width: ${breakpoints.xs}) {
          background-color: ${theme.mono0};
          margin: 0 0 1em 0;
          padding: 0;
        }
      `}
    >
      Recent Maps
    </h3>
    <QueryClientProvider client={queryClient}>
      <Maps />
    </QueryClientProvider>
  </div>
);

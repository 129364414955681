import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { BirdiTheme } from '@birdi/theme';

export const screenContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 1rem 2rem;
  background-color: #38385c;
  min-height: 100vh;
`;

export const insideContainer = (theme: BirdiTheme) => css`
  z-index: 2;
  width: 35em;
  padding: 2em 3em;
  background-color: white;
  border-radius: 6px;
`;

export const breakLine = (theme: BirdiTheme) => css`
  width: 100%;
  height: 1px;
  background-color: ${theme.mono20};
  margin: 1.5em 0;
`;

import React from 'react';
import { css } from '@emotion/react';
import { definitions } from './definitions';
import errorReducer from './reducer';

export default ({
  error,
  path = 'meta',
  className = 'api-error-display',
}) => {
  const errors = errorReducer(error, path);
  if (errors.length) {
    const errorDictionary = { ...definitions };
    return (
      <div
        className={className}
        data-testid="error-message"
      >
        {errors.map((errorMessage, index) => (
          <div className="alert-message" key={index}>
            <p
              css={css`font-size: 0.9rem; margin: 0 0 0.5rem;`}
            >
              {errorDictionary[errorMessage.code] || errorMessage.message || 'Sorry, we encountered an error.'}
            </p>
          </div>
        ))}
      </div>
    );
  }
  return <div className="server-validation-errors-hide" />;
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { BirdiTheme, fonts } from '@birdi/theme';
import { ManageSeatsButton } from '../billing/add-seats-modal';
import { loadSeats, useSeatsSelector, totalSeats } from '../billing/seats-reducer';
import { useMetaSelector } from '../meta/reducer';

const seatsInfoStyle = css`
  display: flex;
  width: fit-content;
  align-items: center;
  padding: 0.5em;
  background-color: #E6F4F9;
  border-radius: 5px;
  margin-bottom: 1em;
  span {
    font-family: ${fonts.display};
  }
`;

interface SeatsBadgeProps {
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

/**
 * This component shows how many seats used & in total
 */
export const SeatsBadge = ({ modalOpen, setModalOpen }: SeatsBadgeProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const meta = useMetaSelector();
  // TODO: Type this at a higher level
  const seats = useSeatsSelector();
  useEffect(() => {
    loadSeats(dispatch);
  }, []);
  if (!seats.loaded) return <div css={seatsInfoStyle}>...</div>;
  return (
    <div>
      {!seats.unlimitedSeats && (
        <div css={seatsInfoStyle}>
          <span>
            {seats.userCount}
            /
            {totalSeats(seats)}
            {' '}
            seats used
          </span>
          {meta.organisation.plan === 'growth' && !meta.organisation.externalBilling && (
            <ManageSeatsButton modalOpen={modalOpen} setModalOpen={setModalOpen} />
          )}
          {(meta.organisation.plan === 'free' || meta.organisation.plan === 'plus') && meta.permissions.includes('org:manage-org') && (
            <button
              type="button"
              css={(theme: BirdiTheme) => css`
                color: ${theme.ocean};
                text-decoration: underline;
                font-family: ${fonts.display};
              `}
              onClick={() => history.push('/billing/options')}
            >
              Update Plan
            </button>
          )}
        </div>
      )}
    </div>
  );
};

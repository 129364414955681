import { BirdiDatePicker } from '@birdi/date-picker';
import { Icons } from '@birdi/icons';
import { MissionSelect } from '@birdi/select/OrganisationMissionSelect';
import { ObjectiveSelect } from '@birdi/select/OrganisationObjectiveSelect';
import { css } from '@emotion/react';
import { parse } from 'date-fns';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import { ProcessingContext } from './ProcessingState';

export const ProcessingFilters = observer(() => {
  const processingState = useContext(ProcessingContext);
  const [defaultStartDate, defaultEndDate] = processingState.getMonthStartAndEndDates();
  return (
    <div css={css`display: flex; gap: 1rem;`}>
      <BirdiDatePicker
        type="range"
        onChange={(dates) => processingState.updateFilters({ start: 1, dates: dates })}
        defaultStartDate={parse(defaultStartDate, 'yyyy/MM/dd', new Date())}
        defaultEndDate={parse(defaultEndDate, 'yyyy/MM/dd', new Date())}
        icon={Icons.Filter}
        placeholder="Filter by processed dates"
      />
      {/*
      <MissionSelect
        onSelect={(mission) => processingState.updateFilters({ start: 1, mission })}
        onClear={() => processingState.updateFilters({ start: 1, mission: null })}
      />
      <ObjectiveSelect
        onSelect={(objective) => processingState.updateFilters({ start: 1, objective })}
        onClear={() => processingState.updateFilters({ start: 1, objective: null })}
      />
      */}
    </div>
  );
});

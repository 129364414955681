import { stroot, user } from '@birdi/js-sdk';
import React, { Component } from 'react';
import { css } from '@emotion/react';
import { Toggle } from '@birdi/toggle';
import { BirdiTheme } from '@birdi/theme';
import { AccountNav, AccountSections } from './AccountNav';

type Props = Record<string, unknown>;
interface State {
  status: boolean;
  disabled: boolean;
}

export class MarketingPreferences extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      status: false,
      disabled: true,
    };
  }
  componentDidMount() {
    user.getSubscriptionStatus(stroot('aiw7ee'))
      .then((res) => this.setState(() => ({ disabled: false, status: res.body.marketingEmails })));
  }
  updateSubscription = (checked: boolean) => {
    this.setState(() => ({ disabled: true }));
    user.updateEmailSubscriptions(stroot('aad8ce'), checked)
      .then(() => {
        this.setState(() => ({ disabled: false, status: checked }));
        this.props.notifySave();
      });
  };

  render() {
    const { status, disabled } = this.state;
    return (
      <div>
        <AccountNav activeSection={AccountSections.Marketing} />
        <div css={(theme: BirdiTheme) => css`
          border-top: solid 1px ${theme.mono30};
          padding: 1.5em;
          `}
        >
          <div css={css`max-width: 35em;`}>
            <p>
              Receive emails related to product updates and other Birdi marketing materials
              &nbsp;(Not shared with third-parties):
            </p>
            <Toggle
              checked={status}
              disabled={disabled}
              onChange={this.updateSubscription}
            />
          </div>
        </div>
      </div>
    );
  }
}

import { createAction, Action, Dispatch } from '@reduxjs/toolkit';
import { stroot, organisation as organisationAPI } from '@birdi/js-sdk';
import { useSelector } from 'react-redux';

export const UPDATE = 'seats/UPDATE';

export interface SeatsState {
  loaded: boolean;
  plan: string | null;
  baseSeatQuantity: number,
  extraSeatQuantity: number,
  userCount: number,
  guestCount: number,
  birdiUserCount: number,
  unlimitedSeats: boolean,
}

const initialState: SeatsState = {
  loaded: false,
  plan: null,
  baseSeatQuantity: 1,
  extraSeatQuantity: 0,
  userCount: 1,
  guestCount: 0,
  birdiUserCount: 0,
  unlimitedSeats: false,
};

export const updateField = createAction<Partial<SeatsState>>(UPDATE);

export const loadSeats = (dispatch: Dispatch) => {
  dispatch(updateField({ loaded: false }));
  organisationAPI.getSeatsCount(stroot('seatct'))
    .then((res) => dispatch(updateField({ loaded: true, ...res.body })));
};

export const seatsReducer = (state = initialState, action: Action) => {
  if (updateField.match(action)) {
    return { ...state, ...action.payload };
  }
  return state;
};

export const useSeatsSelector = () => useSelector<{ seats: SeatsState }, SeatsState>((state) => state.seats);

export const totalSeats = (seats: SeatsState) => seats.baseSeatQuantity + seats.extraSeatQuantity;

/* global fetch */

import querystring from 'querystring';
import { config } from '../config';
import {
  headers, APIResponse, SpanTag, stleaf, fetchAPI
} from '../helpers';

export const get = (st: SpanTag, opts) => {
  const query = querystring.stringify(opts);
  return fetchAPI(stleaf(st, 'eew3mu'), `${config.API_URL}/v1/admin/users?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};
/**
 * Returns a single user
 * TODO: Deprecate userId
 * @param {string} userId userId (Returns unscoped user)
 * @param {string} scopeId scopeId
 */
export const getOne = (st: SpanTag, { scopeId, userId }): Promise<APIResponse> => {
  if ((scopeId && userId) || (!scopeId && !userId)) {
    throw new Error('Must select one of either scopeId or userId');
  }
  const opts = scopeId ? { scopeId } : { userId };
  const query = querystring.stringify(opts);
  return fetchAPI(stleaf(st, 'li9jai'), `${config.API_URL}/v2/admin/user/?${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const update = (st: SpanTag, id: string, fieldsToUpdate): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}`;
  return fetchAPI(stleaf(st, 'ach5qu'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const getCredentials = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/credentials`;
  return fetchAPI(stleaf(st, 'sa8wuf'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getSoftware = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/software`;
  return fetchAPI(stleaf(st, 'bohd0r'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getExperience = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/experience`;
  return fetchAPI(stleaf(st, 'yae6ee'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const deleteAllSoftware = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/software`;
  return fetchAPI(stleaf(st, 'ahje6s'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const deleteAllExperience = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/experience`;
  return fetchAPI(stleaf(st, 'phah8x'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const deleteCredential = (st: SpanTag, credentialID: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/credentials/${credentialID}`;
  return fetchAPI(stleaf(st, 'iu1kie'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const addCredential = (st: SpanTag, fields): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/credentials`;
  return fetchAPI(stleaf(st, 'efiop8'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const addSoftware = (st: SpanTag, userId: string, softwareId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${userId}/software/${softwareId}`;
  return fetchAPI(stleaf(st, 'uu5ahj'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const addExperience = (st: SpanTag, userId: string, experienceId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${userId}/experience/${experienceId}`;
  return fetchAPI(stleaf(st, 'aekaj4'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const deleteSoftware = (st: SpanTag, userId: string, softwareId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${userId}/software/${softwareId}`;
  return fetchAPI(stleaf(st, 'aethu7'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const deleteExperience = (st: SpanTag, userId: string, experienceId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${userId}/experience/${experienceId}`;
  return fetchAPI(stleaf(st, 'uch1pe'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const editCredential = (st: SpanTag, credentialID: string, fields): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/credentials/edit/${credentialID}`;
  return fetchAPI(stleaf(st, 'aixah5'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const verifyCredential = (st: SpanTag,
  credentialID: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/credentials/verification/${credentialID}`;
  return fetchAPI(stleaf(st, 'ni0xu6'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

export const removeCredentialVerification = (st: SpanTag,
  credentialID: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/credentials/verification/${credentialID}`;
  return fetchAPI(stleaf(st, 'soap3z'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const invite = (st: SpanTag, params): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/user`;
  return fetchAPI(stleaf(st, 'uey5ae'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

export const deleteById = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/user/${id}`;
  return fetchAPI(stleaf(st, 'xoh2ji'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const updateLocation = (st: SpanTag, id: string, fieldsToUpdate): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/location`;
  return fetchAPI(stleaf(st, 'urue5a'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const getLocation = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/location`;
  return fetchAPI(stleaf(st, 'fa4idu'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getOrganisations = (st: SpanTag, id: string, opts): Promise<APIResponse> => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  return fetchAPI(stleaf(st, 'oelid6'), `${config.API_URL}/v1/admin/users/${id}/organisations${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

// TODO: Update API to show return type
export const getEquipment = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/equipment`;
  return fetchAPI(stleaf(st, 'eth6ch'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getScopes = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/scopes`;
  return fetchAPI(stleaf(st, 'faeku3'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getRates = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/rate`;
  return fetchAPI(stleaf(st, 'ju5vie'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const updateRate = (st: SpanTag, id: string, fieldsToUpdate): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/${id}/rate`;
  return fetchAPI(stleaf(st, 'kayei7'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const sendPasswordResetLinkToEmail = (st: SpanTag, email: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/admin/user/password`;
  return fetchAPI(stleaf(st, 'eav6up'), url, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify({ email }),
  });
};

export const deleteScope = (st: SpanTag, scopeId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/admin/users/scope/${scopeId}`;
  return fetchAPI(stleaf(st, 'bede4i'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

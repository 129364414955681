/* globals window */

import React from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { Link } from 'react-router-dom';
import { pageDescription } from '@birdi/theme/blocks';
import PlayCircleSVG from '@birdi/icons/play-circle.svg';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import { BirdiTheme, fonts } from '@birdi/theme';
import { INTERCOM_TOUR_CODES } from '../common/consts';
import history from '../state/history';

export enum AccountSections {
  Details = 'Details',
  Pilot = 'Pilot',
  Marketing = 'Marketing',
}

interface AccountNavProps {
  activeSection: AccountSections;
}

export const AccountNav: React.FC<AccountNavProps> = ({ activeSection }) => (
  <section>
    <h2 css={css`margin: 1em`}>Account Settings</h2>
    <div css={pageDescription}>
      <p>Manage your account settings and preferences.</p>
      <div>
        <button
          type="button"
          onClick={() => {
            window?.Intercom && window.Intercom('startTour', INTERCOM_TOUR_CODES.ACCOUNT_SETTINGS);
          }}
        >
          <PlayCircleSVG />
          <span>Take a quick tour of account settings</span>
        </button>
        <a
          href="https://help.birdi.io/en/articles/6113413-edit-account-name-and-details"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BookOpenSVG />
          <span>Learn more about your account settings</span>
        </a>
      </div>
    </div>
  </section>
);

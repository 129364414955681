export { LocationSelect } from './LocationSelect';

export function getLocationFromAccount(account) {
  return {
    suburb: account.suburb,
    state: account.state,
    country: account.country,
  };
}

export const blankLocation = {
  streetAddress: null,
  suburb: null,
  state: null,
  country: null,
};

export const blankLocationWithName = {
  name: null,
  suburb: null,
  state: null,
  country: null,
  center: null,
};

export const blacklistedAreas = [
  'Australia',
  'Western Australia', 'Perth',
  'Victoria', 'Melbourne',
  'Tasmania',
  'South Australia', 'Adelaide',
  'Queensland', 'Brisbane',
  'Northern Territory', 'Darwin',
  'New South Wales', 'Sydney',
  'Australian Capital Territory', 'Canberra',
];

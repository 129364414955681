import { BirdiTheme } from '@birdi/theme';

export const auth = `
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 1rem 2rem;
  background-color: #38385c;
  min-height: 100vh;
`;

export const background = `
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #38385c;
`;

export const authContainer = `
  z-index: 1;
  max-width: 100%;
  border-radius: 6px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  background-color: #F7F6F5;
`;

export const whiteBackgroundContainer = (theme: BirdiTheme) => `
  z-index: 2;
  width: 28em;
  padding: 2em 3em;
  background-color: ${theme.mono0};
  border-radius: 6px;
  box-shadow: 2px 0 2px #EAEAEA;
`;

export const grayBackgroundContainer = `
  z-index: 1;
  width: 18em;
  padding: 3em 2em 1em 2em;
  background-color: #F7F6F5;
`;

import { createFrontendTracer, FORMAT_HTTP_HEADERS } from '@birdi/tracing';

const tracer = createFrontendTracer({
  crypto,
  fetch,
  url: `${process.env.LOGGING_URL}`,
  enabled: JSON.parse(process.env.LOGGING_ENABLED),
});

const tracer_id = tracer.process_id;

const app_version = process.env.APP_VERSION;

function pad(num, size) {
  const s = `000000000${num}`;
  return s.substr(s.length - size);
}

export function handle_global_error(type, e) {
  {
    // XXX Ignore errors that will be processed by componentDidCatch.
    // SEE: https://github.com/facebook/react/issues/10474
    if (e.error && e.error.stack && e.error.stack.indexOf('invokeGuardedCallbackDev') >= 0) {
      // console.log("react dev workaround");
      return;
    }
  }

  try {
    e = e.error || e.reason || e;
    const error_context = e.birdi_context || 'unknown';

    // TODO - async span logging is broken a bit ? span start should not have logs on it?
    const span = tracer.start_span(type, {
      childOf: null,
      tags: {
        'span.kind': 'client',
        'window.location': window.location.toString(),
        error_context,
        app_version,
      },
    });

    if (e.stack) {
      span.logError(e);
    } else {
      span.logEvent('global_error', e);
    }

    span.finish();
  } catch (e) {
    console.warn(`tracer could not log ${type}`);
  }
}

window.addEventListener('unhandledrejection', (e) => {
  handle_global_error('global.unhandledrejection', e);
});
window.addEventListener('error', (e) => {
  handle_global_error('global.error', e);
});

// only needed for supertelemetry
// todo, make a twiddle
// var getStackTrace = function() {
//  var obj = {};
//  Error.captureStackTrace(obj, getStackTrace);
//  return obj.stack;
// };

export interface SpanTag {
  tag: string;
}

export function stroot(tag: string): SpanTag {
  if (typeof tag !== 'string') {
    throw new Error('stroot type error');
  }

  return { tag };
}

export function stleaf(st: SpanTag, tag: string): SpanTag {
  if (typeof st.tag !== 'string') {
    throw new Error('stleaf st type error');
  }

  if (typeof tag !== 'string') {
    throw new Error('stleaf tag type error');
  }

  return { tag: `${st.tag}.${tag}` };
}

// do Fetch Traced is legacy now, for uppy span code.
// TODO: replace doFetchTraced in uppy span code with fetchAPI and global analytics.
async function doFetchTraced(tag, parent_span, url, args) {
  let bodysize = null;

  if (args.body) {
    bodysize = args.body?.size || args.body?.length;
  }

  const span = tracer.start_span('fetch', {
    childOf: parent_span,
    tags: {
      'span.kind': 'client',
      'window.location': window.location.toString(),
      'http.url': url,
      'http.method': args.method,
      app_version,
      ...(bodysize && { bodysize }),
      ...(tag && { tag }),
    },
  });

  let headers = {};

  if (args.headers) {
    headers = JSON.parse(JSON.stringify(args.headers));
  }

  tracer.span_inject(span, FORMAT_HTTP_HEADERS, headers);
  args.headers = headers;

  args.headers['x-birdi-context'] = tag || 'unknown';

  try {
    const response = await fetch(url, args);
    span.logEvent('response_status', response.status);
    return response;
  } catch (err) {
    span.catch(err);
    throw err;
  } finally {
    span.finish();
  }
}

// The core fetch function for the js sdk.
async function doFetchTagged(tag, parent_span, url: RequestInfo | URL, args: RequestInit, resolve) {
  let bodysize = null;

  if (args.body) {
    bodysize = (args.body as any).size || (args.body as any).length;
  }

  const span = tracer.start_span('fetch', {
    childOf: parent_span,
    tags: {
      'span.kind': 'client',
      'window.location': window.location.toString(),
      'http.url': url,
      'http.method': args.method,
      app_version,
      ...(bodysize && { bodysize }),
      ...(tag && { tag }),
    },
  });

  let headers = {};

  if (args.headers) {
    headers = JSON.parse(JSON.stringify(args.headers));
  }

  tracer.span_inject(span, FORMAT_HTTP_HEADERS, headers);
  args.headers = headers;

  args.headers['x-birdi-context'] = tag || 'unknown';

  let responded = false;

  try {
    const response = await fetch(url, args);
    responded = true;

    span.logEvent('response_status', response.status);

    const resolution = await resolve(response);
    span.logEvent('resolution_status', true); // TODO: something better

    return resolution;
  } catch (err) {
    span.catch(err);
    err.birdi_context = tag || 'unknown';

    if (!responded) {
      // This is the old handleNetworkError behaviour, except now
      // we tag it with some context if we can.
      const { name } = err;
      err = new Error('Network Error');
      if (name) err.name = name;
      err.birdi_context = tag || 'unknown';
    }

    throw err;
  } finally {
    span.finish();
  }
}

async function fetchTraced(parent_span, url, args) {
  return doFetchTraced(null, parent_span, url, args);
}

async function fetchAPI<T = Record<string, unknown>>(st: SpanTag, url: RequestInfo | URL, args: RequestInit): Promise<APIResponse<T>> {
  return doFetchTagged(st.tag, null, url, args, parseAPIResponse);
}

async function fetchAPIJSON(st: SpanTag, url, args) {
  return doFetchTagged(st.tag, null, url, args, handleJSON);
}

async function fetchAPIAttachment(st: SpanTag, url, args) {
  return doFetchTagged(st.tag, null, url, args, parseAttachmentResponse);
}

export {
  tracer,
  fetchTraced,
  fetchAPI,
  fetchAPIJSON,
  fetchAPIAttachment,
};

export const headers = {
  Accept: 'application/json, text/plain, */*',
  'Content-Type': 'application/json',
};

export class APIError extends Error {
  status: number;
  body: Record<string, unknown>;

  constructor(kind: string, url: string, status: number, body?: Record<string, unknown>) {
    super(`APIError: kind ${kind} status ${status} fetching ${url}`);
    this.name = 'APIError';
    this.status = status;
    this.body = body || {};
  }

  toJSON() {
    return {
      status: this.status,
      body: this.body,
    };
  }
}

export interface APIResponse<T = Record<string, unknown>> {
  status: number;
  body?: T;
}

// function PromiseOK(): Promise<void>
// function PromiseOK<T>(val: T): Promise<T>

// Fetch utility for parsing Birdi API responses
// Adapts to both JSON and empty responses
const parseAPIResponse = async (response: Response): Promise<APIResponse> => {
  let body: Record<string, unknown>;
  const hasJSONHeader = !!(response.headers.get('Content-Type')
    && response.headers.get('Content-Type')
      .toLowerCase()
      .includes('application/json'));
  if (hasJSONHeader) {
    try {
      body = await response.json();
    } catch (err) {
      // TODO: Log this error!
      console.log('Error parsing JSON');
      throw new APIError('parseAPIResponse: bad_json', response.url, response.status);
    }
  }
  if (!response.ok) {
    throw new APIError('parseAPIResponse: bad_status', response.url, response.status, body);
  }
  return {
    status: response.status,
    ...(body && { body }),
  };
};

// Fetch utility to parse attachment responses and download them automatically
const parseAttachmentResponse = async (res) => {
  if (!res.ok) {
    throw new APIError('parseAttachmentResponse: bad_status', res.url, res.status);
  }

  const contentDisposition = res.headers.get('Content-Disposition');
  if (contentDisposition) {
    // Extracting filename from header
    const parts = contentDisposition.split(';');
    const filename = parts[1].split('=')[1];

    // Convert the data into 'blob'
    const blob = await res.blob();

    // Creating an anchor element
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);

    // Appending anchor to html page
    document.body.appendChild(link);

    // Force download
    link.click();

    // Clean up and remove the anchor
    link.parentNode.removeChild(link);
  }
};

// DEPRECATED FROM HERE:

/**
 * @param {string} error message
 * @param {status} HTTP status to return
 * @param {status} baseError in the event that we are responding to
 * an error that could be ambiguous, pass the original error too
 * DEPRECATED!
 */
function APIErrorLegacy(name = 'APIError', status, message) {
  Object.defineProperty(this, 'name', {
    enumerable: true,
    writable: true,
    value: name,
  });
  Object.defineProperty(this, 'message', {
    enumerable: true,
    writable: true,
    value: message,
  });
  Object.defineProperty(this, 'status', {
    enumerable: true,
    writable: true,
    value: status,
  });
}

Object.setPrototypeOf(APIErrorLegacy.prototype, Error.prototype);

/**
 * Fetch utility for parsing Birdi API responses
 * @deprecated
 */
const handleJSON = (response) =>
  response.text()
    .then((bodyText) => {
      // 500: Do not investigate body.. TODO: Why not?
      if (response.status >= 500) {
        throw new APIErrorLegacy(
          'ServerError',
          response.status,
          bodyText.slice(0, 20),
        );
      }
      // Determine JSON Headers
      const hasJSONHeader = response.headers.get('Content-Type')
        && response.headers.get('Content-Type').toLowerCase().includes('application/json');
      // No JSON so no parse JSON:
      if ((response.ok && !bodyText.length) || (response.ok && !hasJSONHeader)) {
        return {
          type: 'Success',
          status: response.status,
          body: '',
        };
      }
      // Parse JSON
      let parsedJSON = null;
      try {
        if (bodyText.length) parsedJSON = JSON.parse(bodyText);
      } catch (err) {
        // JSON parsing error despite JSON headers
        throw new APIErrorLegacy(
          'JSONParseError',
          response.status,
          bodyText,
        );
      }
      // Good JSON (200 status)
      if (response.ok) {
        const ok = {
          type: 'Success',
          status: response.status,
          body: parsedJSON,
        };
        return ok;
      }
      // Bad JSON
      throw new APIErrorLegacy(
        'ApplicationError',
        response.status,
        parsedJSON,
      );
    });

import { stroot } from '@birdi/js-sdk'
import Creatable from 'react-select/creatable';
import { css } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import { equipment as equipmentAPI } from '@birdi/js-sdk/src/public';
import { reactSelect } from '@birdi/theme/form';

export const ModelSelect = ({ onSelect, equipmentType, theme })  => {
  const [models, setEquipmentModels] = useState([]);
  function handleSelect(val, action) {
    onSelect(val, action);
  }
  async function resolveEquipmentModelsData() {
    const response = await equipmentAPI.getTags(stroot('ieju4e'), equipmentType);
    const equipmentModels = response.body.items
      .map((item) => ({
        value: item.id,
        label: `${item.manufacturer} ${item.model}`,
      }));
    setEquipmentModels(equipmentModels);
    onSelect({
      value: response.body.items[0].id,
      label: response.body.items[0],
    }, { action: 'select-option' });
  }
  useEffect(() => {
    if (equipmentType !== 'Battery') {
    resolveEquipmentModelsData();
    }
  }, []);
  if (!models.length && equipmentType !== 'Battery') return false;
  return (
    <Creatable
      onChange={(val, action) => handleSelect(val, action)}
      options={models}
      // defaultValue={models[0]}
      isClearable
      placeholder="Select or start to type to add a new model ..."
      valueKey="id"
      labelKey="label"
      theme={theme}
      styles={reactSelect}
    />
  );
}

import React, { useEffect, useState } from 'react';
import { stroot, organisation as organisationAPI } from '@birdi/js-sdk';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { itemsTable } from '@birdi/theme/blocks';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { Toggle } from '@birdi/toggle';
import BirdiLogoWhiteSVG from '@birdi/icons/birdi-logo-white.svg';
import BirdiLogoNavySVG from '@birdi/icons/birdi-logo-navy.svg';
import LoaderSVG from '@birdi/icons/loader.svg';
import HandsSVG from '@birdi/icons/thank-you.svg';
import { uploadOne, fileToUppyOpts } from '@birdi/uppy-s3';
import { textInput } from '@birdi/theme/form';
import * as Toast from '@radix-ui/react-toast';
import * as styles from './styles';
import { TabNavigation, CustomisationTabs } from './customisation-nav';
import { useMetaSelector, updateOrganisation } from '../meta/reducer';

interface Fields {
  name: string;
  logo: string;
  emailHeader: string;
  displayBranding: boolean;
  displayLogo: boolean;
  displayEmailHeader: boolean;
}

const FileUpload = (props) => {
  const { imageUrl, uploading, image } = props;

  return (
    <div css={(theme) => css`
        position: relative;
        width: 100px;
        height: 80px;
        background-color: ${theme.mono30};
        border: solid 1px ${theme.mono50};
      `}
    >
      <div css={(theme: BirdiTheme) => css`
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        `}
      >
        {imageUrl && (
          <img
            src={imageUrl}
            alt=""
            className="photo"
            css={css`
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              object-fit: contain;
            `}
          />
        )}
        {!imageUrl && uploading && <LoaderSVG css={css`stroke-width: 2px;`} />}
        {!imageUrl && !uploading && (image === 'logo') && <BirdiLogoNavySVG css={css`font-size: 80px;`} />}
        {!imageUrl && !uploading && (image === 'emailHeader') && <BirdiLogoNavySVG css={css`font-size: 80px;`} />}
      </div>
    </div>
  );
};

export const CustomisationBranding = (): React.ReactElement => {
  const dispatch = useDispatch();
  const meta = useMetaSelector();

  const logoFileInput = React.createRef();
  const emailHeaderFileInput = React.createRef();

  const [orgName, setOrgName] = useState('');
  const [toastOpen, setToastOpen] = React.useState(false);
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [uploadingEmailHeader, setUploadingEmailHeader] = useState(false);

  useEffect(() => {
    setOrgName(meta.organisation.name);
  }, []);

  const updateFields = async (fieldsToUpdate: Fields) => {
    await organisationAPI.updateBranding(stroot('iy3ohb'), meta.organisation.id, fieldsToUpdate);
    dispatch(updateOrganisation(fieldsToUpdate));
  };

  const uploadLogo = async () => {
    const file = logoFileInput.current.files[0];
    setUploadingLogo(true);
    const upload = await uploadOne({
      ...fileToUppyOpts(file),
      public: true,
    });
    await updateFields({ logo: upload.uploadURL });
    setUploadingLogo(false);
  };
  const clearLogo = async () => {
    if (window.confirm('Are you sure you would like to remove the logo?')) {
      const fields = {
        logo: null,
        displayLogo: false,
      };
      await updateFields(fields);
    }
  };

  const uploadEmailHeader = async () => {
    const file = emailHeaderFileInput.current.files[0];
    setUploadingEmailHeader(true);
    const upload = await uploadOne({
      ...fileToUppyOpts(file),
      public: true,
    });
    await updateFields({ emailHeader: upload.uploadURL });
    setUploadingEmailHeader(false);
  };
  const clearEmailHeader = async () => {
    if (window.confirm('Are you sure you would like to remove the email header?')) {
      const fields = {
        emailHeader: null,
        displayEmailHeader: false,
      };
      await updateFields(fields);
    }
  };

  return (
    <div>
      <TabNavigation activeTab={CustomisationTabs.Branding} />
      <div css={(theme: BirdiTheme) => css`
        border-top: solid 1px ${theme.mono30};
        padding: 1em 1.5em;
      `}
      >
        <table css={(theme) => css`
          ${itemsTable(theme)};
          width: 60%;
          @media (max-width: ${breakpoints.s}) {
            width: 100%;
          };
          tr {
            padding: 0.25em;
            border-bottom: none;
          }
          td {
            padding: 0.25em;
            text-align: left;
          }
        `}
        >
          <tbody>
            <tr key="workspace-header">
              <td key="title">
                <h3 css={css`margin-bottom: 0`} id="workspace-name">Workspace Name</h3>
              </td>
            </tr>
            <tr key="workspace-update">
              <td key="input-box">
                <input
                  css={(theme) => css`
                    ${textInput(theme)};
                    margin: 0
                  `}
                  type="text"
                  id="orgName"
                  defaultValue={orgName || meta.organisation.name}
                  onChange={(event) => setOrgName(event.target.value)}
                />
              </td>
              <td key="save-button">
                <Toast.Provider>
                  <button
                    type="button"
                    css={(theme) => css`${buttonBlue(theme, { baseSize: 'sm' })};`}
                    onClick={() => {
                      updateFields({ name: orgName });
                      setToastOpen(true);
                      window.setTimeout(() => setToastOpen(false), 2000);
                    }}
                    disabled={!orgName}
                  >
                    Save
                  </button>
                  <Toast.Root css={styles.toastRoot} open={toastOpen}>
                    <Toast.Description css={styles.toastContent}>
                      <HandsSVG />
                      Workspace name updated
                    </Toast.Description>
                  </Toast.Root>
                  <Toast.Viewport css={styles.toastViewport} />
                </Toast.Provider>
              </td>
            </tr>
            <tr key="branding-header">
              <td key="title">
                <h3 id="add-your-own-branding">Add your own branding</h3>
              </td>
              <td key="status">
                <h3 css={css`text-align: center`}>Active</h3>
              </td>
            </tr>
            <tr key="workspace-name">
              <td key="title">
                <p css={(theme) => css`margin: 0; color: ${theme.midnight}`}>
                  Default your workspace name to be used in the top left
                </p>
              </td>
              <td key="status">
                <div css={css`display: flex; justify-content: center`}>
                  <Toggle
                    checked={meta.organisation?.displayBranding}
                    onChange={(check: boolean) => updateFields({ displayBranding: check })}
                  />
                </div>
              </td>
            </tr>
            <tr key="logo">
              <td key="title">
                <h3 css={css`margin-bottom: 0`}>Logo</h3>
                <p css={(theme: BirdiTheme) => css`margin: 0; color: ${theme.midnight}`}>
                  Recommended: Dark font, 400x320px PNG with a transparent background
                </p>
              </td>
              <td key="status">
                <div css={css`display: flex; justify-content: center`}>
                  <Toggle
                    checked={meta.organisation.displayLogo}
                    onChange={(check: boolean) => updateFields({ displayLogo: check })}
                    disabled={!meta.organisation.logo}
                  />
                </div>
              </td>
            </tr>
            <tr key="upload-logo">
              <td key="buttons">
                <div>
                  <div css={css`display: flex; flex-direction: row; margin: 0.5em 0;`}>
                    <label
                      htmlFor="upload-logo"
                      css={(theme) => css`
                        ${buttonBlue(theme, { baseSize: 'sm' })};
                        width: 8em;
                        margin-right: 0.5em
                      `}
                    >
                      Upload
                      <input
                        id="upload-logo"
                        type="file"
                        accept="image/*"
                        onChange={uploadLogo}
                        ref={logoFileInput}
                        hidden
                      />
                    </label>
                    <button
                      type="button"
                      css={(theme) => css`
                        ${buttonBlue(theme, { baseSize: 'sm' })};
                        background-color: ${theme.mono0};
                        border: solid 1px ${theme.mono98};
                        color: ${theme.mono98};
                        width: 8em;
                        &:active {
                          color: ${theme.mono98};
                        }
                      `}
                      onClick={clearLogo}
                      disabled={!meta.organisation.logo}
                    >
                      Clear
                    </button>
                  </div>
                  <FileUpload
                    imageUrl={meta.organisation?.logo}
                    uploading={uploadingLogo}
                    image="logo"
                  />
                </div>
              </td>
            </tr>
            <tr key="email-header">
              <td key="title">
                <h3 css={css`margin-bottom: 0`} id="email-header">Email Header</h3>
                <p css={(theme: BirdiTheme) => css`margin: 0; color: ${theme.midnight}`}>
                  Recommended resolution: Dark font, 400x320px
                </p>
              </td>
              <td key="status">
                <div css={css`display: flex; justify-content: center`}>
                  <Toggle
                    checked={meta.organisation.displayEmailHeader}
                    onChange={(check: boolean) => updateFields({ displayEmailHeader: check })}
                    disabled={!meta.organisation.emailHeader}
                  />
                </div>
              </td>
            </tr>
            <tr key="upload-email-header">
              <td key="buttons">
                <div>
                  <div css={css`display: flex; flex-direction: row; margin: 0.5em 0;`}>
                    <label
                      htmlFor="upload-email-header"
                      css={(theme) => css`
                        ${buttonBlue(theme, { baseSize: 'sm' })};
                        width: 8em;
                        margin-right: 0.5em
                      `}
                    >
                      Upload
                      <input
                        id="upload-email-header"
                        type="file"
                        accept="image/*"
                        onChange={uploadEmailHeader}
                        ref={emailHeaderFileInput}
                        hidden
                      />
                    </label>
                    <button
                      type="button"
                      css={(theme) => css`
                        ${buttonBlue(theme, { baseSize: 'sm' })};
                        background-color: ${theme.mono0};
                        border: solid 1px ${theme.mono98};
                        color: ${theme.mono98};
                        width: 8em;
                        &:active {
                          color: ${theme.mono98};
                        }
                      `}
                      onClick={clearEmailHeader}
                      disabled={!meta.organisation.emailHeader}
                    >
                      Clear
                    </button>
                  </div>
                  <FileUpload
                    imageUrl={meta.organisation?.emailHeader}
                    uploading={uploadingEmailHeader}
                    image="emailHeader"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div css={css`padding: 0.5em 1.5em`}>
        <h3>Need Help?</h3>
        <p>
          For further support on customising your workspace, visit our
          {' '}
          <span css={(theme: BirdiTheme) => css`
            text-decoration: underline;
            color: ${theme.deepOcean}
          `}
          >
            <a
              href="https://help.birdi.io/en"
              target="_blank"
              rel="noopener noreferrer"
              css={(theme: BirdiTheme) => css`
                color: ${theme.deepOcean};
              `}
            >
              knowledge base
            </a>
          </span>
          {' '}
          or contact us via chat.
        </p>
      </div>
    </div>
  );
};

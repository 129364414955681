import React from 'react';
import { css } from '@emotion/react';
import { useForm } from 'react-hook-form';
import * as Dialog from '@radix-ui/react-dialog';
import CloseSVG from '@birdi/icons/close.svg';
import { OrganisationSelect } from '@birdi/organisation/OrganisationSelect';
import { stroot, auth } from '@birdi/js-sdk';
import {
  contentCSS, portalCSS, closeButtonCSS, paddedButtonFooterCSS,
} from '@birdi/modal-kit';
import { reactSelectLight } from '@birdi/theme/blocks';
import { buttonBlue } from '@birdi/theme';
import RoleSelect from './RoleSelect';

interface MaskModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

export const MaskModal = ({ open, setOpen }: MaskModalProps) => {
  const {
    setValue, handleSubmit,
  } = useForm();

  const onSubmit = (values) => {
    auth.mask(stroot('mask00'), {
      organisationId: values.organisationId,
      roleId: values.roleId,
      ...(values.missionRoleOverride && { missionRoleOverride: values.missionRoleOverride }),
    }).then(() => window.location.reload());
  };
  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Portal>
        <Dialog.Overlay />

        <div css={portalCSS}>
          <Dialog.Content css={contentCSS}>

            <Dialog.Close css={closeButtonCSS}><CloseSVG /></Dialog.Close>
            <Dialog.Title>Mask your account</Dialog.Title>
            <Dialog.Description css={(theme) => css`margin: 0 0 1em 0; color: ${theme.mono50};`}>
              Temporarily change your role &amp; organisation.
            </Dialog.Description>

            <form onSubmit={handleSubmit(onSubmit)}>
              <label htmlFor="roleId">
                Role (Required)
                <RoleSelect
                  theme={reactSelectLight}
                  onChange={(role) => {
                    const roleId = role ? role.value : null;
                    setValue('roleId', roleId);
                  }}
                  inputId="roleId"
                />
              </label>
              <label htmlFor="organisationId" css={css`display: block; margin-top: 0.5em;`}>
                Organisation (Required)
                <OrganisationSelect
                  isClearable
                  placeholder="Search by organisation"
                  theme={reactSelectLight}
                  onClear={() => setValue('organisationId', null)}
                  onOrganisationSelect={(opt) => {
                    setValue('organisationId', opt.value);
                  }}
                  inputId="organisationId"
                />
              </label>
              {/* <label
            htmlFor="mission-role-override"
            css={css`display: block; margin-top: 0.5em;`}
          >
            Mission Role Override
            <Select
              inputId="mission-role-override"
              options={[
                { label: 'Pilot', value: 'pilot' },
                { label: 'Client', value: 'client' },
              ]}
              onChange={(opt) => {
                console.log(opt);
                if (opt === null) {
                  setValue('missionRoleOverride', null)
                } else {
                  setValue('missionRoleOverride', opt.value)
                }
              }}
            />
          </label> */}
              {/* <p css={css`margin: 0;`}>Note: Overriding mission roles applies to <i>all</i> missions.</p> */}
              <footer css={paddedButtonFooterCSS}>
                <button
                  type="submit"
                  css={buttonBlue}
                >
                  Mask
                </button>
              </footer>
            </form>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
    // <Dialog
    //   open={isOpen}
    //   onClose={close}
    //   css={css`
    //     position: fixed;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     top: 0;
    //     z-index: 100;
    //     width: 100%;
    //     height: 100%;
    //     background: hsl(0, 0%, 0%, 0.2);
    //   `}
    // >
    //   <Dialog.Panel css={css`
    //     width: 36em;
    //     padding: 2em;
    //     max-height: 100vh;
    //     overflow-y: scroll;
    //     border-radius: 0.5em;
    //     box-sizing: border-box;
    //     background: white;
    //     // https://neumorphism.io/#e0e0e0
    //     box-shadow: 28px 28px 55px #0006,-28px -28px 55px #ffffff08;
    //   `}
    //   >
    //     <Dialog.Title>Mask your account</Dialog.Title>
    //     <Dialog.Description css={(theme) => css`margin: 0 0 1em 0; color: ${theme.mono50};`}>
    //       Temporarily change your role &amp; organisation.
    //     </Dialog.Description>

  //   </Dialog.Panel>
  // </Dialog>
  );
};

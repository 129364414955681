import React, { Component, ReactNode } from 'react';
import { css } from '@emotion/react';
import { handle_global_error } from '@birdi/js-sdk';
import { fonts } from '@birdi/theme/variables';

export class CrashScreen extends Component<{
  children: ReactNode,
}, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  // Update state so the next render will show the fallback UI.
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error): void {
    handle_global_error('global.crashscreen', error);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    // Bad ending
    if (hasError) {
      return (
        <div
          css={css`display: flex;
            width: 100%;
            justify-content: center;
            height: 100vh;
            align-items: center;
            margin: 0;
            font-family: ${fonts.body};
            position: absolute;
            top: 0;
            padding: 2em;
            box-sizing: border-box;
            left: 0;`}
        >
          <div css={css`max-width: 32em;`}>
            <h1>We encountered a problem :/</h1>
            <h4>The page you were on crashed</h4>
            <p>
              Try refreshing the page. If the issue persists please contact
              {' '}
              <a href="mailto:enquiries@birdi.io">enquiries@birdi.io</a>
              .
            </p>
            <a
              href={`${process.env.APP_URL}`}
            >
              Return to dashboard
            </a>
          </div>
        </div>
      );
    }
    // Good ending
    return children;
  }
}

import pino from 'pino';

export default pino({
  level: 'debug',
  browser: {
    write: {
      debug: o => console.log(o),
    },
  },
});

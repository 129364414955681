import { css } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { MissionCard } from '@birdi/mission-kit/Card';
import { MissionContextMenu } from './ContextMenu';
import { cardList } from '@birdi/theme/blocks';
import { CreateMissionCard, EmptyCard } from '../dashboard/create-mission-card';

export const MissionList = ({
  missions, deleteMission = null, isDashboard = false,
}) => {
  const history = useHistory();

  let missionItems = missions;
  if (isDashboard) {
    missionItems = [ ...missionItems, 'create map card' ];
    if (missionItems.length < 6) {
      while (missionItems.length < 6) missionItems = [ ...missionItems, 'empty card' ];
    }
  }

  return (
    <div css={cardList}>
      {missionItems.map((mission, index) => {
        if (isDashboard && mission === 'create map card') {
          return <CreateMissionCard key={index} />
        } else if (isDashboard && mission === 'empty card') {
          return <EmptyCard key={index} />
        } else {
          return (
            <MissionCard
              key={index}
              mission={mission}
              handleCardClick={() => history.push(`/map/missions/${mission.id}`)}
              ContextMenu={(contextProps) => (
                <MissionContextMenu
                  mission={mission}
                  deleteMission={deleteMission}
                  {...contextProps}
                />
              )}
            />
          )
        }
      })}
    </div>
  );
};

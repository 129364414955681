export enum INTERCOM_TOUR_CODES {
  CREATE_MISSION = 383919,
  ACCOUNT_SETTINGS = 325366,
  USER_MANAGEMENT = 382827,
  PROCESSING_DASHBOARD = 388809,
  ASSESSMENT = 400611,
  EQUIPMENT = 382844,
  CREDENTIALS = 383190,
  CREATE_SITE = 511454,
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import { stroot, inspection as inspectionAPI } from '@birdi/js-sdk';
import { ShareModalController } from '@birdi/mission-kit/Share/ModalController';
import { button } from '@birdi/theme/blocks';
import { GenerateReportBtn } from './GenerateReportBtn';
import { BirdiTheme } from '@birdi/theme';

const headers = ['Inspection Name', 'Progress', ''];

const InspectionTableInternal = ({ campaignId, meta }) => {
  const [inspections, setInspections] = useState([]);
  const [shareModalMission, setShareModalMission] = useState(false);
  const getPolesPluralized = (count) => (count === 1 ? 'pole' : 'poles');
  useEffect(() => {
    if (!campaignId) {
      setInspections([]);
      return;
    }
    inspectionAPI.getByCampaignId(stroot('thaqu0'), campaignId)
      .then((res) => {
        setInspections(res.body.items);
      });
  }, [campaignId]);
  if (!campaignId) return false;
  if (!inspections.length) {
    return (
      <div>
        No inspections found in batch.
      </div>
    );
  }
  return (
    <table>
      {shareModalMission && (
        <ShareModalController
          mission={shareModalMission}
          open={shareModalMission}
          setOpen={setShareModalMission}
        />
      )}
      <thead>
        <tr css={(theme: BirdiTheme) => css`border-bottom: 1px solid ${theme.mono20};`}>
          {headers.map((headerName) => (
            <th key={headerName} css={css`min-width: 16em; text-align: left; padding: 1em 0;`}>
              {headerName}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {inspections.map((inspection) => (
          <tr key={inspection.id}>
            <td css={css`padding: 1em 0em;`}>
              <a
                href={`${process.env.MAP_APP_URL}/missions/${inspection.id}`}
                css={css`color: inherit; text-decoration: underline;`}
              >
                {inspection.name || 'Untitled Inspection'}
              </a>
              <br />
              <span
                css={css`color: #A9A9A9;`}
              >
                {`${inspection.objTotal} released ${getPolesPluralized(Number(inspection.objTotal))}`}
              </span>
            </td>
            <td>
              {`${inspection.objInspected} of ${inspection.objTotal} ${getPolesPluralized(Number(inspection.objTotal))} (${Number(inspection.objPercentInspected)}%) inspected`}
            </td>
            <td>
              <button
                type="button"
                css={(theme: BirdiTheme) => css(button(theme))}
                onClick={() => setShareModalMission(inspection)}
              >
                Share
              </button>
              <GenerateReportBtn inspectionId={inspection.id} />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const InspectionTable = connect((state) => ({
  meta: state.meta,
}))(InspectionTableInternal);

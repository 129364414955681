import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import '../dist/icons.css';
import { Icons } from '../dist/icons';

type Props = {
  icon: Icons;
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  rotate?: '0' | '90' | '180' | '270';
  css?: SerializedStyles;
  readonly className?: string;
};

const BirdiIcon = ({icon, color, size, rotate, className }: Props) => {
  const classNames = ['icon', `icon-${icon}`, `icon-${size}`];
  if (rotate) classNames.push(`icon-rotate-${rotate}`);
  if (className) classNames.push(className);
  return <i className={classNames.join(' ')} css={css`color: ${color}`}/>
}

BirdiIcon.defaultProps = {
  color: 'inherit',
  size: 'sm',
  rotate: null
};

export default BirdiIcon;

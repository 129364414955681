import React from 'react';
import { css, Theme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { breakpoints } from '@birdi/theme';
// import SideNavigation from '../common/SideNavigation';
import SideNavigationProfile from '../common/SideNavigationProfile';
import { CloudHeaderV2 } from '../common/HeaderV2';
import { useMetaSelector } from '../meta/reducer';
import { layoutCSS } from './sidebar-layout-dashboard';

export const SidebarLayoutProfile: React.FC = ({ children }) => {
  const meta = useMetaSelector();
  const location = useLocation();
  return (
    <main css={layoutCSS}>
      <div className="header-wrapper">
        <CloudHeaderV2 />
      </div>
      <div className="sidebar-wrapper">
        <SideNavigationProfile />
      </div>
      <div className="content-wrapper">
        {children}
      </div>
    </main>
  );
};

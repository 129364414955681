import { combineReducers } from 'redux';
// Pre login auth fields
import auth from '../authentication/reducer';
// User profile details
import account from '../account/reducer';
import { credentialsReducer } from '../credentials/credentials-reducer';
import { equipmentReducer } from '../equipment/equipment-reducer';
// General application / user state
import onboarding from '../onboarding/reducer';
// TODO: What is stats?
import stats from '../stats/reducer';
import users from '../organisation-users/reducer';
import { metaReducer } from '../meta/reducer';
import { seatsReducer } from '../billing/seats-reducer';

export default combineReducers({
  auth,
  account,
  credentials: credentialsReducer,
  equipment: equipmentReducer,
  meta: metaReducer,
  onboarding,
  seats: seatsReducer,
  stats,
  users,
});

import React from 'react';
import { css } from '@emotion/react';
import { selectContainer } from '@birdi/theme/blocks';
import { formatName } from '@birdi/utils/src/text';
import CaretSVG from '@birdi/icons/caret.svg';

interface UserShare {
  readOnly: boolean;
  pendingEmail: string;
  email: string;
  fname: string;
  lname: string;
  position: string;
  organisations: string;
}

interface ShareUserProps {
  user: UserShare;
  removeSharedUser: () => void;
  updateUserRole: () => void;
  administrator: boolean;
}

const ExistingUserDetails = ({ user, readOnly }: { user: UserShare, readOnly: boolean }) => (
  <div css={(theme) => css`
    display: flex;
    align-items: left;
    flex-direction: column;
    width: 20em;
    color: ${readOnly ? theme.mono30 : theme.mono60};
    word-wrap: break-word;
    white-space: pre-wrap`}
  >
    <div css={css`font-weight: 700; margin: 0;`}>
      {formatName(user.fname, user.lname)}
    </div>
    <div css={css`margin: 0;`}>
      {user.email}
    </div>
    {user.position && (
    <div css={css`margin: 0;`}>
      {user.position}
    </div>
    )}
    {readOnly && 'Shared by Birdi'}
  </div>
);

export default ({
  user,
  removeSharedUser,
  updateUserRole,
  administrator,
}: ShareUserProps): React.ReactElement => {
  const readOnly = user.readOnly && !administrator;
  return (
    <div
      css={css`
        padding: 0.25rem 0 0.25rem;
        justify-content: space-between;
        align-items: center;
        display: flex;
        span {
          flex: 1 1 auto;
        }
        button {
          flex: 0 0 auto;
        }
      `}
    >
      {user.pendingEmail ? (
        <div css={(theme) => css`
        display: flex;
        align-items: left;
        width: 20em;
        color: ${readOnly ? theme.mono30 : theme.mono60};`}
        >
          {`${user.pendingEmail} (Invited)`}
        </div>
      ) : (
        <ExistingUserDetails user={user} readOnly={readOnly} />
      )}
      <div css={css`display: flex;`}>
        <div css={selectContainer}>
          <select
            value={user.role}
            onChange={(event) => updateUserRole(user.sharedMissionId,
              event.target.value)}
            disabled={readOnly}
          >
            <option value="view">View only</option>
            <option value="edit">Edit</option>
            <option value="inspect">Inspect</option>
            <option value="qa" disabled={!administrator}>
              QA
            </option>
            <option value="partner" disabled={!administrator}>
              Partner
            </option>
          </select>
          <CaretSVG />
        </div>
        <button
          css={(theme) => css`
            border: 1px solid;
            border-color: ${theme.mono30};
            background: white;
            padding: 0 2rem 0 2em;
            &:disabled {
              cursor: default;
              color: ${theme.mono30};
            }
      `}
          type="button"
          onClick={() => removeSharedUser(user)}
          disabled={readOnly}
        >
          Remove
        </button>
      </div>
    </div>
  );
};

import React from 'react';
import { css } from '@emotion/react';
import { pageDescription } from '@birdi/theme/blocks';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import { breakpoints } from '@birdi/theme/variables';
import { BirdiTheme } from '@birdi/theme';
import history from '../../state/history';
import { useMetaSelector } from '../../meta/reducer';

export enum SubscriptionTabs {
  PlanOverview = 'Plan Overview',
  Invoices = 'Invoices',
  // Settings = 'Settings',
  PaymentDetails = 'Payment Details',
}

interface TabNavigationProps {
  activeTab: SubscriptionTabs;
}

export const TabNavigation: React.FC<TabNavigationProps> = ({
  activeTab,
}) => {
  const meta = useMetaSelector();
  return (
    <div>
      <h2 css={css`margin: 1em`}>Plan Overview</h2>
      <div css={pageDescription}>
        <p>Manage your plan, billing information and storage.</p>
        <div>
          <a
            href="https://help.birdi.io/en/collections/3380474-billing-subscription"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn more about your plan and billing</span>
          </a>
        </div>
      </div>
      <div css={css`margin: 1.5em`} />
      <nav css={(theme: BirdiTheme) => css`
        margin-top: -1em;
        @media (max-width: ${breakpoints.s}) {
          width: 100%;
        };
        button {
          font-size: 1em;
          padding: 1em 2em;
          color: ${theme.mono70};
          border-bottom: solid 3px ${theme.mono30};
          font-weight: bold;
          &.active {
            color: ${theme.deepOcean};
            border-bottom: solid 3px ${theme.deepOcean};
          }
          @media (max-width: ${breakpoints.s}) {
            text-aligh: center;
            width: 25%;
            padding: 1em 0;
          };
          @media (max-width: ${breakpoints.xs}) {
            font-weight: normal;
            font-size: 0.75em;
          };
        }
      `}
      >
        <button
          type="button"
          className={activeTab === SubscriptionTabs.PlanOverview ? 'active' : 'inactive'}
          onClick={() => {
            history.push('/billing/plan');
          }}
        >
          {SubscriptionTabs.PlanOverview}
        </button>
        {!meta.organisation.externalBilling && (
          <button
            type="button"
            className={activeTab === SubscriptionTabs.Invoices ? 'active' : 'inactive'}
            onClick={() => {
              history.push('/billing/invoices');
            }}
          >
            {SubscriptionTabs.Invoices}
          </button>
        )}
        {/* <button
          type="button"
          className={activeTab === SubscriptionTabs.Settings ? 'active' : 'inactive'}
          onClick={() => {
            history.push('/billing-subscription/billing-info')
          }}
        >
          {SubscriptionTabs.Settings}
        </button> */}
        {!meta.organisation.externalBilling && (
          <button
            type="button"
            className={activeTab === SubscriptionTabs.PaymentDetails ? 'active' : 'inactive'}
            onClick={() => {
              history.push('/billing/payment-method');
            }}
          >
            {SubscriptionTabs.PaymentDetails}
          </button>
        )}
      </nav>
    </div>
  );
};

/* global fetch */

import querystring from 'querystring';
import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag,
} from '../helpers';

import { ReviewStatus, CaptureStatus } from './objectives';

// data is from API, state is for local stuff
interface BaseMapLayer<T extends string, D, S = { visible: boolean }> {
  id: string;
  type: T;
  parentId: string | null;
  position: number;
  name: string;
  state: S;
  data: D;
}

export type FolderMapLayer = BaseMapLayer<"folder", { processingJobId: string | null; }>;
export type OrthophotoMapLayer = BaseMapLayer<
  "orthophoto",
  { bbox: number[]; tiles: string; thumbnail: string; mediaId: string }
>;

export type GeojsonContoursLayer = BaseMapLayer<
  "geojson_contours",
  { bbox: number[]; mediaId: string; s3Key: string },
  { visible: boolean, flat?: boolean; }
>;

export type ProcessingOutputsLayer = BaseMapLayer<
  "processing_outputs",
  { jobId: string; childrenPositions: Record<string, number>; }
>;

// TBD: GCPs/reference set, photos, mesh, pointcloud

export type MapLayer =
  | FolderMapLayer
  | OrthophotoMapLayer
  | GeojsonContoursLayer
  | ProcessingOutputsLayer;

// TODO: Opts?
export const list = (st: SpanTag, opts): Promise<APIResponse> => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  const url = new URL(`${config.API_URL}/v2/missions${query}`);
  return fetchAPI(stleaf(st, 'aez9ei'), url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const listShared = (st: SpanTag, opts) => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  const url = new URL(`${config.API_URL}/v2/missions/shared/${query}`);
  return fetchAPI(stleaf(st, 'iek9ni'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export interface GetMediaV2Opts {
  sortKey?: string; // TODO: Enum
  sortDirection?: string; // TODO: Enum
  qaStatus?: string[];
  unassociated?: boolean;
  tags?: string[];
  mediaName?: string;
  limit?: number;
  categories?: string[];
  objectives?: string[];
  assessmentStatus?: string[];
  // TODO: Add opts:
  // text?: string;
  // starred?: boolean;
  baseType?: string;
  cursor?: {
    id: string;
    createdAt: string;
  }
}

export enum MediaSortDirection {
  oldest = 'ASC',
  newest = 'DESC',
}
export enum MediaSortKeys {
  title = 'title',
  createdAt = 'created_at',
  updatedAt = 'updated_at',
  fileSize = 'file_size',
  downloadCount = 'download_count',
  viewCount = 'view_count',
}

export const getMediaV2 = (st: SpanTag, id: string, {
  sortDirection = MediaSortDirection.newest,
  qaStatus, unassociated, tags, mediaName, limit,
  categories, objectives, assessmentStatus, baseType,
  cursor,
}: GetMediaV2Opts): Promise<APIResponse> => {
  const sort = { sortDirection };
  const searchParams = new URLSearchParams({
    ...(qaStatus && { qaStatus: qaStatus.join() }),
    ...(unassociated && { unassociated: JSON.stringify(unassociated) }),
    ...(tags && { tags: tags.join() }),
    ...(sort && { sort: JSON.stringify(sort) }),
    ...(mediaName && { mediaName }),
    ...(limit && { limit: JSON.stringify(limit) }),
    ...(categories && { categories: categories.join() }),
    ...(objectives && { objectives: objectives.join() }),
    ...(assessmentStatus && { assessmentStatus: assessmentStatus.join() }),
    // ...(text && { text }),
    // ...(starred && { starred }),
    ...(baseType && { baseType }),
    ...(cursor && { cursor: JSON.stringify(cursor) }),
  });
  const url = new URL(`${config.API_URL}/v2/missions/${id}/media`);
  url.search = searchParams.toString();

  return fetchAPI(stleaf(st, 'eek7ta'), url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getTags = (st: SpanTag, id: string, searchTerm: string): Promise<APIResponse> => {
  let url = (new URL(`${config.API_URL}/v2/missions/${id}/tags`)).toString();
  if (searchTerm) url = (new URL(`?search=${searchTerm}`, url)).toString();
  return fetchAPI(stleaf(st, 'aipee5'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getMediaCategories = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v2/missions/${id}/media/categories`);
  return fetchAPI(stleaf(st, 'xooth0'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const create = (st: SpanTag, obj: any): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v2/missions`);
  return fetchAPI(stleaf(st, 'ohsa6y'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(obj),
  });
};

export const update = (st: SpanTag, id: string, fieldsToUpdate): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v1/missions/${id}`);
  return fetchAPI(stleaf(st, 'quoh4i'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const get = (st: SpanTag, id): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v2/missions/${id}`);
  return fetchAPI(stleaf(st, 'thoo1v'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getJob = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = (new URL(`${config.API_URL}/v2/missions/${id}/job`)).toString();
  return fetchAPI(stleaf(st, 'iit2xu'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const updateJob = (st: SpanTag, id: string, fieldsToUpdate): Promise<APIResponse> => {
  const url = (new URL(`${config.API_URL}/v2/missions/${id}/job`)).toString();
  return fetchAPI(stleaf(st, 'ahp5ei'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fieldsToUpdate),
  });
};

export const getMissionLogs = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = (new URL(`${config.API_URL}/v1/missions/${id}/tracking`)).toString();
  return fetchAPI(stleaf(st, 'so8eed'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getCover = (st: SpanTag, id): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${id}/cover`;
  return fetchAPI(stleaf(st, 'ijeth6'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const destroy = (st: SpanTag, id): Promise<APIResponse> => {
  const url = new URL(`${config.API_URL}/v1/missions/${id}`).toString();
  return fetchAPI(stleaf(st, 'edo7or'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const getActiveContract = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/missions/${id}/active-contract`;
  return fetchAPI(stleaf(st, 'phiel0'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const deleteMedia = (st: SpanTag, missionId: string, mediaIds: string[]): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/media`;
  return fetchAPI(stleaf(st, 'dud5ge'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
    body: JSON.stringify({ mediaIds }),
  });
};

export enum MediaFilterSelector {
  None = 'none',
}

export interface GetObjectiveOpts {
  status?: ReviewStatus[];
  associatedMedia?: MediaFilterSelector;
  coordinates?: [number, number];
  orgUniqueIdSearch?: string;
  objectiveName?: string;
  limit?: number;
  type?: 'parents' | 'children'
  captureStatus?: CaptureStatus[];
}

export const getObjectives = (st: SpanTag, id: string, opts: GetObjectiveOpts = {}): Promise<any> => {
  const searchParams = new URLSearchParams({
    ...(opts.status && { status: opts.status.join(',') }),
    ...(opts.associatedMedia && { associatedMedia: opts.associatedMedia }),
    ...(opts.coordinates && { coordinates: opts.coordinates.join() }),
    ...(opts.orgUniqueIdSearch && { orgUniqueIdSearch: opts.orgUniqueIdSearch }),
    ...(opts.type && { type: opts.type }),
    ...(opts.objectiveName && { objectiveName: opts.objectiveName }),
    ...(opts.limit && { limit: opts.limit.toString() }),
    ...(opts.captureStatus && { captureStatus: opts.captureStatus.join(',') }),
  });
  const url = new URL(`${config.API_URL}/v2/missions/${id}/objectives`);
  url.search = searchParams.toString();
  return fetchAPI(stleaf(st, 'upoi6o'), url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getObjectivesShapefileUrl = (id: string, opts: GetObjectiveOpts = {}): string => {
  const searchParams = new URLSearchParams({
    ...(opts.status && { status: opts.status.join(',') }),
    ...(opts.associatedMedia && { associatedMedia: opts.associatedMedia }),
    ...(opts.coordinates && { coordinates: opts.coordinates.join() }),
    format: 'shapefile',
  });
  const url = new URL(`${config.API_URL}/v2/missions/${id}/objectives`);
  url.search = searchParams.toString();
  return url.toString();
};

export const createObjective = (st: SpanTag, id: string, fields): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${id}/objectives`;
  return fetchAPI(stleaf(st, 'phei3c'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const updateObjective = (st: SpanTag, missionId: string, objId: string, fields): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/objectives/${objId}`;
  return fetchAPI(stleaf(st, 'yaip8a'), url, {
    method: 'PATCH',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const deleteObjective = (st: SpanTag, missionId: string, objId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/objectives/${objId}`;
  return fetchAPI(stleaf(st, 'aing2b'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const createMappingObjective = (st: SpanTag, id: string, fields): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${id}/objectives/map`;
  return fetchAPI(stleaf(st, 'chei3i'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const getJobAttachments = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${id}/attachments`;
  return fetchAPI(stleaf(st, 'xea4ae'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const completeObjectiveUpload = (
  st: SpanTag,
  opts: { missionId: string, objectiveId: string },
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${opts.missionId}/objectives/${opts.objectiveId}/complete-capture`;
  return fetchAPI(stleaf(st, 'oad3ne'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
  });
};

export const getAssessments = (st: SpanTag, missionId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/assessments`;
  return fetchAPI(stleaf(st, 'ele9ph'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getMapLayers = (st: SpanTag, missionId: string): Promise<APIResponse<{items: MapLayer[]}>> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/layers`;
  return fetchAPI(stleaf(st, 'aiy6ah'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const deleteMapLayer2 = (st: SpanTag, layerId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/layers/${layerId}`;
  return fetchAPI(stleaf(st, 'dellay'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const reorderMapLayers = (
  st: SpanTag, missionId: string, layers: Array<{ id: string; position: number; }>,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/layers`;
  return fetchAPI(stleaf(st, 'ahfec2'), url, {
    method: 'PATCH',
    credentials: 'include',
    headers,
    body: JSON.stringify(layers),
  });
};

interface MapLayerProps {
  name: string;
  parentId: string | null;
}

export const createMapFolderLayer = (
  st: SpanTag, missionId: string, props: MapLayerProps,
): Promise<APIResponse<{ id: string }>> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/layers/folder`;
  return fetchAPI(stleaf(st, 'osh0ae'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(props),
  });
};

export const createMapProcessingJobLayers = (
  st: SpanTag, missionId: string, jobIds: string[]
): Promise<APIResponse<{layers: MapLayer[]}>> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/layers/processing-jobs`;
  return fetchAPI(stleaf(st, 'uf7cie'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(jobIds),
  });
}

export const updateMapLayer = (
  st: SpanTag,
  missionId: string,
  layerId: string,
  props: Partial<MapLayerProps> & {childrenPositions?: Record<string, number>;},
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/layers/${layerId}`;
  return fetchAPI(stleaf(st, 'veng8n'), url, {
    method: 'PATCH',
    credentials: 'include',
    headers,
    body: JSON.stringify(props),
  });
};

export const deleteMapLayer = (
  st: SpanTag, missionId: string, layerId: string,
): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/layers/${layerId}`;
  return fetchAPI(stleaf(st, 'io9ooc'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const updateSite = (st: SpanTag, missionId: string, siteId: string | null): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/missions/${missionId}/site`;
  return fetchAPI(stleaf(st, 'updsit'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({ siteId })
  });
};

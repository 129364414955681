import React from 'react';
import cx from 'classnames';
import { css } from '@emotion/react';
import { Link, LinkProps } from 'react-router-dom';
import history from '../../state/history';
import { BirdiTheme } from '@birdi/theme';

export const dividerStyle = (theme: BirdiTheme) => css`
  width: cal(100% - 2em);
  height: 1px;
  background-color: ${theme.mono20};
  margin: 0.25em;
`;

const linkStyle = css`
  width: cal(100% - 2em);
  border-radius: 7px;
  padding: 0.5em;
`;

export function SidebarLink({
  to, match, children, disabled=false, id=null, dashboard=false,
}) {
  const otherProps: Partial<LinkProps> = {};
  if (id) otherProps.id = id;
  if (disabled) {
    return (
      <div css={(theme) => css`
        ${linkStyle};
        color: ${theme.mono40};
        padding-left: ${dashboard ? '2em' : '0.5em'}
      `}
      >
        {children}
      </div>
    )
  } else {
    return (
      <Link
        to={to}
        {...otherProps}
      >
        <div css={(theme) => css`
          ${linkStyle};
          color: ${to === match ? theme.deepOcean : theme.midnight};
          &:hover {
            background-color: ${theme.lightOcean};
          }
          padding-left: ${dashboard ? '2em' : '0.5em'};
        `}
        >
          {children}
        </div>
      </Link>
    );
  }
}

export function TopMenuButton({
  link, label, closeMenu,
}) {
  return (
    <button
      type="button"
      css={(theme) => css`
      color: ${theme.midnight};
      padding: 0.5em;
      width: 100%;
      text-align: left;
      margin: 0;
      border-radius: 3px;
      &:hover {
        background: ${theme.lightOcean};
      }
    `}
      onClick={() => {
        history.push(link);
        closeMenu();
      }}
    >
      {label}
    </button>
  );
}

/* globals document */

import React from 'react';
import { createRoot } from 'react-dom/client';
import loadable from '@loadable/component';
import { Provider, connect } from 'react-redux';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import {
  Router, Route, Switch, Redirect, RouteComponentProps,
} from 'react-router-dom';
import { loadGTM } from '@birdi/gtm';
import { loadFirstPromoter } from '@birdi/first-promoter';
import { loadHubspot } from '@birdi/hubspot';
import { Toaster } from 'react-hot-toast';
import { config } from '@birdi/js-sdk';
import { ThemeProvider, themeLight } from '@birdi/theme';
import { MetaBar } from '@birdi/dev-tools/MetaBar';
import { CrashScreen } from '@birdi/crash-screen';
import { loadIntercom, showIntercom } from '@birdi/intercom';
import AuthContainer from './authentication';
import SignIn from './authentication/SignInPage';
import SignUp from './authentication/SignUpPage';
import ResetPassword from './authentication/ResetPassword';
import ResetOrganisationPassword from './authentication/ResetOrganisationPassword';
import { getInitialAuthState } from './authentication/actions';
import ResendVerification from './authentication/ResendVerification';
import VerifyEmailWithToken from './authentication/VerifyEmailWithToken';
import { AuthenticatedRoute } from './authentication/AuthenticatedRoute';
import history from './state/history';
import { store } from './state';
import { AssessmentType } from './assessment-type';
import { Inspections } from './inspections';
import Onboarding1Welcome from './onboarding/Step1Welcome';
import Onboarding2Workspace from './onboarding/Step2Workspace';
import Onboarding3ThankYou from './onboarding/Step3ThankYou';
import { Dashboard } from './dashboard/main';
import { AllMissions } from './missions/all-missions';
import { IncomingJobRequests } from './incoming-request/index';
import { SharedMissions } from './missions/SharedMissions';
import { UserDetails } from './account/UserDetails';
import { PilotSettings } from './account/PilotSettings';
import { MarketingPreferences } from './account/MarketingPreferences';
import { Credentials } from './credentials';
import Assets from './assets';
import { EquipmentSingle } from './equipment-single';
import { Equipment } from './equipment';
import { Users } from './organisation-users/main';
import { UserManagement } from './organisation-users/user-management';
import { PlanOptions } from './billing/plan-options';
import { PlanOverview } from './billing/plan-overview/main';
import { Invoices } from './billing/plan-overview/invoices';
import { BillingInfo } from './billing/plan-overview/billing-info';
import { PaymentMethod } from './billing/plan-overview/payment-method';
import { CustomisationBranding } from './settings/branding';
import { SidebarLayoutDashboard } from './layouts/sidebar-layout-dashboard';
import { SidebarLayoutProfile } from './layouts/sidebar-layout-profile';
import { SidebarLayoutOrganisation } from './layouts/sidebar-layout-organisation';
import { DefaultLayout } from './layouts/default-layout';
import { UnauthenticatedShare } from './unauthenticated-share/UnauthenticatedShare';
import { NotFoundScreen } from './meta/NotFoundScreen';
import ProcessingView from './processing/dashboard';
import { AssessmentSeverity } from './assessment-severity';
import { CostCalculator } from './processing/cost-calculator';
import { Sites } from './sites'

// Set API routes from Webpack injected defaults
config.setOpts({
  API_URL: process.env.API_URL,
  JWT_NAME: process.env.JWT_NAME,
  S3_ZIP_URL: process.env.S3_ZIP_URL,
  POINTS_URL: process.env.POINTS_URL,
});

if (process.env.COOKIE_DOMAIN) {
  config.setOpts({
    COOKIE_DOMAIN: process.env.COOKIE_DOMAIN,
  });
}

let DevTools = () => <div>404</div>;
if (process.env.DEVTOOLS) {
  DevTools = loadable(() => import('@birdi/dev-tools/DevTools')
    .then((module) => module.DevTools));
}

if (process.env.INTERCOM_ENABLED) {
  loadIntercom(false);
  showIntercom();
}

if (process.env.GTM_ENABLED) {
  loadGTM();
}

if (process.env.FIRST_PROMOTER_ENABLED) {
  loadFirstPromoter({
    domain: process.env.COOKIE_DOMAIN,
    testMode: process.env.FIRST_PROMOTER_TEST_MODE,
    proxy: `${process.env.API_URL}/v2/service/fprom_proxy`,
  });
}

if (process.env.HUBSPOT_TRACKING_ENABLED) {
  loadHubspot();
}

if (process.env.POSTHOG_API_KEY) {
  posthog.init(process.env.POSTHOG_API_KEY, { api_host: process.env.POSTHOG_API_HOST });
}

const MetaBarConnected = connect(
  (state) => ({ meta: state.meta }),
)(({ meta }) => (
  <MetaBar
    id={meta.id}
    scopeRole={meta.scopeRole}
    organisation={meta.organisation}
    masked={meta.masked}
    missionRoleOverride={meta.missionRoleOverride}
    opsActions={meta.opsActions}
  />
));

const RedirectToMap = ({ location }: RouteComponentProps) => {
  // Possible to get this from the route path?
  const path = location.pathname.replace('/map', '');
  const href = `${process.env.MAP_APP_URL}${path}${location.search}${location.hash}`;
  window.location.href = href;

  return null;
};

const Index = () => (
  <CrashScreen>
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <ThemeProvider>
          <Router history={history}>
            <Switch>
              {/* Identity & auth layout */}
              <Route exact path="/sign-in" component={() => <AuthContainer Section={SignIn} />} />
              <Route exact path="/sign-up" component={() => <AuthContainer Section={SignUp} />} />
              <Route exact path="/reset-password" component={() => <AuthContainer Section={ResetPassword} />} />
              <Route exact path="/set-password" component={() => <AuthContainer Section={ResetOrganisationPassword} />} />
              <Route exact path="/resend-verification" component={() => <AuthContainer Section={ResendVerification} />} />
              <Route exact path="/email-verification" component={() => <AuthContainer Section={VerifyEmailWithToken} />} />
              <AuthenticatedRoute exact path="/onboarding" component={Onboarding1Welcome} />
              <AuthenticatedRoute exact path="/onboarding/workspace" component={Onboarding2Workspace} />
              <AuthenticatedRoute exact path="/onboarding/thank-you" component={Onboarding3ThankYou} />
              {/* Job requests */}
              <AuthenticatedRoute exact path="/job-requests/incoming" component={() => <IncomingJobRequests />} layout={SidebarLayoutDashboard} />
              {/* Filtered missions */}
              <AuthenticatedRoute exact path="/missions/mine" component={() => <AllMissions association="mine" />} layout={SidebarLayoutDashboard} />
              <Redirect exact from="/missions" to="/missions/mine" />
              <AuthenticatedRoute exact path="/missions/organisation" component={() => <AllMissions association="organisation" />} layout={SidebarLayoutDashboard} />
              <AuthenticatedRoute exact path="/missions/demo-missions" component={() => <AllMissions association="sample" />} layout={SidebarLayoutDashboard} />
              <AuthenticatedRoute exact path="/missions/shared" component={() => <SharedMissions />} layout={SidebarLayoutDashboard} />
              <Route
                exact
                path="/missions/:id/map"
                component={(props: RouteComponentProps) => {
                // This is a redirect from our old map page link to our new one
                // TODO: Remove in 2023
                  window.location.href = `${process.env.MAP_APP_URL}/missions/${props.match.params.id}`;
                  return <></>;
                }}
              />
              {/* Main app layout */}
              <AuthenticatedRoute exact path="/" component={Dashboard} layout={SidebarLayoutDashboard} />
              <AuthenticatedRoute exact path="/assets" component={Assets} layout={SidebarLayoutDashboard} />
              <AuthenticatedRoute exact path="/equipment" component={Equipment} layout={SidebarLayoutProfile} />
              <AuthenticatedRoute exact path="/equipment/:id" component={EquipmentSingle} />
              <AuthenticatedRoute exact path="/user/details" component={UserDetails} layout={SidebarLayoutProfile} />
              <AuthenticatedRoute exact path="/user/pilot" component={PilotSettings} layout={SidebarLayoutProfile} />
              <AuthenticatedRoute exact path="/user/marketing" component={MarketingPreferences} layout={SidebarLayoutProfile} />
              <AuthenticatedRoute exact path="/user/credentials" component={Credentials} layout={SidebarLayoutProfile} />
              {/* organisations */}
              <AuthenticatedRoute exact path="/users" component={Users} layout={SidebarLayoutOrganisation} />
              <AuthenticatedRoute exact path="/users/:scopeId" component={UserManagement} layout={SidebarLayoutOrganisation} />
              <AuthenticatedRoute exact path="/assessment-types" component={AssessmentType} layout={DefaultLayout} />
              <AuthenticatedRoute exact path="/assessment-severity" component={AssessmentSeverity} layout={DefaultLayout} />
              <AuthenticatedRoute exact path="/billing/options" component={PlanOptions} layout={DefaultLayout} />
              <AuthenticatedRoute exact path="/billing/plan" component={PlanOverview} layout={SidebarLayoutOrganisation} />
              <AuthenticatedRoute exact path="/billing/invoices" component={Invoices} layout={SidebarLayoutOrganisation} />
              <AuthenticatedRoute exact path="/billing/billing-info" component={BillingInfo} layout={SidebarLayoutOrganisation} />
              <AuthenticatedRoute exact path="/billing/payment-method" component={PaymentMethod} layout={SidebarLayoutOrganisation} />
              <AuthenticatedRoute exact path="/settings/customisation/branding" component={CustomisationBranding} layout={SidebarLayoutOrganisation} />
              <AuthenticatedRoute exact path="/inspections" component={Inspections} layout={SidebarLayoutOrganisation} />
              {/* mission share invitation */}
              <Route exact path="/share/:id" component={UnauthenticatedShare} />
              <AuthenticatedRoute exact path="/processing" component={ProcessingView} layout={DefaultLayout} />
              <AuthenticatedRoute exact path="/cost-calculator" component={CostCalculator} layout={DefaultLayout} />
              <AuthenticatedRoute exact path="/sites" component={Sites} layout={SidebarLayoutDashboard} />
              {/* dev */}
              <Route exact path="/dev/:resource" component={DevTools} />
              <Route path="/map/*" component={RedirectToMap} />
              {/* 404 not found */}
              <Route component={NotFoundScreen} />
            </Switch>
            <MetaBarConnected />
          </Router>
          <Toaster position="top-right" containerStyle={{ top: 80 }} />
        </ThemeProvider>
      </Provider>
    </PostHogProvider>
  </CrashScreen>
);

getInitialAuthState()(store.dispatch);

const root = createRoot(document.getElementById('root')!);
root.render(<Index />);

import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  errorMessage, blockLabel, nextButtonHolder, textInput,
} from '@birdi/theme/form';
import Select from 'react-select';
import { formStyles } from '@birdi/theme/blocks';
import WelcomeSVG from '@birdi/icons/welcome.svg';
import { css } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import * as onboardingActions from './reducer';
import * as userActions from '../account/actions';
import * as onboardingStyles from './styles';
import IndustryAndTeamSelect from './IndustryAndTeamSelect';
import { StepsDots } from './StepsDots';
import Heading from './Heading';

const yesNoOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

const hearAboutUsList = [
  { value: 'Online search (e.g. Google, Bing)', label: 'Online search (e.g. Google, Bing)' },
  { value: 'Social media (e.g. Facebook, LinkedIn)', label: 'Social media (e.g. Facebook, LinkedIn)' },
  { value: 'Software review site (e.g. G2, Capterra)', label: 'Software review site (e.g. G2, Capterra)' },
  { value: 'You met us at an event (e.g. conference, webinar)', label: 'You met us at an event (e.g. conference, webinar)' },
  { value: 'Someone referred us to you (e.g. a Birdi Partner)', label: 'Someone referred us to you (e.g. a Birdi Partner)' },
];

const sanitizeValue = (val) => {
  if (typeof val === 'boolean') {
    return {
      value: val,
      label: val ? 'Yes' : 'No',
    };
  }
  return null;
};

const Step1Welcome = (props): React.ReactElement => {
  const {
    account, updateStateAndPatch, onboarding,
    history, updateAnswers, getProfile,
  } = props;

  const {
    control, formState, handleSubmit,
  } = useForm();

  useEffect(() => {
    getProfile();
  }, []);

  const next = () => {
    if (onboarding.industry === 'Drone Services') {
    } else {
      updateStateAndPatch({ jobNotifications: null });
    }
    history.push('/onboarding/workspace');
  };

  if (!account.loaded) return <div css={onboardingStyles.screenContainer} />;
  if (!onboarding.loaded) return <div css={onboardingStyles.screenContainer} />;

  return (
    <div css={onboardingStyles.screenContainer}>
      <div css={onboardingStyles.insideContainer}>
        <div css={css`display: flex; flex-direction: row; align-items: center`}>
          <WelcomeSVG css={css`font-size: 4rem; margin-right: 0.25em;`} />
          <div css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            span { display: block }
            `}
          >
            <div css={css`span { display: block }`}>
              <span>{`Welcome ${account.fname}`}</span>
              <Heading title="A bit about you" />
            </div>
            <StepsDots step={1} />
          </div>
        </div>
        <p css={css`margin: 0.5em 0`}>
          Thanks for choosing Birdi! Let's get you set up with a few quick questions.
        </p>
        <div css={onboardingStyles.breakLine} />
        <form
          css={(theme: BirdiTheme) => css(formStyles(theme))}
          onSubmit={handleSubmit(next)}
        >
          <IndustryAndTeamSelect
            control={control}
            formState={formState}
          />
          <label css={blockLabel} htmlFor="hearAboutUs">
            How did you first hear about us?
            <Controller
              render={({
                field: { onChange },
              }) => (
                <Select
                  inputId="hearAboutUs"
                  instanceId="hearAboutUs"
                  options={hearAboutUsList}
                  placeholder="Select"
                  onChange={(option) => {
                    onChange(option.value);
                    updateAnswers({ hearAboutUs: option.value, reference: '' });
                  }}
                  defaultValue={{ value: onboarding.hearAboutUs, label: onboarding.hearAboutUs }}
                />
              )}
              control={control}
              name="hearAboutUs"
              rules={{ required: true }}
              defaultValue={onboarding.hearAboutUs ? { value: onboarding.hearAboutUs, label: onboarding.hearAboutUs } : null}
            />
            {formState.errors.hearAboutUs && (
              <span css={css`${errorMessage}; margin-top: 0.5em;`}>
                Field is required.
              </span>
            )}
          </label>
          {onboarding.hearAboutUs === 'Someone referred us to you (e.g. a Birdi Partner)' && (
            <label css={blockLabel} htmlFor="reference">
              Reference
              <Controller
                render={({
                  field: { onChange },
                }) => (
                  <input
                    css={textInput}
                    type="text"
                    id="reference"
                    onChange={(event) => {
                      onChange(event.target.value);
                      updateAnswers({ reference: event.target.value });
                    }}
                    defaultValue={onboarding.reference}
                  />
                )}
                control={control}
                name="reference"
                rules={{ required: true }}
                defaultValue={onboarding.reference}
              />
              {formState.errors.reference && (
                <span css={errorMessage}>Please provide a reference.</span>
              )}
            </label>
          )}
          <div css={nextButtonHolder}>
            <button
              type="submit"
              css={(theme: BirdiTheme) => css`
                ${buttonBlue(theme)}
                height: 2.8rem;
                width: 7rem;
              `}
            >
              <span>Next</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect((state) => ({
  account: state.account,
  onboarding: state.onboarding,
}), (dispatch) => ({
  getProfile: () => dispatch(userActions.getProfile()),
  updateStateAndPatch: (field) => dispatch(userActions.updateStateAndPatch(field)),
  updateAnswers: (field) => dispatch(onboardingActions.updateAnswers(field)),
}))(withRouter(Step1Welcome));

/* globals window */

import BirdiIcon, { Icons } from '@birdi/icons';
import {
  buttonTransparent, contextualHeader, pageDescription,
} from '@birdi/theme/blocks';
import { BirdiTheme, themeLight } from '@birdi/theme/theme';
import { Helmet } from 'react-helmet';
import { css } from '@emotion/react';
import PlayCircleSVG from '@birdi/icons/play-circle.svg';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DownloadButton } from './DownloadButton';
import { ProcessingFilters } from './ProcessingFilters';
import { ProcessingOverview } from './ProcessingOverview';
import { ProcessingContext, ProcessingState } from './ProcessingState';
import { ProcessingTable } from './ProcessingTable';
import { INTERCOM_TOUR_CODES } from '../common/consts';
import { BackBar } from '../common/HeaderV2/BackBar';

const Processing = observer(() => {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <style>{`body { background-color:${themeLight.sand}; }`}</style>
      </Helmet>
      <BackBar to="/users">
        <DownloadButton />
      </BackBar>
      <div css={(theme:BirdiTheme) => css`
        ${pageDescription(theme)};
        background-color: ${theme.mono0};
        border: solid 1px ${theme.mono30};
      `}
      >
        <h2>Processing Dashboard</h2>
        <p>View, filter and export your processing jobs.</p>
        <div>
          <button
            type="button"
            onClick={() => {
              window?.Intercom && window.Intercom('startTour', INTERCOM_TOUR_CODES.PROCESSING_DASHBOARD);
            }}
          >
            <PlayCircleSVG />
            <span>Take a tour of processing (Free & Growth plan)</span>
          </button>
          <a
            href="http://help.birdi.io/en/articles/6974662-how-to-request-processing-outputs-on-a-set-of-data-orthophotos-hillshades-etc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn how to request processing (Free & Growth plan)</span>
          </a>
        </div>
      </div>
      <div css={(theme: BirdiTheme) => css`padding: 0.5rem 2rem;`}>
        <ProcessingFilters />
        <ProcessingOverview />
        <ProcessingTable />
      </div>
    </>
  );
});

const ProcessingViewProvider = (): JSX.Element => {
  const [processingState] = useState(new ProcessingState());
  return (
    <ProcessingContext.Provider value={processingState}>
      <Processing />
    </ProcessingContext.Provider>
  );
};

export default ProcessingViewProvider;

import React from 'react';
import { css } from '@emotion/react';
import { pageDescription } from '@birdi/theme/blocks';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import { BirdiTheme } from '@birdi/theme';
import history from '../state/history';

export enum CustomisationTabs {
  Branding = 'Branding',
}

interface TabNavigationProps {
  activeTab: CustomisationTabs;
}

export const TabNavigation: React.FC<TabNavigationProps> = ({ activeTab }) => (
  <div>
    <h2 css={css`margin: 1em`}>Customization</h2>
    <div css={pageDescription}>
      <p>Manage your workspace and brand settings.</p>
      <div>
        <a
          href="https://help.birdi.io/en/articles/7043705-custom-branding"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BookOpenSVG />
          <span>Learn how to customise your branding</span>
        </a>
      </div>
    </div>
    <nav css={(theme: BirdiTheme) => css`
      button {
        font-size: 1em;
        margin: 0;
        padding: 1em 2em;
        color: ${theme.mono70};
        border-bottom: solid 3px ${theme.mono30};
        font-weight: bold;
        &.active {
          color: ${theme.deepOcean};
          border-bottom: solid 3px ${theme.deepOcean};
        }
      }
    `}
    >
      <button
        type="button"
        className={activeTab === CustomisationTabs.Branding ? 'active' : 'inactive'}
        onClick={() => {
          history.push('/settings/customisation/branding');
        }}
      >
        {CustomisationTabs.Branding}
      </button>
    </nav>
  </div>
);

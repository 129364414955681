import React from 'react';
import { css, Theme } from '@emotion/react';
import {
  planOptions, planTitle, planSubTitle, currentPlanTitle,
  planSection1, planSection2, planSection3, planOptionsColumn,
  planTitleHolder, planSection4,
} from '@birdi/theme/blocks';
import FreePlanSVG from '@birdi/icons/free-plan-2.svg';
import GreenCheckCircleSVG from '@birdi/icons/green-check-circle.svg';
import { useMetaSelector } from '../meta/reducer';
import { CancelButton } from './cancel-button';
import { PriceData } from './types';
import { sanitizedPrice, storagePricing, sanitizedPAYGProcessingPrice } from './utils';

interface FreePlanOptionProps {
  paymentInterval: 'monthly' | 'annual';
  prices: PriceData;
  planIsCanceled: boolean;
}

export const FreePlanOption = (props: FreePlanOptionProps): JSX.Element => {
  const meta = useMetaSelector();
  const { paymentInterval, prices, planIsCanceled } = props;
  const title = 'free';
  const {currency} = prices.free;
  const features = [
    'Forever access to basic Birdi platform',
    'Upload and visualize any data set',
    'Auto image location and orientation mapping',
    'Data processing (Pay as You Go)',
    'Basic 2D and 3D processed map outputs',
    'In-platform annotation tools to measure distance and area',
    'Basic base map layers',
    'Email & chat support',
  ];

  // `Additional 1Tb storage ${sanitizedPrice(storagePricing.free[currency], currency)}/month`

  const paygPriceIncludeGSD = sanitizedPAYGProcessingPrice(
    prices.free.gpCost, currency,
  );

  return (
    <div css={planOptionsColumn}>
      <span className='option-header'>Individuals & hobbyists</span>
      <div css={planOptions}>
        <div css={planSection1}>
          <FreePlanSVG width="7em" height="7em" />
          <div css={(theme) => planTitleHolder(theme.lightSunConure)}>
            <span css={planTitle}>{title}</span>
          </div>
          <span>For individuals who want the basics and to request occasional PAYG processing.</span>
          <span css={css`font-weight: bold`}>1 user.</span>
        </div>
        <div css={planSection2}>
          <span css={planSubTitle}>$0</span>
          <span>per month</span>
          <span>plus PAYG processing</span>
        </div>
        <div css={planSection3}>
          {title === meta.organisation.plan && (
            <div css={currentPlanTitle}>Current plan</div>
          )}
          {title !== meta.organisation.plan && planIsCanceled && (
            <div css={currentPlanTitle}><span>Already cancelled</span></div>
          )}
          {title !== meta.organisation.plan && !planIsCanceled && (
            <CancelButton
              currentPlan={meta.organisation.plan}
              differentInterval={!!meta.organisation.planInterval && (meta.organisation.planInterval !== paymentInterval)}
              planIsCanceled={planIsCanceled}
            />
          )}
        </div>
        <div css={planSection4}>
          {features.map((feature) => (
            <div
              key={feature}
              css={css`
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 0.25em;
                span {
                  display: block;
                  text-align: left;
                }
              `}
            >
              <GreenCheckCircleSVG
                css={(theme: Theme) => css`
                  color: ${theme.flora};
                  min-width: 1.25em;
                  width: 1.25em;
                  height: 1.25em;
                  margin-right: 0.5em;
                  margin-top: 0.25em;
                `}
              />
              <span>{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

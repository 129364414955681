import React, { useContext } from 'react';
import { css } from '@emotion/react';
import { observer } from 'mobx-react';
import { fonts } from '@birdi/theme';
import { ProcessingContext } from './ProcessingState';

export const ProcessingOverview = observer(() => {
  const processingState = useContext(ProcessingContext);
  const {
    totalCount, totalMissions, totalGpCount,
  } = processingState;
  return (
    <>
      <h4>Processing Overview</h4>
      <div css={css`display: flex; gap: 2rem; margin-bottom: 1rem; font-family: ${fonts.display};`}>
        <span>
          Total number of maps:&nbsp;
          <b>{totalMissions}</b>
        </span>
        <span>
          Total number of objectives:&nbsp;
          <b>{totalCount}</b>
        </span>
        <span>
          Total Gigapixels:&nbsp;
          <b>{totalGpCount}</b>
        </span>
      </div>
    </>
  );
});

import { updateMeta } from '../meta/reducer';
import { store } from '../state/index';

/**
 * This function is run on sign in and sign up pages
 * and looks at the url search params
 * and adds them to the redux meta state as required
 */
export function saveAuthSearchParams(): void {
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');
  const invitation = searchParams.get('invitation');
  const patch = {
    ...(redirect && { redirect }),
    ...(invitation && { invitation }),
  };
  store.dispatch(updateMeta(patch));
}

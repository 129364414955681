import { BirdiTheme, breakpoints } from '@birdi/theme';
import { css } from '@emotion/react';

export const boxStyle = (theme: BirdiTheme) => css`
  display: flex;
  flex-direction: row;
  width: 65%;
  min-height: 8em;
  border: solid 1px ${theme.mono40};
  background-color: ${theme.mono20};
  padding: 1em;
  @media (max-width: ${breakpoints.s}) {
    width: calc(100% - 2em);
  }
  @media (max-width: ${breakpoints.xs}) {
    min-height: 7em;
  }
`;

export const itemsStyle = css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1em 0;
`;

export const underlinedButtonStyle = (theme: BirdiTheme) => css`
  font-size: 0.8em;
  margin-top: 0.5em;
  color: ${theme.deepOcean};
  text-decoration: underline;
`;

import React from 'react';
import { css } from '@emotion/react';

export interface Props {
  color?: string;
  size?: string;
}

const offset = 187;
const duration = '0.8s';

// TODO: Customisable colours
const styles = (color) => css`
.spinner {
  animation: rotator ${duration} linear infinite;
}
@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}
.path {
  stroke-dasharray: ${offset};
  stroke-dashoffset: 0;
  stroke: ${color};
  transform-origin: center;
  animation:
    dash ${duration} ease-in-out infinite;
}

@keyframes dash {
 0% { stroke-dashoffset: ${offset}; }
 50% {
   stroke-dashoffset: ${offset / 4};
   transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: ${offset};
   transform:rotate(450deg);
 }
}
`;

export const LoadingSpinner = ({
  color = '#FFFFFF',
  size = '1em',
}: Props): React.ReactElement => (
  <svg
    css={styles(color)}
    width={size}
    height={size}
    viewBox="0 0 66 66"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      className="path"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
      cx="33"
      cy="33"
      r="30"
    />
  </svg>
);

import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  errorMessage, textInput, blockLabel, reactSelect, nextButtonHolder,
} from '@birdi/theme/form';
import { formStyles } from '@birdi/theme/blocks';
import { LocationSelect, blankLocation } from '@birdi/google-places-autocomplete';
import AboutOrgSVG from '@birdi/icons/about-org.svg';
import {
  stroot, organisation as organisationAPI,
  user as userAPI,
} from '@birdi/js-sdk';
import { css } from '@emotion/react';
import { withRouter } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import {
  BirdiTheme, buttonBlue, buttonGrey,
} from '@birdi/theme';
import { useMetaSelector, updateMeta } from '../meta/reducer';
import * as userActions from '../account/actions';
import * as onboardingStyles from './styles';
import { StepsDots } from './StepsDots';
import Heading from './Heading';
import { validatePhoneNumber } from './utils';

const Step2AboutOrganisation = (props): React.ReactElement => {
  const {
    account, updateStateAndPatch,
    history, getProfile,
  } = props;

  const meta = useMetaSelector();
  const dispatch = useDispatch();

  const {
    control, formState, clearErrors, handleSubmit,
  } = useForm();

  const [locationLoaded, setLocationLoaded] = useState(false);
  const [locationObject, setLocationObject] = useState(blankLocation);

  useEffect(() => {
    getProfile();
    userAPI.getLocation(stroot('pekaa1'), account.id)
      .then((res) => setLocationObject(res.body))
      .then(() => setLocationLoaded(true));
  }, []);

  const handleLocationSelect = (geocode) => {
    userAPI.updateLocation(stroot('ooleo5'), geocode.location)
      .then((res) => {
        setLocationObject(geocode.location);
      });
  };

  const back = () => {
    history.goBack();
  };

  const next = () => {
    history.push('/onboarding/thank-you');
  };

  if (!account.loaded) return <div css={onboardingStyles.screenContainer} />;

  return (
    <div css={onboardingStyles.screenContainer}>
      <div css={onboardingStyles.insideContainer}>
        <div css={css`display: flex; flex-direction: row; align-items: center`}>
          <AboutOrgSVG css={css`font-size: 4rem; margin-right: 0.25em;`} />
          <div css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            span { display: block }
            `}
          >
            <Heading title="About your organisation" />
            <StepsDots step={2} />
          </div>
        </div>
        <div css={css`p { margin: 0.25em 0 }`}>
          <p>Add your company details to get started.</p>
          <p>You can update this information anytime in your account settings.</p>
        </div>
        <div css={onboardingStyles.breakLine} />
        <form
          css={(theme: BirdiTheme) => css(formStyles(theme))}
          onSubmit={handleSubmit(next)}
        >
          <label css={blockLabel} htmlFor="orgName">
            Organisation name
            <Controller
              render={({
                field: { onChange },
              }) => (
                <input
                  css={textInput}
                  type="text"
                  id="orgName"
                  placeholder="e.g. NASA"
                  disabled={!meta.permissions.includes('org:manage-org')}
                  defaultValue={meta.organisation ? meta.organisation.name : ''}
                  onBlur={(event) => {
                    organisationAPI.update(stroot('orgu4u'), {
                      name: event.target.value,
                    }).then(() => {
                      // TODO: Specific update organisation patch action
                      dispatch(updateMeta({
                        organisation: {
                          ...meta.organisation,
                          name: event.target.value,
                        },
                      }));
                    });
                  }}
                  onChange={(event) => {
                    onChange(event.target.value);
                    clearErrors('orgName');
                  }}
                />
              )}
              control={control}
              name="orgName"
              rules={{ required: true }}
              defaultValue={meta.organisation ? meta.organisation.name : ''}
            />
            {formState.errors.orgName && (
              <span css={errorMessage}>An organisation name is required.</span>
            )}
          </label>
          <label css={blockLabel} htmlFor="phone">
            Mobile number (optional)
            <Controller
              render={({
                field: { onChange },
              }) => (
                <input
                  css={textInput}
                  type="text"
                  id="phone"
                  onChange={(event) => {
                    onChange(event.target.value);
                    updateStateAndPatch({ phone: event.target.value });
                  }}
                  defaultValue={account.phone}
                />
              )}
              control={control}
              name="phone"
              rules={{
                validate: (value: string) => (
                  (!value) || (validatePhoneNumber(value))
                ),
              }}
              defaultValue={account.phone}
            />
            {formState.errors.phone && (
              <span css={errorMessage}>Please provide a valid mobile number.</span>
            )}
          </label>
          {locationLoaded && (
            <label css={blockLabel} htmlFor="location">
              Location
              <Controller
                render={({
                  field: { onChange },
                }) => (
                  <LocationSelect
                    inputId="location"
                    defaultValue={locationObject.name}
                    onLocationSelect={(geocode) => {
                      onChange(geocode.location);
                      handleLocationSelect(geocode);
                    }}
                    placeholder="Start to type to select a location"
                    styles={reactSelect}
                  />
                )}
                control={control}
                name="location"
                rules={{ required: true }} // validate: () => checkSuburbValidation(location)
                defaultValue={locationObject.name}
              />
              {formState.errors.location && (
                <span css={css`${errorMessage}; margin-top: 0.5em`}>
                  Please provide your location.
                </span>
              )}
            </label>
          )}
          <div css={nextButtonHolder}>
            <button
              type="button"
              css={(theme: BirdiTheme) => css`
                ${buttonGrey(theme)}
                height: 2.8rem;
                width: 7rem;
              `}
              onClick={back}
            >
              <span>Back</span>
            </button>
            <button
              type="submit"
              css={(theme: BirdiTheme) => css`
                ${buttonBlue(theme)}
                height: 2.8rem;
                width: 7rem;
              `}
            >
              <span>Next</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect((state) => ({
  account: state.account,
}), (dispatch) => ({
  getProfile: () => dispatch(userActions.getProfile()),
  updateStateAndPatch: (field) => dispatch(userActions.updateStateAndPatch(field)),
  updateOrganisation: (field) => dispatch(userActions.updateOrganisation(field)),
}))(withRouter(Step2AboutOrganisation));

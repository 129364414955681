/* global window */

import queryString from 'querystring';

export function marshallToSchema(obj, schema) {
  return Object.keys(obj).reduce((acc, queryKey) => {
    // Skip items not in schema
    if (typeof schema[queryKey] !== 'object') return acc;
    // Handle arrays
    if (schema[queryKey].type === Array) {
      acc[queryKey] = obj[queryKey];
      return acc;
    }
    // Handle obj with schema attachment incl. explict false booleans
    if (obj[queryKey] || obj[queryKey] === false) {
      acc[queryKey] = schema[queryKey].type(obj[queryKey]);
    }
    return acc;
  }, {});
}

function getSearchQuery(parametersSchema) {
  const query = window.location.search.slice(1); // Remove initial '?'
  const queryObj = queryString.parse(query);
  const searchQuery = marshallToSchema(queryObj, parametersSchema);
  return searchQuery;
}

export function getInitialSearchParams(parametersSchema) {
  const defaults = Object.keys(parametersSchema).reduce((acc, key) => {
    acc[key] = parametersSchema[key].default;
    return acc;
  }, {});
  const searchQuery = getSearchQuery(parametersSchema);
  return {
    ...defaults,
    ...searchQuery,
  };
}

/* global document, fetch */

import jwtDecode from 'jwt-decode';
import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag,
} from '../helpers';

export interface ResetPasswordParams {
  id: string;
  code: string;
  password: string;
}

export const resetPassword = (st: SpanTag, params: ResetPasswordParams): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/user/password`;
  return fetchAPI(stleaf(st, 'engoh7'), url, {
    method: 'PUT',
    headers,
    body: JSON.stringify(params),
    credentials: 'include',
  });
};

export const sendPasswordResetCodeToEmail = (st: SpanTag, email: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/user/password/code`;
  return fetchAPI(stleaf(st, 'kiegh0'), url, {
    method: 'POST',
    headers,
    body: JSON.stringify({ email }),
  });
};

export function setCookieFromJWT(encodedJWT: string) {
  try {
    // TODO: Do something with decoded JWT
    // (Validate date - or even better use refresh tokens)
    const decodedJwt = jwtDecode(encodedJWT);
    // 30 days
    const expDate = (new Date(Date.now() + 30 * 24 * 60 * 1000)).toString();
    let secure = '';
    let domain = '';
    if (process.env.NODE_ENV !== 'development') secure = '; secure';
    if (process.env.NODE_ENV !== 'development') domain = `; domain=${process.env.COOKIE_DOMAIN}`;
    const cookieString = `${config.JWT_NAME}=${encodedJWT}; expires=${expDate}; path=/${secure}${domain}`;
    document.cookie = cookieString;
  } catch (error) {
    console.log('Error decoding or setting JWT', error);
  }
}

export const signInWithPassword = (st: SpanTag, obj): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/auth`;
  return fetchAPI(stleaf(st, 'ol7uth'), url, {
    headers,
    method: 'POST',
    body: JSON.stringify(obj),
    credentials: 'include',
  });
};

interface MaskProps {
  roleId: string;
  organisationId: string;
  missionRoleOverride?: string;
}

export const mask = (st: SpanTag, maskProps: MaskProps): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/auth/mask`;
  return fetchAPI(stleaf(st, 'olkuth'), url, {
    headers,
    method: 'POST',
    body: JSON.stringify(maskProps),
    credentials: 'include',
  });
};

export const unmask = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/auth/mask`;
  return fetchAPI(stleaf(st, 'olmuth'), url, {
    headers,
    method: 'DELETE',
    credentials: 'include',
  });
};

export const switchContext = (st: SpanTag, obj): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/auth/context`;
  return fetchAPI(stleaf(st, 'oangi9'), url, {
    headers,
    method: 'POST',
    body: JSON.stringify(obj),
    credentials: 'include',
  });
};

export const signInWithGoogleIdToken = (st: SpanTag, code: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/auth/google`;
  return fetchAPI(stleaf(st, 'eep3ee'), url, {
    headers,
    method: 'POST',
    body: JSON.stringify({ code }),
    credentials: 'include',
  });
};

// Client-side only cookie sharing
export function clearCookie(st, refresh = true) {
  const url = `${config.API_URL}/v2/auth/sign-out`;
  return fetchAPI(stleaf(st, 'avzi5u'), url, {
    headers,
    method: 'GET',
    credentials: 'include',
  }).then((res) => {
    console.log('Cookie cleared');
    if (refresh) {
      location.reload();
    }
  });
}

// Generate and resend verification token
export const getVerificationToken = (st: SpanTag, email: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/email-verification/`;
  return fetchAPI(stleaf(st, 'avai5u'), url, {
    headers,
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ email }),
  });
};

export const verifyEmailWithToken = (st: SpanTag, userId: string, token: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/email-verification/user/${userId}`;
  return fetchAPI(stleaf(st, 'jefo7k'), url, {
    headers,
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify({ token }),
  });
};

export const getCredentials = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/pilots/${id}/credentials`;
  return fetchAPI(stleaf(st, 'tohm9o'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

import { stroot, credentials } from '@birdi/js-sdk';
import React, { useState, useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import AsyncCreatable from 'react-select/async-creatable';
import { formStyles } from '@birdi/theme/blocks';
import mem from 'mem';
import * as Dialog from '@radix-ui/react-dialog';
import CloseSVG from '@birdi/icons/close.svg';
import PlusSVG from '@birdi/icons/plus.svg';
import CredentialSVG from '@birdi/icons/credential.svg';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import { buttonBlue, buttonOutlineBlue, ThemeOverride } from '@birdi/theme';
import { Formik, Form, Field } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { parseISO } from 'date-fns';
import UploadButton from './UploadButton';
import './datePickerStyles.css';
import {
  errorMessage, textInput, blockLabel, textArea, reactSelect,
} from '@birdi/theme/form';

const initialValues = {
  number: '',
  uploadTitle: '',
  expiryDate: '',
};

const memFetchCredentialTypes = mem(() => credentials.getTypes(stroot('ur0ooh')), {
  maxAge: 1000 * 60 * 60, // 1 hour
});

const CredentialTypeSelect = ({ onChange, credential, edit }) => {
  console.log('credential: ', credential);
  return (
    <AsyncCreatable
      isClearable
      placeholder="Select or start to type to add a new credential type ..."
      defaultOptions
      maxMenuHeight={140}
      loadOptions={(inputValue) =>
        memFetchCredentialTypes()
          .then((res) => {
            const types = res.body.map((type) => ({
              label: type.acronym ? `${type.acronym} (${type.credential})` : type.credential,
              value: type.id,
              expiry: type.expiry,
              referenceNo: type.referenceNo,
            }));
            console.log('types: ', types);
            return types.filter((type) => type.label.toLowerCase()
              .includes(inputValue.toLowerCase()));
          })}
      onChange={(option, action) => onChange(option, action)}
      defaultValue={credential ? { value: credential, label: credential } : null}
      isDisabled={edit || false}
      styles={reactSelect}
    />
  )
};

export default ({
  open, setOpen, addCredential, item,
  editCredential, credentialsList,
}) => {
  // TODO: Amalgamate these into 'schema' object
  const [expiryStatus, setExpiryStatus] = useState('hidden');
  const [refNoStatus, setRefNoStatus] = useState('hidden');

  useEffect(() => {
    // to get expiry status while editing a credential
    if (item) {
      console.log('test', item);
      setExpiryStatus(item.expiry);
      setRefNoStatus(item.referenceNo);
    }
  }, [item]);

  const validate = (values) => {
    const errors = {};
    if (!values.credentialTypeId && !values.customType) errors.credentialType = 'Credential type is required';
    if (!item) {
      credentialsList.items.forEach((prevCredential) => {
        if (values.credentialTypeId && values.credentialTypeId === prevCredential.credentialTypeId) {
          errors.duplicatedType = 'You already have this type of credential';
        }
      });
    }
    if (expiryStatus === 'required' && !values.expiryDate) {
      errors.expiryDate = 'Expiry date is required';
    }
    if (refNoStatus === 'required' && !values.number) {
      errors.number = 'Reference number is required';
    }
    return errors;
  };

  const fileInputEl = useRef();
  const defaultValue = item || initialValues;

  console.log('defaultValue: ', defaultValue);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <button
          type="button"
          css={(theme) => css`${buttonBlue(theme)}; margin-left: 1.5rem; display: flex; align-items: center;`}
        >
          <span>Add Credential</span>
          <PlusSVG css={css`margin-left: 2em; stroke-width: 2px;`} />
        </button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <Dialog.Overlay />
        <div css={portalCSS}>
          <ThemeOverride>
            <Dialog.Content css={(theme) => css`${contentCSS(theme)}; padding: 1em 2em`}>

              <Dialog.Close css={closeButtonCSS}><CloseSVG /></Dialog.Close>

              <div css={(theme) => css`
                display: flex;
                flex-direction: column;
                align-items: center;
                border-bottom: solid 1px ${theme.mono30};
                margin-bottom: 1em;
                `}
              >
                <CredentialSVG css={css`display: block; font-size: 7em`} />
                <Dialog.Title css={css`text-align: center`}>
                  {item ? 'Edit Credentials' : 'Add your credentials'}
                </Dialog.Title>
              </div>

              <Formik
                initialValues={defaultValue}
                onSubmit={item ? editCredential : addCredential}
                validate={validate}
              >
                {({
                  handleSubmit, setFieldValue, submitForm, values, errors, touched,
                }) => (
                  <Form
                    css={(theme) => css(formStyles(theme))}
                    onSubmit={handleSubmit}
                  >
                    <label htmlFor="type" css={blockLabel}>
                      Credential type
                      <CredentialTypeSelect
                        onChange={(option, action) => {
                          setFieldValue('expiryDate', '');
                          if (action.action === 'create-option') {
                            setExpiryStatus('optional');
                            setRefNoStatus('optional');
                            setFieldValue('credentialTypeId', undefined);
                            setFieldValue('customType', option.value);
                          } else if (action.action === 'clear') {
                            setExpiryStatus('hidden');
                            setRefNoStatus('hidden');
                            setFieldValue('credentialTypeId', undefined);
                            setFieldValue('customType', undefined);
                          } else {
                            setExpiryStatus(option.expiry);
                            setRefNoStatus(option.referenceNo);
                            setFieldValue('credentialTypeId', option.value);
                          }
                        }}
                        edit={!!item}
                        credential={item ? (item.credential || item.customType) : null}
                      />
                    </label>
                    {errors.credentialType && touched.credentialTypeId && (
                      <div css={errorMessage}>{errors.credentialType}</div>
                    )}
                    {errors.duplicatedType && touched.credentialTypeId && (
                      <div css={errorMessage}>{errors.duplicatedType}</div>
                    )}

                    {refNoStatus !== 'hidden' && (
                      <label htmlFor="number" css={blockLabel}>
                        Reference no.
                        {refNoStatus === 'optional' && (<span>&nbsp;(optional)</span>)}
                        <Field
                          css={textInput}
                          type="text"
                          name="number"
                          laceholder="Add reference number ..."
                        />
                      </label>
                    )}
                    {errors.number && touched.number && (
                      <div css={errorMessage}>{errors.number}</div>
                    )}

                    {expiryStatus !== 'hidden' && (
                      <div css={css`width: 100%;`}>
                        <label htmlFor="expiryDate" css={blockLabel}>
                          Expiry date
                          {expiryStatus === 'optional' && (<span>&nbsp;(optional)</span>)}
                        </label>
                        <DatePicker
                          selected={(function () {
                            if (!values.expiryDate) return '';
                            if (typeof values.expiryDate === 'string') {
                              return parseISO(values.expiryDate);
                            }
                            return values.expiryDate;
                          }())}
                          onChange={(date) => setFieldValue('expiryDate', date)}
                        />
                      </div>
                    )}
                    {errors.expiryDate && touched.expiryDate && (
                      <div css={errorMessage}>{errors.expiryDate}</div>
                    )}

                    <label htmlFor="file" css={blockLabel}>
                      Associated document
                      <div>
                        <input
                          type="file"
                          id="file"
                          onChange={(event) => {
                            setFieldValue('upload', event.currentTarget.files[0]);
                            setFieldValue('uploadHasChanged', true);
                          }}
                          ref={fileInputEl}
                          style={{ display: 'none' }}
                        />
                        <UploadButton
                          fileInputEl={fileInputEl}
                          file={(values.upload && values.upload.name)
                            ? values.upload.name : values.uploadTitle}
                          clearField={() => {
                            setFieldValue('upload', '');
                            setFieldValue('uploadTitle', '');
                            setFieldValue('uploadHasChanged', true);
                          }}
                          label="Upload certificate"
                        />
                      </div>
                    </label>

                    <div css={css`
                      display: flex;
                      flext-direction: row;
                      align-items: center;
                      justify-content: center;
                    `}
                    >
                      <Dialog.Close>
                        <button type="button" css={(theme) => css`${buttonOutlineBlue(theme)}; width: 7rem;`}>
                          Cancel
                        </button>
                      </Dialog.Close>
                      <button type="button" onClick={submitForm} css={(theme) => css`${buttonBlue(theme)}; width: 7rem;`}>
                        Confirm
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Dialog.Content>
          </ThemeOverride>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

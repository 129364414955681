import React, { Component } from 'react';
import { auth } from '@birdi/js-sdk';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import { css } from '@emotion/react';
import { BirdiTheme } from '@birdi/theme';
import * as authActions from './actions';

class VerificationReceived extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: false,
    };
  }
  componentDidMount() {
    const { match, verifyEmailWithTokenAndSignIn } = this.props;
    // TODO: Get user id & token from query
    const searchParams = new URLSearchParams(window.location.search);
    const userId = searchParams.get('id');
    const token = searchParams.get('token');
    if (!userId || !token) {
      this.setState({ invalid: true });
    } else {
      verifyEmailWithTokenAndSignIn(userId, token)
        .catch(() => this.setState({ invalid: true }));
    }
  }
  render() {
    return (
      <div>
        <div css={css`text-align: center; margin-bottom: 1rem;`}>
          <BirdiLogoSVG css={css`width: 7em; height: 5em;`} />
        </div>
        {this.state.invalid && (
          <span>
            This token is invalid or expired.&nbsp;
            <Link to="/resend-verification" css={(theme: BirdiTheme) => css`color: ${theme.deepOcean}`}>
              Resend verification token.
            </Link>
          </span>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  (dispatch) => ({
    verifyEmailWithTokenAndSignIn: (userId, verificationToken) =>
      dispatch(authActions.verifyEmailWithTokenAndSignIn(userId, verificationToken)),
  }),
)(withRouter(VerificationReceived));

/* globals window, CSS, requestAnimationFrame */

import React, { useRef, useState } from 'react';
import { css, useTheme } from '@emotion/react';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import HelloSVG from '@birdi/icons/illustration-hello.svg';
import { useMetaSelector } from '../meta/reducer';
import ActionDropDown from './action-dropdown';
import { AssetAssessmentModal } from './asset-assessment-modal';

export const GetStarted: React.FC = () => {
  const meta = useMetaSelector();
  const [assessmentModal, setAssessmentModal] = useState<boolean>(false);

  const openAssessmentModal = () => {
    setAssessmentModal(true);
  };

  return (
    <div>
      <div css={(theme) => css`
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 1em;
        @media (min-width: ${breakpoints.xs}) {
          border-bottom: solid 2px ${theme.mono20};
          padding-bottom: 1em;
        }
      `}
      >
        <HelloSVG css={css`font-size: 2.5em; margin-right: 0.25em`} />
        <h2 css={css`margin: 0`}>
          {`Hello ${meta.fname},`}
        </h2>
      </div>
      <div css={(theme: BirdiTheme) => css`
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1em;
        padding: 0.1em 1em;
        background-color: ${theme.lightOcean};
        border-radius: 0.5rem;
      `}
      >
        <h3>What would you like to do today?</h3>
        <ActionDropDown
          openAssessmentModal={openAssessmentModal}
        />
      </div>
      {assessmentModal && (
        <AssetAssessmentModal
          modalOpen={assessmentModal}
          setModalOpen={setAssessmentModal}
        />
      )}
    </div>
  );
};

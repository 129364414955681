import React from 'react';
import { css } from '@emotion/react';
import {
  stroot, onboarding as onboardingAPI,
} from '@birdi/js-sdk';
import ThankYouSVG from '@birdi/icons/thank-you.svg';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import { useHistory } from 'react-router-dom';
import * as onboardingStyles from './styles';
import Heading from './Heading';
import { useMetaSelector } from '../meta/reducer';

const Step3ThankYou = (): React.ReactElement => {
  const meta = useMetaSelector();
  const history = useHistory();
  const start = () => {
    onboardingAPI.completeOnboarding(stroot('quie8g'));
    // Don't get caught in a loop here
    // TODO: Update meta state so that even if we're in onboarding it will kick you out
    // To avoid this complicated logic
    const redirectToOnboarding = meta.redirect?.includes('onboarding');
    let redirect = '/';
    // Testing if there's a valid redirect
    if (meta.redirect && !redirectToOnboarding) {
      redirect = meta.redirect;
    }
    history.push(redirect);
  };

  return (
    <div css={onboardingStyles.screenContainer}>
      <div css={onboardingStyles.insideContainer}>
        <div css={css`
          padding: 3em 2em 5em 2em;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `}
        >
          <ThankYouSVG css={css`font-size: 8rem`} />
          <Heading title="Thank You!" style={css`margin-top: 1em`} />
          <p css={css`margin: 2em 0`}>
            Your account was created successfully.
          </p>
          <button
            type="button"
            css={(theme: BirdiTheme) => css`
              ${buttonBlue(theme)};
              padding: 0.75em 5em;
            `}
            onClick={start}
          >
            <span>Get Started</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Step3ThankYou;

import { ContextMenu2 } from '@birdi/context-menu/context-menu-2';
import BirdiIcon, { Icons } from '@birdi/icons';
import { handle_global_error } from '@birdi/js-sdk';
import { buttonOutlined, contextMenuStyle } from '@birdi/theme/blocks';
import { omit, pick } from 'lodash-es';
import { observer } from 'mobx-react';
import React, { useContext } from 'react';
import toast from 'react-hot-toast';
import { downloadContent, objectToCsv } from '../common/utils';
import { ProcessingContext } from './ProcessingState';

export const DownloadButton = observer(() => {
  const processingState = useContext(ProcessingContext);
  const { someItemsSelected } = processingState;

  const downloadData = async (type: 'all' | 'selected') => {
    const headers = [
      { label: 'Map name', key: 'missionName' },
      { label: 'Processing name', key: 'name' },
      { label: 'Processing date', key: 'processingDate' },
      { label: 'GP Count', key: 'gpCount' },
      { label: 'GCP Requested', key: 'gcpIncluded' },
    ];
    const pickProperties = headers.map((header) => header.key);
    let data = [];
    const csvToast = toast.loading('Generating csv');
    if (type === 'selected') {
      data = processingState.items
        .filter((item) => item.selected)
        .map((item) => pick(item, pickProperties));
    } else if (type === 'all') {
      const filters = omit(processingState.filters, ['start', 'limit']);
      const { items } = (await processingState.fetchProcessingJobs(filters)).body;
      data = processingState.formatProcessingJobs(items);
    }
    if (data.length > 0) {
      try {
        const result = await objectToCsv(headers, data);
        downloadContent('processing_jobs.csv', result, 'text/csv');
      } catch (e) {
        handle_global_error('global.error', e);
        toast.error('An error occured while generating the csv');
      } finally {
        toast.dismiss(csvToast);
      }
    }
  };

  return (
    <ContextMenu2<HTMLButtonElement, HTMLDivElement>
      Menu={({ hide, wrapperRef }) => (
        <div
          ref={wrapperRef}
          css={contextMenuStyle}
        >
          <button
            type="button"
            onClick={async () => {
              hide();
              await downloadData('all');
            }}
          >
            Download all
          </button>
          <button
            type="button"
            disabled={!someItemsSelected}
            onClick={async () => {
              hide();
              await downloadData('selected');
            }}
          >
            Download selected
          </button>
        </div>
      )}
      Button={(props) => (
        <button
          type="button"
          onClick={props.toggle}
          ref={props.toggleRef}
          css={buttonOutlined}
        >
          Download&nbsp;
          <BirdiIcon icon={Icons.Chevron} rotate="180" size="sm" />
        </button>
      )}
    />
  );
});

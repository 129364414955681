import { LoadingSpinner } from '@birdi/loading';
import { Pagination } from '@birdi/pagination';
import { blockLabel, linkPrimary } from '@birdi/theme/blocks';
import { css, Theme } from '@emotion/react';
import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import React, { useContext, useEffect } from 'react';
import { BirdiTheme, fonts } from '@birdi/theme';
import { Link } from 'react-router-dom';
import { ProcessingContext } from './ProcessingState';
import { useMetaSelector } from '../meta/reducer';

const tableStyles = (theme: BirdiTheme) => css`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  thead tr {
    border-bottom: 1px solid ${theme.dove}
  }
  th, td {
    text-align: left;
    padding: 1rem 0.75rem;
    font-family: ${fonts.display};
  }
`;

type Column = {
  title: string,
  property: string,
  component: (data: { [key: string]: any }, prop: string) => JSX.Element,
};

export const ProcessingTable = observer(() => {
  const processingState = useContext(ProcessingContext);
  const meta = useMetaSelector();
  const {
    items, totalCount,
  } = processingState;
  const columns: Column[] = [
    {
      title: '',
      property: 'selected',
      component: (data, prop) => (
        <td key={prop}>
          <input
            type="checkbox"
            id={`${data.id as string}`}
            css={css`margin-left: 1em;`}
            checked={data[prop] as boolean}
            onChange={(e) => processingState.updateTableItem(
              e.target.id, { selected: e.target.checked },
            )}
          />
        </td>
      ),
    },
    {
      title: 'Map name',
      property: 'missionName',
      component: (data, prop) => (
        <td key={prop}>
          <Link css={linkPrimary} to={`map/missions/${data.missionId}`}>{ data[prop] }</Link>
        </td>
      ),
    },
    {
      title: 'Objective | Processing name & ID',
      property: 'name',
      component: (data, prop) => <td key={prop}>{data[prop]}</td>,
    },
    {
      title: 'Processing Date & Time',
      property: 'processingDate',
      component: (data, prop) => <td key={prop}>{data[prop]}</td>,
    },
    {
      title: 'GP Count',
      property: 'gpCount',
      component: (data, prop) => <td key={prop}>{data[prop]}</td>,
    }
  ];

  if (meta.organisation.plan === 'pro') {
    columns.push({
      title: 'GCP Requested',
      property: 'gcpIncluded',
      component: (data, prop) => <td key={prop}>{data[prop]}</td>,
    });
  } else {
    columns.push({
      title: 'Status',
      property: 'publishableState',
      component: (data, prop) => <td css={css`text-transform: capitalize`}key={prop}>{data[prop]}</td>,
    })
  }

  if (processingState.fetching) {
    return (
      <div css={css`text-align: center`}>
        <LoadingSpinner color="black" />
      </div>
    );
  }

  return (
    <>
      <div css={css`margin-bottom: 0.5rem;`}>
        <label css={blockLabel} htmlFor="select-all">
          <input
            type="checkbox"
            name="select-all"
            checked={processingState.areAllItemsSelected}
            onChange={() => processingState.toggleTableItemsSelection()}
            css={css`margin-right: 0.5em;`}
          />
          <span>Select all</span>
        </label>
      </div>
      <table css={tableStyles}>
        <thead>
          <tr>
            {columns.map((col) => (<th key={col.property}>{col.title}</th>))}
          </tr>
        </thead>
        <tbody>
          {items.map((d) => (
            <tr key={d.id}>
              {columns.map((col) => col.component(d, col.property))}
            </tr>
          ))}
        </tbody>
      </table>
      {totalCount > 0 && (
        <section css={() => css`padding: 1em 0; flex-grow: 1;`}>
          <Pagination
            onChange={(newPage: number) => processingState.updateFilters({ start: newPage })}
            itemCount={totalCount}
            itemsPerPage={processingState.filters.limit}
            currentPage={processingState.filters.start}
          />
        </section>
      )}
    </>
  );
});

/* global fetch */

import querystring from 'querystring';
import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag
} from '../helpers';

export interface EquipmentType {
  id: string;
  batteryCount: number;
  details: any;
  model: {
    id: string;
    type: string;
    model: string;
    manufacturer: string;
    attributes: string;
  }
  modelId: string;
  name: string;
  purchaseDate: string;
  serialNo: string;
  userScopeId: string;
}

export const getTypes = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/equipment/types`;
  return fetchAPI(stleaf(st, 'ohd7us'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const enum EquipmentAssociation {
  Mine = 'mine',
  Organisation = 'organisation',
}

interface Opts {
  association: EquipmentAssociation;
  limit: number;
  // the rest?
}
export const list = (st: SpanTag, opts?: Partial<Opts>): Promise<APIResponse> => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  const url = new URL(`${config.API_URL}/v1/equipment${query}`);
  return fetchAPI(stleaf(st, 'gahse7'), url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getId = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/equipment/${id}`;
  return fetchAPI(stleaf(st, 'oos1le'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getSerial = (st: SpanTag, serialNo: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/equipment/serial/${serialNo}`;
  return fetchAPI(stleaf(st, 'ugah3u'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export interface EquipmentParams {
  modelId: string;
  name: string;
  purchaseDate: Date;
  serialNo: string;
  type: string;
  batteryCount: string;
  userScopeId: string;
}
export const add = (st: SpanTag, params: EquipmentParams): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/equipment`;
  return fetchAPI(stleaf(st, 'thahr6'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params),
  });
};

interface PropsOpts {
  details: any;
  model: string;
  name: string;
  purchaseDate: Date;
  serialNo: string;
  type: string;
  userScopeId: string;
}
export const update = (st: SpanTag, id: string, props: PropsOpts): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/equipment/${id}`;
  return fetchAPI(stleaf(st, 'aing6k'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(props),
  });
};

export const deleteEquipment = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/equipment/${id}`;
  return fetchAPI(stleaf(st, 'uxo0mu'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export const getTags = (st: SpanTag, type: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/equipment-tags/${type}`;
  return fetchAPI(stleaf(st, 'aeb8xi'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

import { stroot } from '@birdi/js-sdk'
import { organisationUsers } from '@birdi/js-sdk/src/public';

export const FETCH_REQUESTED = 'users/FETCH_REQUESTED';
export const FETCH_SUCCESS = 'users/FETCH_SUCCESS';
export const FETCH_ERROR = 'users/FETCH_ERROR';
export const RESET = 'users/RESET';
export const UPDATE_ROLE = 'users/UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'users/UPDATE_ROLE_SUCCESS';
export const DELETE = 'users/DELETE';
export const DELETE_SUCCESS = 'users/DELETE_SUCCESS';

const initialState = {
  loading: false,
  items: [],
  sort: 'name',
  itemCount: 0,
  itemsPerPage: 25,
};

export const reset = () => ({ type: RESET });

export const getUsers = () => (dispatch) =>
  organisationUsers.getUsers(stroot('iteih4'))
    .then((res) => dispatch({ type: FETCH_SUCCESS, payload: res.body }));

export const updateRole = (id, roleId) => (dispatch) =>
  organisationUsers.changeRole(stroot('ahg1ee'), id, roleId)
    .then((res) => dispatch({ type: UPDATE_ROLE_SUCCESS, payload: res.body }));

export const remove = (id) => (dispatch) =>
  organisationUsers.deleteUser(stroot('aub4ua'), id)
    .then(() => dispatch({ type: DELETE_SUCCESS, payload: id }));

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        items: state.items.map(item =>
          item.id === payload.userId
            ? {...item, roleName: payload.roleName, roleId: payload.roleId}
            : item
        )
      };
    case DELETE_SUCCESS:
      const items = state.items.filter((i) => (i.id !== payload));
      return {
        ...state,
        items,
      };
    case FETCH_REQUESTED:
      return { ...state, loading: true };
    case FETCH_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case RESET:
      return { ...initialState };
    default:
      return state;
  }
};

/* globals window, fetch */

import { config } from '../config';
import {
  headers, SpanTag, stleaf, fetchAPIJSON,
} from '../helpers';
import { setCookieFromJWT } from '../public/auth';

const create = (st: SpanTag, opts = {}) => {
  const url = `${config.API_URL}/v1/dev/user/`;
  return fetchAPIJSON(stleaf(st, 'aem3xa'), url,
    {
      method: 'POST',
      headers,
      body: JSON.stringify(opts),
    });
};

export const login = (st: SpanTag, opts) => create(st, opts)
  .then((res) => {
    setCookieFromJWT(res.body.token);
    window.location.reload();
  });

/* global fetch */

import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag
} from '../helpers';
import querystring from 'querystring';

export const getList = (st: SpanTag, opts): Promise<APIResponse> => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  return fetchAPI(stleaf(st, 'to9gai'), `${config.API_URL}/v1/assets${query}`, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

// We need missionId here so QA users cannot fuck with other missions
export const bulkAssignMedia = (st: SpanTag, assetId: string, {
  mediaIds, missionId, }: { mediaIds: Array<string>, missionId: string }): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/assets/bulk-media`;
  return fetchAPI(stleaf(st, 'nah7sa'),  url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify({ mediaIds, assetId, missionId }),
  });
};

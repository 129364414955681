import querystring from 'querystring';
import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag
} from '../helpers';

export interface Site {
  id: string;
  name: string;
  // organisationId: string;
  location: any;
  createdAt: string;
}

export interface SiteParams {
  name: string;
  description?: string;
  location: any;
}

export const createSite = (st: SpanTag, params: SiteParams): Promise<APIResponse<Site>> => {
  const url = `${config.API_URL}/v2/site`;
  return fetchAPI(stleaf(st, 'epohs8'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(params)
  })
}

export const deleteSite = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/site/${id}`;
  return fetchAPI(stleaf(st, 'epohs8'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  })
}

interface SearchSitesParams extends Record<string, any> {
  start?: number;
  limit?: number;
  searchTerm?: string;
  coordinates?: Array<number>;
}

// search sites in organisation
export const searchSites = (st: SpanTag, params: SearchSitesParams = {}): Promise<APIResponse<{ sites: Site[] }>> => {
  const url = new URL(`${config.API_URL}/v2/site/`);
  const searchParams = new URLSearchParams(params);
  url.search = searchParams.toString();
  return fetchAPI(stleaf(st, 'iey0ie'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  })
}

export const getSiteInfo = (st: SpanTag, id: string): Promise<APIResponse<Site>> => {
  const url = new URL(`${config.API_URL}/v2/site/${id}`);
  return fetchAPI(stleaf(st, 'aefot6'), url, {
    method: 'GET',
    credentials: 'include',
    headers
  })
}

export const getMediaBySiteId = (st: SpanTag, siteId: string, params: { baseType: string }): Promise<APIResponse<{ items: any[] }>> => {
  const url = new URL(`${config.API_URL}/v2/site/${siteId}/media`);
  const searchParams = new URLSearchParams(params);
  url.search = searchParams.toString();
  return fetchAPI(stleaf(st, 'gmbsi1'), url, {
    method: 'GET',
    credentials: 'include',
    headers
  })
}

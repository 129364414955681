/* global fetch */

import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag
} from '../helpers';

export interface CredentialsType {
  id: string;
  acronym: string;
  credential: string;
  credentialTypeId: string;
  customType: string;
  description: string;
  expiry: string;
  expiryDate: Date;
  number: string;
  referenceNo: string;
  upload: string;
  uploadTitle: string;
  verified: string;
  verifiedAt: string;
  verifiedBy: string;
  verifiedByName: string;
}

export const getTypes = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/credential-types`;
  return fetchAPI(stleaf(st, 'eing8p'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const get = (st: SpanTag): Promise<APIResponse<CredentialsType[]>> => {
  const url = `${config.API_URL}/v1/credentials`;
  return fetchAPI(stleaf(st, 'duiv1p'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

interface CredentialOpts {
  credentialTypeId: string;
  expiryDate: Date;
  number: string;
  uploadTitle: string;
  upload: string;
}

export const add = (st: SpanTag, credential: CredentialOpts): Promise<APIResponse<CredentialsType>> => {
  const url = `${config.API_URL}/v1/credentials`;
  return fetchAPI(stleaf(st, 'ahwah2'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(credential),
  });
};

export const edit = (st: SpanTag, id: string, credential: CredentialOpts): Promise<APIResponse<CredentialsType>> => {
  const url = `${config.API_URL}/v1/credentials/${id}`;
  return fetchAPI(stleaf(st, 'aeng2a'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(credential),
  });
};

export const remove = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/credentials/${id}`;
  return fetchAPI(stleaf(st, 'otef3i'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

import React, { Component } from 'react';
import { css } from '@emotion/react';
import EyeSVG from '@birdi/icons/eye.svg';
import EyeOffSVG from '@birdi/icons/eye-off.svg';

class Password extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  componentWillUnmount() {
    const { setPassword } = this.props;
    setPassword('');
    this.setState({ show: false });
  }
  togglePasswordDisplay = (event) => {
    event.preventDefault();
    this.setState(prevState => ({ show: !prevState.show }));
  }
  render() {
    const {
      name, label, value,
      setPassword, required,
      minLength,
    } = this.props;
    const { show } = this.state;
    return (
      <div>
        <label htmlFor={name}>
          {label}
          <div css={css`position: relative;`}>
            <input
              type={show ? 'text' : 'password'}
              data-cy="password"
              id={name}
              value={value}
              onInput={event => setPassword(event.target.value)}
              required={required}
              minLength={minLength}
            />
            <button
              onClick={this.togglePasswordDisplay}
              type="button"
              css={css`
                position: absolute;
                top: 1.2rem;
                right: 0.5rem;
                z-index: 10;
                cursor: pointer;
              `}
            >
              {show ? <EyeOffSVG /> : <EyeSVG />}
            </button>
          </div>
        </label>
      </div>
    );
  }
}

Password.defaultProps = {
  minLength: '8',
  required: true,
  name: 'password',
  label: 'Password',
};

export default Password;

/* globals window */

import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { BirdiTheme, fonts } from '@birdi/theme';
import { pageDescription } from '@birdi/theme/blocks';
import PlayCircleSVG from '@birdi/icons/play-circle.svg';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import { UserTable } from './UserTable';
import * as userActions from './reducer';
import { InviteUserModal } from './invite-user-modal';
import { SeatsBadge } from './seats-badge';
import { useMetaSelector } from '../meta/reducer';
import { INTERCOM_TOUR_CODES } from '../common/consts';

/**
 * Needs a rewrite
 */
export const Users: React.FC = () => {
  // TODO: Self-contained modal
  const [showInvitationModal, setShowInvitationModal] = useState(false);
  const [showManageSeatsModal, setShowManageSeatsModal] = useState(false);
  const meta = useMetaSelector();
  const users = useSelector((state) => state.users);
  const history = useHistory();
  const dispatch = useDispatch();
  const getUsers = () => dispatch(userActions.getUsers());

  useEffect(() => {
    getUsers();
    function openModalOnSearch() {
      const modalParam = new URLSearchParams(window.location.search).get('modal');
      if (modalParam === 'invite') {
        setShowManageSeatsModal(false);
        setShowInvitationModal(true);
        // Removes query
        window.history.replaceState('users', 'Birdi', '/users');
      }
      if (modalParam === 'seats') {
        setShowInvitationModal(false);
        setShowManageSeatsModal(true);
        window.history.replaceState('users', 'Birdi', '/users');
      }
    }
    openModalOnSearch();
    const deregister = history.listen(openModalOnSearch);
    return () => deregister();
  }, []);
  return (
    <div>
      <h2 css={css`margin: 1em`}>User Management</h2>
      <div css={pageDescription}>
        <p>Manage, update and invite team members to join your organization.</p>
        <div>
          <button
            type="button"
            onClick={() => {
              window?.Intercom && window.Intercom('startTour', INTERCOM_TOUR_CODES.USER_MANAGEMENT);
            }}
          >
            <PlayCircleSVG />
            <span>Take a quick tour</span>
          </button>
          <a
            href="https://help.birdi.io/en/articles/7853457-how-to-invite-a-team-member"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn how to invite a team member</span>
          </a>
          <a
            href="https://help.birdi.io/en/articles/6113439-roles-and-permissions-explained"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn about roles and permissions</span>
          </a>
        </div>
      </div>
      <div css={css`
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.25em 1.5em;
        `}
      >
        <SeatsBadge modalOpen={showManageSeatsModal} setModalOpen={setShowManageSeatsModal} />
        <Link
          to="/users?modal=invite"
          css={css`margin-bottom: 0.5em;`}
        >
          <span css={(theme: BirdiTheme) => css`
            font-family: ${fonts.display};
            font-size: 1rem;
            font-weight: bold;
            cursor: pointer;
            color: ${theme.deepOcean}
          `}
          >
            + Invite a team member
          </span>
        </Link>
      </div>
      {meta.permissions.includes('org:manage-users') && (
        <div>
          <InviteUserModal
            open={showInvitationModal}
            setOpen={setShowInvitationModal}
          />
        </div>
      )}
      <section css={css`padding-bottom: 2em;`}>
        {!users.loading && (
          <UserTable
            users={users.items}
            id={meta.id}
            permissions={meta.permissions}
          />
        )}
      </section>
    </div>
  );
};

import { stroot, subscription as subscriptionAPI } from '@birdi/js-sdk';
import React, { useEffect, useState } from 'react';
import { css, Theme } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { Toggle } from '@birdi/toggle';
import BackSVG from '@birdi/icons/arrow-left.svg';
import { Link } from 'react-router-dom';
import { PriceData } from './types';
import { FreePlanOption } from './free-plan-option';
import { GrowthPlanOption } from './growth-plan-option';
import { PlusPlanOption } from './plus-plan-option';
import { EnterpriseOption } from './enterprise-option';
import { useMetaSelector } from '../meta/reducer';
import { planIntervalType, CurrentPlan } from './types';
import { fonts } from '@birdi/theme';

export const PlanOptions = (): React.ReactElement => {
  const meta = useMetaSelector();
  const [paymentInterval, setPaymentInterval] = useState<planIntervalType>('monthly');
  const [prices, setPrices] = useState<PriceData | null>(null);
  const [currentPlan, setCurrentPlan] = useState<CurrentPlan | null>(null);

  useEffect(() => {
    console.log('meta.organisation: ', meta.organisation);
    // set default plan interval
    if (meta.organisation.planInterval) {
      setPaymentInterval(meta.organisation.planInterval);
    }
    // get plans based on user's country currency
    subscriptionAPI.getPrices(stroot('otii6o'))
      .then((res) => {
        setPrices(res.body);
      })
      .catch((err) => console.log(err));
    // get current plan status
    subscriptionAPI.getCurrentPlan(stroot('phahc4'))
        .then((res) => {
          setCurrentPlan(res.body);
          console.log('res.body: ', res.body);
        })
  }, []);

  if (prices === null) return <div />;

  return (
    <div>
      <div css={(theme: Theme) => css`
        position: relative;
        padding: 1em;
        border-bottom: solid 1px #E8E7F2;
        background: ${theme.lightOcean};
      `}
      >
        <h2 css={css`
          margin: 0;
          text-align: center;
          @media (max-width: ${breakpoints.xs}) {
            margin-top: 2em;
          }
        `}
        >
          Plans for every business size
        </h2>
        <Link
          css={css`
            position: absolute;
            top: 1em;
            left: 1em;
            padding: 0;
            margin-right: auto;
            display: flex;
            align-items: center;
            color: inherit;
            padding: 0 1em;
          `}
          to="/billing/plan"
        >
          <BackSVG
            css={(theme: Theme) => css`
              width: 1em;
              height: 2em;
              color: ${theme.mono70};
              margin-right: 0.25em;
            `}
          />
          <span>Back</span>
        </Link>
      </div>
      <div css={(theme) => css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1em;
        border-bottom: solid 1px #E8E7F2;
        background: ${theme.lightOcean};
      `}
      >
        <p css={(theme) => css`margin: 0; color: ${theme.mono50}`}>
          Perfect for sole traders, teams and mid-large businesses.
        </p>
        <section css={css`
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          margin: 10px 0;
          font-family: ${fonts.display};
          `}
        >
          <span css={css`margin-right: 0.5em;`}>Monthly</span>
          <Toggle
            checked={paymentInterval === 'annual'}
            onChange={() => {
              setPaymentInterval((paymentInterval === 'annual') ? 'monthly' : 'annual');
            }}
          />
          <span css={css`margin-left: 0.5em;`}>Annual</span>
        </section>
        <p css={(theme) => css`margin: 0; color: ${theme.mono50}`}>
          Save 20% off on an annual subscription
        </p>
        <span css={(theme) => css`
          margin-top: 10px;
          font-family: ${fonts.display};
          text-decoration: underline;
          color: ${theme.deepOcean}
        `}>
          <a
            href="https://www.birdi.io/pricing"
            target="_blank"
            rel="noopener noreferrer"
            css={(theme) => css`
              color: ${theme.deepOcean}
            `}
          >
            See full inclusions list
          </a>
        </span>
      </div>
      <div css={css`
        display: flex;
        flex-direction: row;
        padding: 1em 10em;
        font-family: ${fonts.display};
        @media (max-width: ${breakpoints.xs}) {
          flex-direction: column;
          padding: 2em 0;
        }
      `}
      >
        <FreePlanOption
          paymentInterval={paymentInterval}
          prices={prices}
          planIsCanceled={currentPlan?.status === 'canceled'}
        />
        <PlusPlanOption
          paymentInterval={paymentInterval}
          prices={prices}
          planIsCanceled={currentPlan?.status === 'canceled'}
          isTrial={meta.organisation?.freeTrialDate === null && meta.organisation.plan === 'free'}
        />
        <GrowthPlanOption
          paymentInterval={paymentInterval}
          prices={prices}
          planIsCanceled={currentPlan?.status === 'canceled'}
          isTrial={meta.organisation?.freeTrialDate === null && meta.organisation.plan === 'free'}
        />
        <EnterpriseOption
          paymentInterval={paymentInterval}
        />
      </div>
    </div>
  );
};

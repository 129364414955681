import React from 'react'
import { css } from '@emotion/react'
import { stroot, sites as sitesAPI } from '@birdi/js-sdk';
import * as Dialog from '@radix-ui/react-dialog';
import CloseSVG from '@birdi/icons/close.svg';
import { closeButtonCSS, contentCSS, portalCSS } from '@birdi/modal-kit';
import {
  Formik, Form, Field, FormikActions,
} from 'formik';
import { useDispatch } from 'react-redux';
import { formStyles } from '@birdi/theme/blocks';
import { LocationSelect } from '@birdi/google-places-autocomplete';
import { buttonBlue } from '@birdi/theme';
import { errorMessage } from '@birdi/theme/form';
import { SiteParams } from '@birdi/js-sdk/src/public/sites';

interface NewSiteModalProps {
  // children: React.ReactNode;
  // addSite: void;
  open: boolean;
  setOpen: (value: boolean) => void;
  refreshSites: any;
}

const fieldCSS = css`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const NewSiteModal = ({ open, setOpen, refreshSites }: NewSiteModalProps) => {
  // TODO: editing site
  const dispatch = useDispatch();

  const submit = async (params: SiteParams, {setSubmitting, setFieldError}: FormikActions<any>) => {
    setSubmitting(false)

    try {
      await sitesAPI.createSite(stroot('obee0i'), params);
      await refreshSites();
      setOpen(false);
    } catch (err) {
      console.log(err)
      const error = err as Error;
      setFieldError('general', error.message || 'something went wrong')
    }
  }

  const validate = (values: Record<string, unknown>) => {
    const errors: Record<string, string> = {}

    if (!values.name) errors.name = 'Site name is required';
    if (!values.location) errors.location = 'Location is required';

    return errors;
  }

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      {/* <Dialog.Trigger asChild>
        {children}
      </Dialog.Trigger> */}

      <Dialog.Portal>
        <Dialog.Overlay/>

        <div css={portalCSS}>
          <Dialog.Content css={contentCSS}>
            <Dialog.Close css={closeButtonCSS}><CloseSVG /></Dialog.Close>
            <Dialog.Title>Create a Site</Dialog.Title>
            {/* TODO: graphic */}

            <Formik
              initialValues={{ name: '', location: '' }}
              onSubmit={submit}
              validate={validate}
            >
              {({
                handleSubmit, errors, touched, setFieldValue, isValid
              }) => (
                <Form css={formStyles}>
                  <label css={fieldCSS}>
                    Site name
                    <Field type='text' name='name' id='name' placeholder='What should this site be called?' />
                  </label>
                  {errors.name && touched.name && <div css={errorMessage}>{errors.name}</div>}

                  <label htmlFor='location' css={css`${fieldCSS}; margin-bottom: 1rem;`}>
                    Select a location
                    {/* TODO: Possible to get touched for custom select? */}
                    <LocationSelect
                      inputId='location'
                      onLocationSelect={(geocode) => {
                        setFieldValue('location', geocode.location);
                      }}
                    />
                  </label>
                  {errors.location && touched.location && <div css={errorMessage}>{errors.location}</div>}

                  <label css={fieldCSS}>
                    Description (optional)
                    <Field
                      type='text'
                      component='textarea'
                      name='description'
                      id='description'
                      placeholder='Add any key details about the site here. This can be edited later.'
                    />
                  </label>

                  <footer css={css`margin-top: 1rem; display: flex; justify-content: flex-end;`}>
                    {(errors as any).general && (
                      <div css={css`${errorMessage}; margin-top: 1em;`}>{(errors as any).general}</div>
                    )}
                    <button type='button' css={theme => css`${buttonBlue(theme)}; width: 7rem;`} disabled={!isValid} onClick={handleSubmit}>
                      Add Site
                    </button>
                  </footer>
                </Form>
               )}
            </Formik>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

import React, { ReactNode } from 'react';
import Select from 'react-select';
import history from '../state/history';
import { fonts } from '@birdi/theme';

interface ActionDropDownProps {
  openAssessmentModal: () => void,
}

const ActionDropDown: React.FC<ActionDropDownProps> = ({
  openAssessmentModal,
}) => {
  const options = [
    {
      value: 'Create a map',
      label: 'Create a map',
      link: '/map/missions/plan',
      externalLink: false,
      onClick: () => null,
    },
    {
      value: 'Process a map',
      label: 'Process a map',
      link: '/missions/organisation',
      externalLink: false,
      onClick: () => null,
    },
    {
      value: 'Assess an asset',
      label: 'Assess an asset',
      link: '',
      externalLink: false,
      onClick: openAssessmentModal,
    },
    {
      value: 'Get help',
      label: 'Get help',
      link: 'https://help.birdi.io/en',
      externalLink: true,
      onClick: () => null,
    },
  ];

  return (
    <div>
      <Select
        isSearchable={false}
        onChange={(option) => {
          if (option.value === 'Assess an asset') {
            option.onClick();
          } else if (!option.externalLink) {
            history.push(option.link);
          } else if (option.externalLink) {
            window.open(option.link, '_blank');
          }
        }}
        // defaultValue={options[0]}
        placeholder="Select ..."
        options={options}
        styles={{
          control: (provided) => ({
            ...provided,
            height: '100%',
            width: '12em',
            fontFamily: fonts.display,
            borderRadius: '1em',
            backgroundColor: '#0078C0', // deepOcean
            color: 'white',
            fontWeight: 'bold',
          }),
          menu: (provided) => ({
            ...provided,
            borderRadius: '1em',
            overflow: 'hidden',
            backgroundColor: '#EBF4FF',
          }),
          singleValue: (provided) => ({
            ...provided,
            color: 'white',
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? '#0078C0' : '#EBF4FF', // deepOcean : lightOcean
            color: state.isSelected ? 'white' : '#0078C0',
            fontWeight: 'bold',
            fontFamily: fonts.display,
            '&:hover': {
              backgroundColor: state.isSelected ? '#0078C0' : '#D4E3F6',
            }
          }),
          placeholder: (provided) => ({
            ...provided,
            color: 'white',
            // fontWeight: 'normal',
          })
        }}
      />
    </div>
  );
};

export default ActionDropDown;

import { ReduxState } from '@birdi/types';
import { createAction, Action } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const LOAD = 'meta/LOAD';
export const UPDATE = 'meta/UPDATE';
export const UPDATE_ORG = 'meta/UPDATE_ORG';

type MetaState = ReduxState.MetaState;
type MetaOrganisation = ReduxState.MetaOrganisation;

// Loading user object
export const initialState: MetaState = {
  loaded: false,
  onboardingCompleted: false,
  redirect: null,
  // this is used to accept mission invitations on sign up
  // if visited through mission share invite e.g. /share/:id
  invitation: null,
  id: null,
  fname: null,
  lname: null,
  phone: null,
  email: null,
  jobNotifications: false,
  role: null,
  permissions: [],
  opsActions: [],
  profilePic: '',
  orgFeatureFlags: [],
  organisation: {
    externalBilling: false,
    name: null,
    stripeCustomerId: null,
    plan: null,
    planInterval: null,
    displayBranding: false,
    displayEmailHeader: false,
    displayLogo: false,
    logo: null,
    processingFlavour: 'none',
    emailHeader: null,
  },
};

export const updateOrganisation = createAction<Partial<MetaOrganisation>>(UPDATE_ORG);
export const updateMeta = createAction<Partial<MetaState>>(UPDATE);
export const loadMeta = createAction<Partial<MetaState>>(LOAD);

export const metaReducer = (state: MetaState = initialState, action: Action): MetaState => {
  if (updateOrganisation.match(action)) {
    const organisation = { ...state.organisation, ...action.payload };
    return { ...state, organisation };
  }
  if (updateMeta.match(action)) {
    return { ...state, ...action.payload };
  }
  if (loadMeta.match(action)) {
    return { ...state, ...action.payload, loaded: true };
  }
  return state;
};

export const useMetaSelector = () => useSelector<{ meta: MetaState }, MetaState>((state) => state.meta);

/* global fetch */

import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag
} from '../helpers';

export const get = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/onboarding`;
  return fetchAPI(stleaf(st, 'oo8tee'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const completeOnboarding = (st: SpanTag): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/onboarding/complete`;
  return fetchAPI(stleaf(st, 'ahqu6e'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
  });
};

interface AnswersV1 {
  version?: '1';
  team?: string;
  industry?: string;
}

export const updateAnswers = (st: SpanTag, answers: Partial<AnswersV1>): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/onboarding/answers`;
  return fetchAPI(stleaf(st, 'siegh1'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(answers),
  });
};

/* globals window, fetch */

import { config } from '../config';
import { headers, SpanTag, stleaf, fetchAPI } from '../helpers';


export const mockAssessmentTypes = (st: SpanTag) => {
  const url = `${config.API_URL}/v1/dev/assessment-types/`;
  return fetchAPI(stleaf(st, 'aicoh2'), url, {
      method: 'POST',
        headers,
        credentials: 'include',
      },
    );
};

import { stroot } from '@birdi/js-sdk'
/* global btoa */

import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3'
import { uppy as uppyAPI } from '@birdi/js-sdk';
import { httpToS3 } from '@birdi/utils/src/s3';

export function fileToUppyOpts(file: File) {
  return {
    name: file.name,
    type: file.type,
    data: new Blob([file], { type: file.type })
  }
}

const defaultAllowedFileTypes = [
  'image/*',
  'text/*',
  'application/pdf',
];

// Used for single part, one-off S3 PUT uploads
// See Uppy S3 documentation: https://uppy.io/docs/aws-s3/
// Presently, files uploaded here go into the user bucket only

interface UploadOneOpts {
  name: string;
  type: string;
  data: Blob;
  allowedFileTypes?: Array<string>;
  public?: boolean;
  userId?: string; // Administrator only functionality -> upload to other users
}

export function uploadOne(opts: UploadOneOpts) {
  return new Promise((resolve, reject) => {
    const uppy = new Uppy({
      id: 'user-upload',
      autoProceed: true,
      allowMultipleUploads: false,
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: (opts.allowedFileTypes && opts.allowedFileTypes.length) ? opts.allowedFileTypes : defaultAllowedFileTypes,
      },
    });
    uppy.use(AwsS3, {
      async getUploadParameters(file) {
        const response = await uppyAPI.getSignedSinglePartUpload(stroot('aim4ch'), {
          fileName: file.name,
          contentType: file.type,
          ...(opts.userId && { userId: opts.userId }),
          ...(opts.public && { public: true }),
        })
        return response.body;
      },
    });
    uppy.addFile({
      name: opts.name,
      type: opts.type,
      data: opts.data,
    });
    uppy.on('complete', (result) => {
      if (result.successful) {
        const s3Url = httpToS3(result.successful[0].uploadURL);
        const completedUpload = {
          s3Url,
          ...result.successful[0],
        }
        return resolve(completedUpload);
      }
      return reject(result.failed);
    })
    return uppy;
  });
}

import { stroot } from '@birdi/js-sdk'
import { credentials } from '@birdi/js-sdk';
import { CredentialsType } from '@birdi/js-sdk/src/public/credentials';
import { createAction, Action, Dispatch } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const enum CredentialsActions {
  FETCH = 'credentials/FETCH',
  FETCH_ERROR = 'credentials/FETCH_ERROR',
  DELETE = 'credentials/DELETE',
  ADD = 'credentials/ADD',
  EDIT = 'credentials/EDIT',
}

interface CredentialsState {
  loaded: boolean;
  items: CredentialsType[];
}

export const initialState: CredentialsState = {
  loaded: false,
  items: [],
};

const fetchAction = createAction<Partial<CredentialsType>[]>(CredentialsActions.FETCH);
const deleteAction = createAction<string>(CredentialsActions.DELETE);
const addAction = createAction<Partial<CredentialsType>>(CredentialsActions.ADD);
const editAction = createAction<Partial<CredentialsType>>(CredentialsActions.EDIT);

export const useCredentialsSelector = () => useSelector<{ credentials: CredentialsState }, CredentialsState>((state) => state.credentials);

export const get = (dispatch: Dispatch) =>
  credentials.get(stroot('shai6e'))
    .then((response) => {
      if (response.body) dispatch(fetchAction(response.body));
    })
    .catch((err) => console.log(err)); // TODO: Log application area

export const remove = (dispatch: Dispatch, id: string) =>
  credentials.remove(stroot('ien5oh'), id)
    .then(() => {
      dispatch(deleteAction(id));
    })
    .catch((err) => console.log(err)); // TODO: Log application area

export const add = (dispatch: Dispatch, credential: CredentialsType) =>
  credentials.add(stroot('ohpeu6'), credential)
    .then((response) => {
      if (response.body) dispatch(addAction(response.body));
    })
    .catch((err) => console.log(err)); // TODO: Log application area

export const edit = (dispatch: Dispatch, id: string, credential: CredentialsType) =>
  credentials.edit(stroot('pa8ees'), id, credential)
    .then((response) => {
      if (response.body) dispatch(editAction(response.body));
    })
    .catch((err) => console.log(err)); // TODO: Log application area

export const credentialsReducer = (state = initialState, { type, payload }: { type: CredentialsActions, payload: any }) => {
  switch (type) {
    case CredentialsActions.ADD:
      return {
        ...state,
        type: '',
        number: '',
        upload: '',
        uploadTitle: '',
        expiryDate: '',
        items: [...state.items, payload],
      };
    case CredentialsActions.EDIT: {
      const credentialsList = [...state.items];
      const newcredentialsList = credentialsList
        .map((credential) => (credential.id === payload.id ? payload : credential));
      return { ...state, items: newcredentialsList };
    }
    case CredentialsActions.FETCH:
      return { ...state, loaded: true, items: payload };
    case CredentialsActions.DELETE:
      return { ...state, items: state.items.filter((cred) => (cred.id !== payload)) };
    case CredentialsActions.FETCH_ERROR:
      return { ...payload, loaded: false };
    default:
      return state;
  }
};

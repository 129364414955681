import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { css } from '@emotion/react';
import { useForm, Controller } from 'react-hook-form';
import { button } from '@birdi/theme/blocks';
import { blockLabel, textInput, errorMessage } from '@birdi/theme/form';
import { BirdiTheme, fonts } from '@birdi/theme';
import * as userActions from './actions';
import { validatePhoneNumber } from '../onboarding/utils';
import { pageDescription } from '@birdi/theme/blocks';
import PlayCircleSVG from '@birdi/icons/play-circle.svg';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import { INTERCOM_TOUR_CODES } from '../common/consts';

export const UserDetailsInternal = (props): React.ReactElement => {
  // TODO: Trigger Redux value refresh
  const {
    account, updateStateAndPatch,
    getProfile,
  } = props;

  const {
    register, getValues, handleSubmit, reset,
    control, formState,
  } = useForm();

  // Reload profile on page load
  useEffect(() => {
    getProfile();
  }, []);

  // Reset form when data has been gathered
  useEffect(() => {
    reset({
      fname: account.fname,
      lname: account.lname,
      phone: account.phone,
      email: account.email,
      company: account.company,
      position: account.position,
    });
  }, [account.loaded]);

  const save = () => {
    const initialValues = {
      fname: account.fname,
      lname: account.lname,
      phone: account.phone,
      email: account.email,
      company: account.company,
      position: account.position,
    };
    const finalValues = getValues();

    const valuesToUpdate = Object.keys(finalValues)
      .reduce((acc, key) => {
        if (finalValues[key] !== initialValues[key]) acc[key] = finalValues[key];
        return acc;
      }, {});

    if (!Object.keys(valuesToUpdate).length) return false;
    return updateStateAndPatch(valuesToUpdate);
  };

  return (
    <div>
      <h2 css={css`margin: 1em`}>Account Settings</h2>
      <div css={pageDescription}>
        <p>Manage your account settings and preferences.</p>
        <div>
          <button
            type="button"
            onClick={() => {
              window?.Intercom && window.Intercom('startTour', INTERCOM_TOUR_CODES.ACCOUNT_SETTINGS);
            }}
          >
            <PlayCircleSVG />
            <span>Take a quick tour of account settings</span>
          </button>
          <a
            href="https://help.birdi.io/en/articles/6113413-edit-account-name-and-details"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn more about your account settings</span>
          </a>
        </div>
      </div>
      <div css={(theme: BirdiTheme) => css`
        padding: 1.5em;
        `}
      >
        <div css={css`max-width: 35em;`}>
          <form onSubmit={handleSubmit(save)}>
            <label css={blockLabel} htmlFor="fname">
              First Name
              <input
                css={textInput}
                type="text"
                id="fname"
                {...register('fname')}
              />
            </label>
            <label css={blockLabel} htmlFor="lname">
              Last Name
              <input
                css={textInput}
                type="text"
                id="lname"
                {...register('lname')}
              />
            </label>
            <label css={blockLabel} htmlFor="phone">
              Phone
              <Controller
                render={({
                  field: { onChange },
                }) => (
                  <input
                    css={textInput}
                    type="text"
                    id="phone"
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                    defaultValue={account.phone}
                  />
                )}
                control={control}
                name="phone"
                rules={{
                  validate: (value: string) => (
                    (value !== null) && (validatePhoneNumber(value))
                  ),
                }}
              />
              {formState.errors.phone && (
                <span css={errorMessage}>Please provide a valid mobile number.</span>
              )}
            </label>
            <label css={blockLabel} htmlFor="email">
              Email
              <input
                css={textInput}
                type="text"
                id="email"
                {...register('email')}
                disabled
              />
            </label>
            <label css={blockLabel} htmlFor="company">
              Company
              <input
                css={textInput}
                type="text"
                {...register('company')}
                disabled={account.jobNotifications}
              />
            </label>
            <label css={blockLabel} htmlFor="position">
              Position Title
              <input
                css={textInput}
                type="text"
                {...register('position')}
                disabled={account.jobNotifications}
              />
            </label>
            <div css={css`display: flex; justify-content: flex-end`}>
              <button type="submit" css={(theme) => css`${button(theme)};`}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export const UserDetails = connect((state) => ({
  account: state.account,
}), (dispatch) => ({
  getProfile: () => dispatch(userActions.getProfile()),
  updateField: (field) => dispatch(userActions.updateField(field)),
  updateStateAndPatch: (field) => dispatch(userActions.updateStateAndPatch(field)),
}))(withRouter((UserDetailsInternal)));

import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { css } from '@emotion/react';
import { contentLimit } from '@birdi/theme/blocks';
import { toTitleCase } from '@birdi/utils/src/text';
import { EquipmentIcon } from '@birdi/equipment/equipment'; // TODO: labeling
import { EquipmentHeader } from './header';
import { BirdiTheme } from '@birdi/theme';
import { EquipmentType, getId } from '@birdi/js-sdk/src/public/equipment';
import { stroot } from '@birdi/js-sdk';

export const EquipmentSingle = () => {
  const match = useRouteMatch<{ id: string }>();
  const [equipment, setEquipment] = useState<Partial<EquipmentType>>({
    id: '',
    model: {
      id: '',
      type: '',
      model: '',
      manufacturer: '',
      attributes: '',
    },
    name: '',
    serialNo: '',
    purchaseDate: '',
    details: '',
  })

  const getEquipmentById = (id: string) => {
    return getId(stroot('sahr1o'), id);
  };
  useEffect(() => {
    getEquipmentById(match.params.id)
      .then((res) => {
        if (res.body) setEquipment(res.body);
      })
      .catch((err) => console.log(err));
  }, [match.params.id]);

  if (equipment === undefined) {
    return null;
  }
  return (
    <div
      css={css`
      display: flex;
      flex-direction: column;
      width: 100vw;
      height: 100vh;
    `}
    >
      <EquipmentHeader />
      {/* <EquipmentIcon equipment={equipment} additionalCss={equipmentLogo} /> */}
      <section css={(theme: BirdiTheme) => css`
        ${contentLimit}
        margin-bottom: 2rem;
        padding: 1rem 2rem;
        section {
          color: ${theme.midnight};
        }
      `}
      >
        <EquipmentIcon equipment={equipment} />
        <h1>
          {equipment.model?.type}
          {' '}
          (
          {toTitleCase(equipment.model?.model)}
          )
        </h1>
        <div css={css`
          position: relative;
        `}
        >
          {equipment.model?.type === 'Drone' && (
          <div>
            <span css={(theme: BirdiTheme) => css`margin-right: 1rem; color: ${theme.mono70};`}>Batteries</span>
            <span>{equipment.batteryCount || '-'}</span>
          </div>
          )}
          <div>
            <span css={(theme: BirdiTheme) => css`margin-right: 1rem; color: ${theme.mono70};`}>Serial Number</span>
            <span>{equipment.serialNo ? equipment.serialNo : '-'}</span>
          </div>
          <div>
            <span css={(theme: BirdiTheme) => css`margin-right: 1rem; color: ${theme.mono70};`}>Notes</span>
            <span>{equipment.name ? equipment.name : '-'}</span>
          </div>
        </div>
      </section>
    </div>
  );
  }

/* globals confirm */

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css, useTheme } from '@emotion/react';
import { ContextMenu, contextStyles } from '@birdi/context-menu/ContextMenu';
import { Link } from 'react-router-dom';
import { itemsNewTable } from '@birdi/theme/blocks';
import RoleSelect from '@birdi/select/RoleSelect';
import { BirdiTheme } from '@birdi/theme';
import * as userActions from './reducer';
import history from '../state/history';
import { loadSeats, useSeatsSelector, totalSeats } from '../billing/seats-reducer';

const columns = [
  {
    title: 'Name',
    property: 'name',
    component: ({ user }) => (
      <th scope="row" key="name" css={css`text-align: left;`}>
        <Link to={`/users/${user.scopeId}`}>
          <span css={(theme: BirdiTheme) => css`color: ${theme.deepOcean};`}>
            {`${user.fname} ${user.lname}`}
          </span>
        </Link>
      </th>
    ),
    sortable: true,
  },
  {
    title: 'Email',
    property: 'email',
    component: ({ user }) => (
      <td key="email">
        <span>{`${user.email}${user.emailVerified ? '' : ' (invited)'}`}</span>
      </td>
    ),
  },
  {
    title: 'User Type',
    property: 'Role',
    component: ({
      isSelf, user, permissions, updateRole, seats, loadSeats, guestsOnly,
    }) => (
      <td
        key="role"
        css={css`
          margin-top: 25px;
          width: 150px;
        `}
      >
        {permissions.includes('org:manage-users') && (
          <RoleSelect
            onChange={(role) => {
              const roleId = role ? role.value : null;
              updateRole(user.id, roleId).then(() => {
                loadSeats();
              });
            }}
            defaultValue={{ label: user.roleName, value: user.roleId }}
            isDisabled={isSelf || (user.roleName === 'Creator')}
            guestsOnly={guestsOnly}
          />
        )}
      </td>
    ),
  },
  {
    title: 'Seat Allocation',
    property: 'SeatAllocation',
    component: ({ user }) => (
      <td key="seat" css={css`text-align: center;`}>
        <span css={css`
          background-color: ${(user.roleName === 'Guest' || user.hasOpsAccess) ? '#E6F4F9' : '#E9FAEF'};
          color: ${(user.roleName === 'Guest' || user.hasOpsAccess) ? '#1886AF' : '#2BC466'};
          padding: 0.5em;
          border-radius: 2px;
        `}
        >
          {(user.roleName === 'Guest' || user.hasOpsAccess) ? 'Free' : 'Paid'}
        </span>
      </td>
    ),
  },
  {
    title: '',
    property: 'contextMenu',
    component: ({
      isSelf, user, permissions, removeUser,
    }) => (
      <td key="delete">
        {
          permissions.includes('org:manage-users') && !isSelf && (
            <ContextMenu alignRight>
              {(props) => (
                <div css={(theme: BirdiTheme) => css(contextStyles(theme))}>
                  <button
                    type="button"
                    onClick={() => {
                      history.push(`/users/${user.scopeId}`);
                      props.hide();
                    }}
                  >
                    <span>Edit</span>
                  </button>
                  <button
                    onClick={() => {
                      confirm('Are you sure you want to delete this user?') && removeUser(user.id);
                      props.hide();
                    }}
                    type="button"
                  >
                    <span>Delete User</span>
                  </button>
                </div>
              )}
            </ContextMenu>
          )
        }
      </td>
    ),
  },
];

export const UserTable = ({
  users, permissions, organisationRoles,
}) => {
  const dispatch = useDispatch();
  const meta = useSelector((state) => state.meta);
  const seats = useSeatsSelector();
  const updateRole = (id: string, roleId: string) => dispatch(userActions.updateRole(id, roleId));
  const removeUser = (id: string) => dispatch(userActions.remove(id));
  const theme = useTheme();
  const [guestsOnly, setGuestsOnly] = useState<boolean>(false);

  useEffect(() => {
    loadSeats(dispatch);
  }, []);

  useEffect(() => {
    if (seats.loaded) {
      const guestsOnly = (seats.plan === 'free' || (totalSeats(seats) <= seats.userCount));
      setGuestsOnly(guestsOnly);
    }
  }, [seats.loaded]);

  return (
    <table id="usertable" css={css(itemsNewTable(theme))}>
      <thead
        css={(theme: BirdiTheme) => css`
          border-top: 2px solid ${theme.mono30};
          border-bottom: 2px solid ${theme.mono30};
          background-color: ${theme.mono20};
        `}
      >
        <tr>
          {columns.map((col) => (
            <th
              scope="col"
              key={col.property}
            >
              {col.title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <tr
            key={user.id}
            css={css`
              border-bottom: 0px solid white;
              text-align: left;
            `}
          >
            {columns.map((col) =>
              col.component({
                user,
                isSelf: user.id === meta.id,
                email: user.email,
                permissions,
                updateRole,
                seats,
                loadSeats: () => loadSeats(dispatch),
                guestsOnly,
                removeUser,
                organisationRoles,
              }, col.property))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

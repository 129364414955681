import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import {
  stroot, missionSharing, user as userAPI,
} from '@birdi/js-sdk/src/public';
import { button, selectContainer } from '@birdi/theme/blocks';
import { Controller, useForm } from 'react-hook-form';
import { errorMessage } from '@birdi/theme/form';
import CaretSVG from '@birdi/icons/caret.svg';
import { OrganisationUsersSelect } from '@birdi/select/OrganisationUsersSelect';

interface OrganisationShareProps {
  administrator;
  organisation: string;
  missionId: string;
  setUsers: (any) => void;
}

export const OrganisationShare
: React.FunctionComponent<OrganisationShareProps> = ({
  administrator, missionId, setUsers,
}) => {
  // Local selected user state, less cumbersome than embedding in form
  const [selectedUser, setSelectedUser] = useState(null);
  const [organisation, setOrganisation] = useState(null);
  const formMethods = useForm();

  useEffect(() => {
    // TODO: Replace this with better targeted API
    userAPI.getMeta(stroot('aasdz4'))
      .then((res) => {
        setOrganisation(res.body.organisation);
      });
  }, []);

  const submit = () => {
    const options = {
      ...formMethods.getValues(),
      ...(administrator && { readOnly: true }),
    };
    missionSharing.addUser(stroot('au3ohg'), missionId, options)
      .then((res) => {
        setUsers(res.body);
        formMethods.reset();
        setSelectedUser(null);
      })
      .catch((err) => {
        if (err.status === 400) {
          formMethods.setError('notUniqueScopeId');
        } else {
          console.log(err);
        }
      });
  };

  if (!organisation) return <div>Loading Organisation</div>;

  return (
    <form
      onSubmit={formMethods.handleSubmit(submit)}
      css={(theme) => css`
        display: flex;
        flex-direction: column;
        border-top: 1px solid ${theme.mono20};
        padding-bottom: 1rem;
        padding-top: 1em;
      `}
    >
      <h3>{`Share with ${organisation.name} users`}</h3>
      <Controller
        name="scopeId"
        control={formMethods.control}
        rules={{ required: true }}
        render={({
          field: { onChange },
        }) => (
          <OrganisationUsersSelect
            id="scopeId"
            value={selectedUser}
            styles={{
              container: (base) => ({
                ...base,
                marginBottom: '1em',
              }),
            }}
            onChange={(option, action) => {
              formMethods.clearErrors('notUniqueScopeId');
              if (action.action === 'select-option') {
                setSelectedUser(option.data);
                onChange(option.data.scopeId);
              } else if (action.action === 'clear') {
                setSelectedUser(null);
                onChange(null);
              }
            }}
          />
        )}
      />
      {formMethods.formState.errors.scopeId && (
        <div css={errorMessage}>Field is required</div>
      )}
      {formMethods.formState.errors.notUniqueScopeId && (
        <div css={errorMessage}>
          This mission is already being shared with this user
        </div>
      )}
      <div css={css`
        display: flex;
        justify-content: flex-end;`}
      >
        <div css={selectContainer}>
          <select
            type="role"
            id="role"
            {...formMethods.register('role')}
          >
            <option value="view">View only</option>
            <option value="edit">Edit</option>
            <option value="inspect">Inspect</option>
            {administrator && (<option value="partner">Partner</option>)}
            {administrator && (<option value="qa">QA</option>)}
          </select>
          <CaretSVG />
        </div>
        <button
          type="submit"
          css={(theme) => css`${button(theme)} padding: 0 2rem 0 2em;`}
        >
          Share
        </button>
      </div>
    </form>
  );
};

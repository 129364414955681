// import React, { useMemo } from "react";
import { css } from "@emotion/react";
import * as Switch from "@radix-ui/react-switch";
import CancelSVG from "@birdi/icons/cancel.svg";
import CheckSVG from "@birdi/icons/check.svg";
import { BirdiTheme } from "@birdi/theme";

const icon = (theme) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;

  & svg {
    width: 12px;
    height: 12px;
    stroke: hsl(0, 0%, 95%);
  }
`;

export const Toggle = ({
  checked, disabled, onChange,
}: ToggleProps) => (
  <Switch.Root
    css={(theme: BirdiTheme) =>
      css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        padding: 2px;
        margin: 2px;
        height: 16px;
        width: 32px;
        border-radius: 32px;
        background: ${theme.mode === 'dark' ? theme.mono20 : theme.mono70};
        color: hsl(0, 0%, 95%);
        box-sizing: border-box;

        &[data-state="checked"] {
          background: ${theme.deepOcean};
        }

        &:disabled {
          cursor: not-allowed;
          background: ${theme.mono40} !important;
        }
      `}
    checked={checked}
    disabled={disabled}
    onCheckedChange={onChange}
  >
    <Switch.Thumb
      css={(theme) =>
        css`
          position: absolute;
          width: 12px;
          height: 12px;
          background: hsl(0, 0%, 95%);
          border-radius: 32px;
          transition: transform 0.2s ease;

          &[data-state="checked"] {
            transform: translateX(16px);
          }
        `}
    />

    <span css={icon}><CheckSVG /></span>
    <span css={icon}><CancelSVG /></span>
  </Switch.Root>
);

export interface ToggleProps {
  checked: boolean;
  disabled?: boolean;
  onChange(checked: boolean): void;
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import CurrentUsersSVG from '@birdi/icons/current-users.svg';
import { css } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { boxStyle, itemsStyle, underlinedButtonStyle } from './styles';
import { useSeatsSelector, loadSeats, totalSeats } from '../seats-reducer';
import { useMetaSelector } from '../../meta/reducer';

export const CurrentUsers: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const seats = useSeatsSelector();
  const meta = useMetaSelector();
  useEffect(() => {
    loadSeats(dispatch);
  }, []);
  if (!seats.loaded) return <></>;

  return (
    <div css={boxStyle}>
      <div css={css`
        ${itemsStyle}
        @media (max-width: ${breakpoints.xs}) {
          display: none;
        }
      `}
      >
        <CurrentUsersSVG css={css`font-size: 8em;`} />
      </div>
      <div css={css`
        display: flex;
        flex: 3;
      `}
      >
        <div css={(theme: BirdiTheme) => css`${itemsStyle}; border-right: solid 1px ${theme.mono40};`}>
          {seats.unlimitedSeats ? <span>Unlimited seats</span> : (
            <>
              <div>
                <span css={css`font-weight: bold;`}>
                  {`${seats.userCount} / ${totalSeats(seats)}`}
                </span>
                <span>&nbsp;Paid<br />seats used</span>
              </div>
              {!meta.organisation.externalBilling && (
                <Link
                  to="/users"
                  css={theme => css`
                    text-decoration: underline;
                    color: ${theme.deepOcean} !important;
                    font-size: 14px;
                  `}
                >
                  Manage users
                </Link>
              )}
            </>
          )}
        </div>
        <div css={(theme: BirdiTheme) => css`${itemsStyle}; border-right: solid 1px ${theme.mono40};`}>
          <div css={css`display: flex; flex-direction: column;`}>
            <div>
              <span css={css`font-weight: bold;`}>
                {seats.guestCount}&nbsp;
              </span>
              {`Guest${seats.guestCount === 1 ? '' : 's'}`}
              {seats.birdiUserCount && (
                <p>
                  {`${seats.birdiUserCount} Birdi user${seats.birdiUserCount === 1 ? '' : 's'}`}
                </p>
              )}
            </div>
            (Free)
          </div>
        </div>
        <div css={itemsStyle}>
          <span css={css`font-weight: bold;`}>
            {seats.userCount + seats.guestCount + seats.birdiUserCount}
          </span>
          <span>Total Users</span>
        </div>
      </div>
    </div>
  );
};

import { stroot, organisationUsers } from '@birdi/js-sdk';
import React, { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { css, Theme } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import { withRouter } from 'react-router-dom';
import BackSVG from '@birdi/icons/arrow-left.svg';
import { BirdiTheme } from '@birdi/theme';
import history from '../../state/history';
// import { TabNavigation, UserManagementSections } from './TabNavigation';
import Permissions from './Permissions';
// import { PersonInfo } from './PersonInfo';
// import { Security } from './Security';

export const UserManagement = withRouter(({
  match,
}): React.ReactElement => {
  const [details, setDetails] = useState(null);
  // const [activeTab, setActiveTab] = useState(UserManagementSections.Permissions);

  useEffect(() => {
    const { scopeId } = match.params;
    organisationUsers.getUserDetails(stroot('as7oo7'), scopeId)
      .then((res) => {
        setDetails(res.body);
      });
  }, []);

  if (!details) return <div />;

  return (
    <div>
      <div>
        <button
          type="button"
          css={(theme: BirdiTheme) => css`
            padding: 0;
            margin-right: auto;
            display: flex;
            align-items: center;
            color: inherit;
            padding: 0.25rem 1rem;
            border-radius: 3px;
            &:hover {
              background: ${theme.mono20};
            }
          `}
          onClick={() => {
            history.goBack();
          }}
        >
          <BackSVG
            css={(theme: BirdiTheme) => css`
              width: 1em;
              height: 2em;
              color: ${theme.midnight};
              margin-right: 0.25em;
            `}
          />
          <span>Back</span>
        </button>
      </div>
      <div css={(theme: BirdiTheme) => css`
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          padding: 3em 5em;
          background: ${theme.mono20};
          @media (max-width: ${breakpoints.xs}) {
            padding: 2em;
            align-items: flex-start;
          }
        `}
      >
        <div css={(theme: BirdiTheme) => css`
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url('${details.profilePic}');
          background-position: center;
          background-size: cover;
          background-color: ${theme.flora};
          color: white;
          width: 5em;
          height: 5em;
          border-radius: 2.5em;
          margin-right: 2em;
        `}
        >
          {(!details.profilePic && details.fname && details.lname) && (
            <span css={css`font-size: 1.2em; font-weight: bold`}>
              {`${details.fname[0]}${details.lname[0]}`}
            </span>
          )}
        </div>
        <div css={css`
          display: flex;
          flex-direction: row;
          @media (max-width: ${breakpoints.xs}) {
            flex-direction: column;
          };
        `}
        >
          <div css={css`
            margin-right: 3em;
            span { display: block; }
            @media (max-width: ${breakpoints.xs}) {
              margin-right: 0;
            };
          `}
          >
            <span css={css`font-size: 1.1em; font-weight: bold`}>
              {`${details.fname} ${details.lname}`}
            </span>
            <span>{details.email}</span>
            <span>{`Phone: ${details.phone || '-'}`}</span>
            <span>{`Job Title: ${details.position || '-'}`}</span>
            <span>{`Location: ${details.locationId ? `${details.suburb}, ${details.state}` : '-'}`}</span>
          </div>
          <div css={css`
            span { display: block; }
            margin-top: 2em;
            @media (max-width: ${breakpoints.xs}) {
              margin-top: 0;
            };
          `}
          >
            <span>{`Member Since: ${format(parseISO(details.createdAt), 'dd MMM yyyy')}`}</span>
            <span>Last Active: -</span>
          </div>
        </div>
      </div>
      <div css={css`
        margin: 0 3em 2em 3em;
        @media (max-width: ${breakpoints.xs}) {
          margin-top: 0 1em 1em 1em;
        };
      `}
      >
        <Permissions details={details} />
        {/* <TabNavigation
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        {activeTab === UserManagementSections.Permissions && (
          <Permissions details={details} />
        )}
        {activeTab === UserManagementSections.PersonInfo && (
          <PersonInfo />
        )}
        {activeTab === UserManagementSections.Security && (
          <Security />
        )} */}
      </div>
    </div>
  );
});

// Iterated from:
// Switching off the lights - Adding dark mode to your React app
// Maxime Heckel
// https://blog.maximeheckel.com/posts/switching-off-the-lights-adding-dark-mode-to-your-react-app-with-context-and-hooks-f41da6e07269

import React from 'react';
import { Global, css } from '@emotion/react';
import { ThemeProvider as EmotionThemeProvider, useTheme as useEmotionTheme } from '@emotion/react';
import { getTheme, globals, themeDark, themeLight } from './theme';
import { reactSelectDarkTheme, reactSelectLight } from './blocks';

const defaultContextData = {
  dark: false,
  toggle: () => {},
};

export const ThemeOverride: React.FC<{ dark: boolean }> = (props) =>
  <EmotionThemeProvider theme={props.dark ? themeDark : themeLight}>
    {props.children}
  </EmotionThemeProvider>

const ThemeContext = React.createContext(defaultContextData);
const useTheme = () => React.useContext(ThemeContext);

const useEffectDarkMode = (darkMode: boolean) => {
  const [themeState, setThemeState] = React.useState({
    darkMode,
    hasThemeMounted: false,
  });
  React.useEffect(() => {
    setThemeState({ ...themeState, darkMode, hasThemeMounted: true });
  }, []);

  return [themeState, setThemeState];
};

const ThemeProvider = ({ children, darkMode = false }) => {
  const [themeState, setThemeState] = useEffectDarkMode(darkMode);
  // Do not load if theme has not mounted
  // to prevent flash on first retrieval
  // TODO: Is localStorage actually an asynchronous function?
  if (!themeState.hasThemeMounted) return <div />;

  const toggle = () => {
    const newThemeMode = !themeState.darkMode;
    setThemeState({ ...themeState, darkMode: newThemeMode });
  };

  const computedTheme = themeState.darkMode ? getTheme('dark') : getTheme('light');
  return (
    <EmotionThemeProvider theme={computedTheme}>
      <Global styles={css(globals(computedTheme))} />
      <ThemeContext.Provider
        value={{
          darkMode: themeState.darkMode,
          toggle,
          theme: computedTheme,
        }}
      >
        {children}
      </ThemeContext.Provider>
    </EmotionThemeProvider>
  );
};

const useSelectTheme = () => {
  const appTheme = useEmotionTheme();
  const selectTheme = appTheme.mode === 'light' ? reactSelectLight : reactSelectDarkTheme;

  return selectTheme;
};

export { ThemeProvider, useTheme, useSelectTheme };

import { stroot } from '@birdi/js-sdk'
import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { software } from '@birdi/js-sdk/src/public';

export const SoftwareSelect = ({
  onChange, value, theme, isMulti, inputId,
}) => {
  const [assetTags, loadSoftwareTags] = useState([]);
  useEffect(() => {
    software.getTags(stroot('die9ee'))
      .then((data) =>
        loadSoftwareTags(data.body.items.map((item) => ({ value: item.id, label: item.name }))));
  }, []);

  async function getOptions(name, callback) {
    const softwareRequest = await software.findByName(stroot('aph6nu'), name);
    const softwareUpdated = softwareRequest.body;
    const options = softwareUpdated.map((item) => ({
      label: item.name,
      value: item.id,
    }));
    return callback(options);
  }

  return (
    <>
      <AsyncSelect
        type="checkbox"
        theme={theme}
        styles={{
          control: (base, theme) => ({
            ...base,
            height: '3em',
            minWidth: '20em',
          }),
        }}
        isMulti={isMulti}
        isClearable
        onChange={(option, action) => onChange(option, action)}
        onClear
        defaultOptions={assetTags}
        loadOptions={(input, callback) => getOptions(input, callback)}
        value={value ? assetTags
          .filter((item) => value
            .some((valueItem) => (valueItem.label ? item.label === valueItem.label
              : item.value === valueItem))) : null}
        placeholder="Software..."
        inputId={inputId}
      />
    </>
  );
};

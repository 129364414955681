import React from 'react';
import { css } from '@emotion/react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Jobs } from '@birdi/types';
import { JobRequestCard } from './Card';

type JobRequestProps = RouteComponentProps;

const JobRequestsList: React.FunctionComponent<JobRequestProps> = ({
  history, jobRequests,
}) => {
  if (jobRequests.length === 0) return (<div />);
  return (
    <div css={css`
      width: calc(100% + 1rem);
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    `}
    >
      {jobRequests.map((jobRequest) => (
        <JobRequestCard
          key={jobRequest.id}
          jobRequest={jobRequest}
          handleCardClick={() => window.location.href = `${process.env.MAP_APP_URL}/incoming/${jobRequest.id}`}
        />
      ))}
    </div>
  );
};

export const JobRequestsListRouted = withRouter(JobRequestsList);

import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import { BirdiTheme, breakpoints } from '@birdi/theme';
import { stroot } from '@birdi/js-sdk';
import { getStorageInfo } from '@birdi/js-sdk/src/public/organisation';
import CurrentPlanSVG from '@birdi/icons/current-plan.svg';
import { boxStyle, itemsStyle } from './styles';

interface StorageStats {
  storageGb: number;
  mediaCount: number;
  mediaGb: number;
}

export const StorageInfo: React.FC = () => {
  const [storageStats, setStorageStats] = useState<StorageStats | null>(null);
  useEffect(() => {
    getStorageInfo(stroot('faci1a')).then((res) => {
      if (res.body) {
        setStorageStats({
          mediaGb: res.body.mediaGb,
          storageGb: res.body.storageGb,
        });
      }
    }).catch((err) => console.log(err));
  }, []);

  if (!storageStats) return <></>;

  return (
    <div css={boxStyle}>
      <div css={(theme: BirdiTheme) => css`${itemsStyle}; display: flex; flex-direction: row; border-right: solid 1px ${theme.mono40};`}>
        <div css={css`
          ${itemsStyle};
          display: flex;
          flex-direction: row;
          flex: 1;
          @media (max-width: ${breakpoints.xs}) {
            display: none;
          }
        `}
        >
          <CurrentPlanSVG css={css`font-size: 8em;`} />
        </div>
        <div css={css`
          ${itemsStyle};
          display: flex;
          flex-direction: row;
          flex: 1;
        `}
        >
          <span css={css`font-weight: bold`}>
            {`${storageStats.mediaGb} GB`}
          </span>
          <span>
            &nbsp;
            {`/ ${storageStats.storageGb} GB`}
          </span>
        </div>
      </div>
      <div css={itemsStyle}>
        <span css={css`font-weight: bold;`}>Need more storage?</span>
        <a
          href="mailto:support@birdi.io"
          target="_blank"
          css={(theme) => css`
          text-decoration: underline;
          color: ${theme.deepOcean} !important;
          font-size: 14px;
          `}
          rel="noreferrer"
        >
          Contact us
        </a>
      </div>
    </div>
  );
};

export type Coordinates = [number, number];
export type Bbox = [[number, number], [number, number]];

export interface Location {
  streetAddress: string;
  suburb: string;
  state: string;
  country: string;
  name: string;
  center?: Coordinates;
  bbox?: Bbox;
}

export const blankLocation: Location = {
  streetAddress: '',
  suburb: '',
  state: '',
  country: '',
  name: '',
};

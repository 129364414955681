import { stroot, user as userAPI } from '@birdi/js-sdk';
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { css, useTheme } from '@emotion/react';
import { breakpoints, fonts } from '@birdi/theme/variables';
import { blockLabel } from '@birdi/theme/form';
import { Link } from 'react-router-dom';
import { contextualHeader } from '@birdi/theme/blocks';
import BackSVG from '@birdi/icons/arrow-left.svg';
import TakePhotoSVG from '@birdi/icons/illustration-take-photo.svg';
import MultiplicationSVG from '@birdi/icons/multiplication.svg';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import Select from 'react-select';
import history from '../state/history';

export const inputStyle = (theme: BirdiTheme) => css`
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 2px solid ${theme.mono70};
  margin-bottom: 0.5em;
  font-family: ${fonts.display};
`;

const resolutionsList = [
  { value: 45, label: '45 Megapixels', width: 8192, height: 5460 },
  { value: 20, label: '20 Megapixels', width: 5163, height: 3872 },
  { value: 16, label: '16 Megapixels', width: 4618, height: 3464 },
  { value: 12, label: '12 Megapixels', width: 4000, height: 3000 },
  { value: 10, label: '10 Megapixels', width: 3651, height: 2738 },
];

const gpRates = {
  AUD: { free: 13.20, plus: 11.83, growth: 9.9 },
  USD: { free: 9.6, plus: 8.6, growth: 7.2 },
};

export const CostCalculator = (): React.ReactElement => {
  const birdiTheme = useTheme();
  const [currency, setCurrency] = useState<'USD' | 'AUD'>('AUD');

  const {
    register, control, getValues, setValue,
  } = useForm();

  useEffect(() => {
    userAPI.getCurrency(stroot('siequ7'))
      .then((res) => setCurrency(res.body.currency.toUpperCase()));
  }, []);

  const calculateCost = () => {
    const totalGp = Number(getValues('total-GP'));
    const freePlanCost = (totalGp * gpRates[currency].free).toFixed(2);
    const plusPlanCost = (totalGp * gpRates[currency].plus).toFixed(2);
    const growthPlanCost = (totalGp * gpRates[currency].growth).toFixed(2);
    setValue('free-plan-cost', `$${freePlanCost} ${currency}`);
    setValue('plus-plan-cost', `$${plusPlanCost} ${currency}`);
    setValue('growth-plan-cost', `$${growthPlanCost} ${currency}`);
  };

  const calculateTotalGP = () => {
    const imagesCountNum = Number(getValues('total-number-of-images'));
    const pixelCount = Number(getValues('individual-pixel-count-2'));
    const totalGP = (imagesCountNum * pixelCount) / 1000000000;
    setValue('total-GP', totalGP);
    calculateCost();
  };

  const calculateIndividualPixelCount = () => {
    const widthNum = Number(getValues('width'));
    const heightNum = Number(getValues('height'));
    const pixelCount = widthNum * heightNum;
    setValue('individual-pixel-count', pixelCount);
    setValue('individual-pixel-count-2', pixelCount);
    if (getValues('total-number-of-images')) {
      calculateTotalGP();
    }
  };

  return (
    <div>
      <header
        css={css`
          height: 4rem;
          ${contextualHeader(birdiTheme)}
        `}
      >
        <Link
          css={css`
            padding: 0;
            margin-right: auto;
            display: flex;
            align-items: center;
            color: inherit;
            padding: 0.25rem 0.5rem;
            border-radius: 3px;
            font-weight: 600;
            &:hover {
              background: ${birdiTheme.mono20};
            }
          `}
          to="/"
        >
          <BackSVG
            css={css`
              width: 1em;
              height: 2em;
              color: ${birdiTheme.mono80};
              margin-right: 0.25em;
            `}
          />
          <span>Back</span>
        </Link>
      </header>
      <div css={(theme: BirdiTheme) => css`
        background-color: ${theme.mono0};
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.5em;
      `}
      >
        <div css={css`
          text-align: center;
          max-width: 45%;
          @media (max-width: ${breakpoints.s}) {
            max-width: 70%;
          };
          @media (max-width: ${breakpoints.xs}) {
            max-width: 100%;
          };
        `}
        >
          <TakePhotoSVG css={css`font-size: 5em;`} />
          <h1 css={css`margin: 0;`}>Birdi’s Processing Cost Calculator</h1>
          <p>
            PAYG processing is calculated based on the total number of gigapixels in your map objective. Use Birdi’s Gigapixel Calculator to estimate your PAYG processing costs.
          </p>
        </div>
      </div>
      <form>
        <div css={(theme: BirdiTheme) => css`
          background-color: ${theme.lightOcean};
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 1em;
        `}
        >
          <div css={css`
            max-width: 60%;
            @media (max-width: ${breakpoints.s}) {
              max-width: 80%;
            };
            @media (max-width: ${breakpoints.xs}) {
              max-width: 100%;
            };
          `}
          >
            <h3 css={css`margin: 0;`}>Image Resolution</h3>
            <p css={css`margin: 0.75em 0;`}>Select a resolution or enter your images’ width and height dimensions below:</p>
            <label
              css={css`${blockLabel}; margin-top: 1.5em; width: 60%;`}
              htmlFor="resolution"
              id="select-resolution"
            >
              Select Resolution
              <Controller
                render={({ field }) => (
                  <Select
                    options={resolutionsList}
                    placeholder="Example: 20 Megapixels 4:3"
                    isClearable
                    onChange={(option) => {
                      field.onChange(option || null);
                      setValue('width', option.width);
                      setValue('height', option.height);
                      calculateIndividualPixelCount();
                    }}
                    value={getValues('resolution')}
                    inputId="resolution"
                  />
                )}
                control={control}
                name="resolution"
              />
            </label>
            <div css={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              margin-top: 1.5em;
            `}
            >
              <label
                css={blockLabel}
                htmlFor="width"
                id="select-width"
              >
                Width (px)
                <div css={(theme:BirdiTheme) => css`
                  background: ${theme.mono0};
                `}
                >
                  <input
                    {...register('width')}
                    type="text"
                    onChange={(e) => {
                      setValue('width', e.target.value);
                      setValue('resolution', null);
                      calculateIndividualPixelCount();
                    }}
                    css={inputStyle}
                  />
                </div>
              </label>
              <MultiplicationSVG css={css`margin: 0 0.1em; font-size: 1.5em;`} />
              <label
                css={blockLabel}
                htmlFor="height"
              >
                Height (px)
                <div css={(theme:BirdiTheme) => css`
                  background: ${theme.mono0};
                `}
                >
                  <input
                    {...register('height')}
                    type="text"
                    onChange={(e) => {
                      setValue('height', e.target.value);
                      setValue('resolution', null);
                      calculateIndividualPixelCount();
                    }}
                    css={inputStyle}
                  />
                </div>
              </label>
              <span css={css`margin: 0 0.5em;`}>=</span>
              <label
                css={blockLabel}
                htmlFor="individual-pixel-count"
              >
                Individual Pixel Count
                <input
                  {...register('individual-pixel-count')}
                  type="text"
                  readOnly
                  css={inputStyle}
                  value={getValues('individual-pixel-count')}
                />
              </label>
            </div>
            <h3 css={css`margin: 0;`}>Number of images in your objective</h3>
            <p css={css`margin: 0.75em 0;`}>Enter the total number of images in your mapping objective:</p>
            <div css={css`
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              margin-top: 1.5em;
            `}
            >
              <label
                css={blockLabel}
                htmlFor="total-number-of-images"
              >
                Total number of images
                <div css={(theme:BirdiTheme) => css`
                  background: ${theme.mono0};
                `}
                >
                  <input
                    {...register('total-number-of-images')}
                    type="text"
                    onChange={(e) => {
                      setValue('total-number-of-images', e.target.value);
                      calculateTotalGP();
                    }}
                    css={inputStyle}
                  />
                </div>
              </label>
              <MultiplicationSVG css={css`margin: 0 0.1em; font-size: 1.5em;`} />
              <label
                css={blockLabel}
                htmlFor="individual-pixel-count-2"
              >
                Individual Pixel Count
                <input
                  {...register('individual-pixel-count-2')}
                  type="text"
                  readOnly
                  css={inputStyle}
                  value={getValues('individual-pixel-count-2')}
                />
              </label>
              <span css={css`margin: 0 0.5em;`}>=</span>
              <label
                css={blockLabel}
                htmlFor="total-GP"
              >
                Total GP
                <input
                  {...register('total-GP')}
                  type="text"
                  readOnly
                  css={inputStyle}
                  value={getValues('total-GP')}
                />
              </label>
            </div>
            <div css={(theme: BirdiTheme) => css`
                width: 100%;
                height: 1px;
                background: ${theme.midnight};
                margin: 0.5em 0;
              `}
            />
            <h3 css={css`margin: 1.5em 0 0 0;`}>Total cost (Approx.)</h3>
            <p>The below pricing is approximate and based on your location and plan type.</p>
            <h3 css={css`margin: 0;`}>
              {currency}
              &nbsp;
              Pricing
            </h3>
            <table css={css`
                margin: 1em 0;
                tr {
                  td { padding-right: 2em; }
                }
              `}
            >
              <thead>
                <tr css={css`td { padding-bottom: 0.5em; }`}>
                  <td>Plan Type</td>
                  <td>Gigapixel Rate</td>
                  <td>Total Approx. Cost</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <input
                      type="text"
                      readOnly
                      css={inputStyle}
                      value="Free"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      readOnly
                      css={inputStyle}
                      value={`$${(gpRates[currency].free).toFixed(2)} ${currency}`}
                    />
                  </td>
                  <td>
                    <input
                      {...register('free-plan-cost')}
                      type="text"
                      readOnly
                      css={inputStyle}
                      value={getValues('free-plan-cost')}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      readOnly
                      css={inputStyle}
                      value="Plus"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      readOnly
                      css={inputStyle}
                      value={`$${(gpRates[currency].plus).toFixed(2)} ${currency}`}
                    />
                  </td>
                  <td>
                    <input
                      {...register('plus-plan-cost')}
                      type="text"
                      readOnly
                      css={inputStyle}
                      value={getValues('plus-plan-cost')}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      readOnly
                      css={inputStyle}
                      value="Growth"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      readOnly
                      css={inputStyle}
                      value={`$${(gpRates[currency].growth).toFixed(2)} ${currency}`}
                    />
                  </td>
                  <td>
                    <input
                      {...register('growth-plan-cost')}
                      type="text"
                      readOnly
                      css={inputStyle}
                      value={getValues('growth-plan-cost')}
                    />
                  </td>
                </tr>
                {currency === 'AUD' && (
                  <tr>
                    <td />
                    <td />
                    <td css={css`text-align: right;`}>
                      <span css={css`margin-right: 0.5em; font-size: 0.75em;`}>
                        All prices are incl. GST
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </form>
      <div css={(theme: BirdiTheme) => css`
        background-color: ${theme.mono0};
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2em;
      `}
      >
        <p css={css`font-size: 1.25em; font-weight: bold; margin-top: 0;`}>
          Ready to create a new map or process existing?
        </p>
        <button
          css={buttonBlue}
          type="submit"
          onClick={() => history.push('/missions/organisation')}
        >
          Get started
        </button>
      </div>
    </div>
  );
};

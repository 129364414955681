import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { css } from '@emotion/react';
import { ContextMenuHOC } from '@birdi/context-menu';
import { BirdiTheme, breakpoints, fonts } from '@birdi/theme';
import { shadows } from '@birdi/theme/variables';
import { useMatchWidth } from '@birdi/shared-deprecated/match-width';
import { hasPermission } from '@birdi/utils/src/helpers';
import { Branding } from '@birdi/branding/branding';
import BirdiIcon, { Icons } from '@birdi/icons';
import { AvatarButton } from './Avatar';
import { MobileMenu } from './MobileMenu';
import { useMetaSelector } from '../../meta/reducer';
import { UserWorkspaces } from '../../user-workspaces/user-workspaces';

export const MainHeaderCSS = (theme: BirdiTheme) => `
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
  height: 4rem;
  padding: 0rem 1rem;
  background: ${theme.sand};
  color: ${theme.mono70};
  font-weight: 500;
`;

const whiteButton = css`
  color: white;
  margin-right: 1rem;
  border: 1px solid #5d5d5d;
  padding: 0.5em;
  border-radius: 4px;
`;

// eslint-disable-next-line
export const CloudHeaderV2 = ContextMenuHOC(({
  toggleRef, toggle, open, wrapperRef, // Context menu props
}) => {
  const auth = useSelector((state) => state.auth);
  const meta = useMetaSelector();
  const mobile = useMatchWidth(breakpoints.m);
  const history = useHistory();
  return (
    <div css={(theme: BirdiTheme) => css(MainHeaderCSS(theme))}>
      <UserWorkspaces open={open} wrapperRef={wrapperRef} />
      <div css={css`
        display: flex;
        align-items: center;
        margin-right: auto;
      `}
      >
        {meta.loaded && (
          <Branding
            meta={meta}
            onClick={() => history.push('/')}
          />
        )}
        <button
          type="button"
          onClick={toggle}
          ref={toggleRef}
        >
          <BirdiIcon
            icon={Icons.Chevron}
            rotate="180"
            css={(theme: BirdiTheme) => css`color: ${theme.mono70};`}
          />
        </button>
      </div>
      <nav css={css`display: flex;`}>
        {auth.status === 'anonymous' && (
          <Link css={css`margin-right: 1.5rem; ${whiteButton}`} to="/sign-in">Sign in</Link>
        )}
        {auth.status === 'anonymous' && (
          <Link
            css={(theme: BirdiTheme) => css`
              ${whiteButton};
              background:
              ${theme.deepOcean};
              border-color: ${theme.deepOcean};
            `}
            to="/sign-up"
          >
            Sign up
          </Link>
        )}
        {auth.status === 'authenticated' && hasPermission(meta.permissions, 'org:manage-users') && (
          <Link
            to="/users?modal=invite"
            css={(theme: BirdiTheme) => css`
              font-family: ${fonts.display};
              font-size: 1rem;
              font-weight: 400;
              color: ${theme.midnight};
              white-space: nowrap;
              cursor: pointer;
              margin-right: 1rem;
              padding: 0.75em;
              ${mobile ? 'display: none;' : ''}
            `}
          >
            + Invite a team member
          </Link>
        )}
        {auth.status === 'authenticated' && meta.permissions.includes('org:default') && (
          <Link
            to="/map/missions/plan"
            css={(theme: BirdiTheme) => css`
              font-family: ${fonts.display};
              font-size: 1rem;
              font-weight: 400;
              color: ${theme.midnight};
              white-space: nowrap;
              cursor: pointer;
              margin-right: 1rem;
              padding: 0.75em;
              ${mobile ? 'display: none;' : ''}
            `}
          >
            + Create a map
          </Link>
        )}
        {auth.status === 'authenticated' && !mobile && (
          <AvatarButton />
        )}
        {auth.status === 'authenticated' && mobile && (
          <MobileMenu />
        )}
      </nav>
    </div>
  );
});

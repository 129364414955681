import React from 'react';
import { css, Theme } from '@emotion/react';
import {
  planOptions, planTitle, planSubTitle, currentPlanTitle,
  planSection1, planSection2, planSection3, planOptionsColumn,
  planTitleHolder, planSection4,
} from '@birdi/theme/blocks';
import PlusPlanSVG from '@birdi/icons/plus-plan-2.svg';
import GreenCheckCircleSVG from '@birdi/icons/green-check-circle.svg';
import { useMetaSelector } from '../meta/reducer';
import { Checkout } from './checkout-button';
import { PriceData } from './types';
import { sanitizedPrice, storagePricing, sanitizedPAYGProcessingPrice } from './utils';

interface PlusPlanOptionProps {
  paymentInterval: 'monthly' | 'annual';
  prices: PriceData;
  planIsCanceled: boolean;
  isTrial: boolean;
}

export const PlusPlanOption = (props: PlusPlanOptionProps): JSX.Element => {
  const meta = useMetaSelector();
  const { paymentInterval, prices, planIsCanceled, isTrial } = props;
  const title = 'plus';
  const {currency, ...currentPlusPrices} = prices[`${title}-${paymentInterval}`];
  const features = [
    'Additional processed outputs: contours, textured mesh',
    'Annotation reports',
    'View-only map sharing with guests',
    'Custom branding',
    'Multiple workspaces',
    'Inspections and Asset Assessment',
    'Assessment report',
    '3D model viewer',
    'RTK processing',
  ];

  // `Additional 1Tb storage ${sanitizedPrice(storagePricing.paid[currency], currency)}/month`,

  const payableMonths = (paymentInterval === 'monthly') ? 1 : 12;

  const totalPlanPriceIncludeGSD = sanitizedPrice(
    currentPlusPrices.unit_amount, currency,
  );
  const monthlyPlanPriceIncludeGSD = sanitizedPrice(
    (currentPlusPrices.unit_amount / payableMonths), currency,
  );
  const paygPriceIncludeGSD = sanitizedPAYGProcessingPrice(
    currentPlusPrices.gpCost, currency,
  );

  const label = (isTrial ? 'Start a 14-day free trial' : (
    ((planIsCanceled) || (meta.organisation.plan === 'free') || (meta.organisation.plan === 'plus' && meta.organisation.planInterval === 'monthly' && paymentInterval === 'annual')) ? 'Upgrade' : 'Downgrade'
  ))

  return (
    <div css={planOptionsColumn}>
      <span className='option-header'>Sole traders</span>
      <div css={planOptions}>
        <div css={planSection1}>
          <PlusPlanSVG width="7em" height="7em" />
          <div css={(theme) => planTitleHolder(theme.lightFlora)}>
            <span css={planTitle}>{title}</span>
          </div>
          <span>For sole traders who want to brand their business, request processing, and share maps and insights with their clients.</span>
          <span css={css`font-weight: bold`}>1 user.</span>
        </div>
        <div css={planSection2}>
          <span css={planSubTitle}>{monthlyPlanPriceIncludeGSD}</span>
          <span>per month</span>
          <span>plus PAYG processing</span>
        </div>
        <div css={planSection3}>
          {title === meta.organisation.plan && paymentInterval === meta.organisation.planInterval && !planIsCanceled && (
            <div css={currentPlanTitle}>Current plan</div>
          )}
          {(title !== meta.organisation.plan || paymentInterval !== meta.organisation.planInterval || planIsCanceled) && (
            <Checkout
              priceId={currentPlusPrices.id}
              label={label}
              selectedPlan={'plus'}
              differentInterval={!!meta.organisation.planInterval && (meta.organisation.planInterval !== paymentInterval)}
              planIsCanceled={planIsCanceled}
            />
          )}
        </div>
        <div css={planSection4}>
          <span css={css`font-weight: bold; margin-bottom: 1em; text-align: center; min-height: 2.5em`}>
            All the Free plan features plus:
          </span>
          {features.map((feature) => (
            <div
              key={feature}
              css={css`
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 0.25em;
                span {
                  display: block;
                  text-align: left;
                }
              `}
            >
              <GreenCheckCircleSVG
                css={(theme: Theme) => css`
                  color: ${theme.flora};
                  min-width: 1.25em;
                  width: 1.25em;
                  height: 1.25em;
                  margin-right: 0.5em;
                  margin-top: 0.25em;
                `}
              />
              <span>{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import LoginSVG from '@birdi/icons/log-in.svg';
import MenuAlt from '@birdi/icons/hero-menu-alt.svg';
import SettingsSVG from '@birdi/icons/settings.svg';
import LayoutSVG from '@birdi/icons/sidebar.svg';
import SunSVG from '@birdi/icons/sun.svg';
import MaskSVG from '@birdi/icons/mask-happy.svg';
import CheckSVG from '@birdi/icons/check.svg';
import PlusSVG from '@birdi/icons/plus.svg';
import { ContextMenu2 } from '@birdi/context-menu/context-menu-2';
import { useTheme, BirdiTheme } from '@birdi/theme';
import { stroot, auth } from '@birdi/js-sdk';
import { user, assessmentType } from '@birdi/js-sdk/src/dev';
import { css } from '@emotion/react';
import { MaskModal } from './MaskModal';

function createOpsUser() {
  return user.login(stroot('ez7hah'), {
    skipOnboarding: true,
    ops: true,
    plan: 'pro',
  });
}

function createProUser() {
  return user.login(stroot('k22hhi'), {
    skipOnboarding: true,
    ops: false,
    plan: 'pro',
    featureFlagOrganisations: ['pro-processing'],
  });

}

function createBasicUser() {
  return user.login(stroot('eb7hah'), {
    skipOnboarding: true,
  });
}

function createPilot() {
  return user.login(stroot('mckplt'), {
    pilot: true,
    skipOnboarding: true,
  });
}

const buttonCSS = (theme: BirdiTheme) => css`
display: block;
width: 100%;
color: inherit;
padding: 0.75em 0.5em;
border-radius: 3px;
text-align: left;
white-space: nowrap;
box-sizing: border-box;
line-height: inherit;
&:hover {
  background: ${theme.nightSky};
}
`;

interface MetaBar {
  id: string;
  masked: boolean;
  scopeRole: string;
  organisation: {
    id?: string;
    name?: string;
  }
  devTools: boolean,
  isOpsUser: boolean,
  missionRoleOverride: string;
  toggleOpsUI: (visibility: boolean) => void,
  opsUIVisible: boolean,
  opsActions: string[],
}

const unmask = () => auth.unmask(stroot('unmask'))
  .then(() => window.location.reload());

export function MetaBar({
  id = '',
  masked = false,
  scopeRole = '',
  organisation = {},
  devTools = process.env.DEVTOOLS as unknown as boolean,
  missionRoleOverride,
  opsActions = [],
}: MetaBar) {
  const [maskModalOpen, setMaskModalOpen] = useState<boolean>(false);
  const { toggle: toggleTheme, darkMode } = useTheme();
  if (!devTools && !opsActions.includes('access_ops')) return false;
  return (
    <div css={(theme: BirdiTheme) => css`
      position: fixed;
      bottom: 2px;
      left: 2px;
      padding: 0.25em 0.75em;
      border-radius: 6px;
      background: rgba(25, 25, 26, 0.90);
      font-size: 0.75em;
      color: ${theme.white};
      z-index: 100;
      span:not(:last-child) {
        margin-right: 0.25em;
      }
    `}
    >
      <div css={css`
        width: 10px;
        height: 10px;
        background: ${id ? '#5c9cff' : '#8c4444'};
        display: inline-block;
        border-radius: 8px;
        margin-right: 0.5em;
      `}
      />
      <span css={css`font-family: monospace;`}>
        {devTools ? 'Dev' : 'Prod'}
      </span>
      <MaskModal open={maskModalOpen} setOpen={setMaskModalOpen} />
      <ContextMenu2
        Menu={({ hide, wrapperRef }) => (
          <div
            ref={wrapperRef}
            css={(theme: BirdiTheme) => css`
              position: absolute;
              bottom: calc(100% + 0.25em);
              width: 20em;
              max-height: calc(100vh - 5em);
              overflow-y: scroll;
              padding: 0.25em;
              border-radius: 3px;
              background: ${theme.blackbird};
              &:hover svg {
                color: ${theme.white};
              }
          `}
          >
            {opsActions.includes('access_ops') && (
              <a
                href={`${process.env.MAP_APP_URL}/missions/plan-managed`}
                css={buttonCSS}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PlusSVG css={css`margin-right: 1em;`} />
                <span>New capture request</span>
              </a>
            )}
            {devTools && (
              <button
                onClick={() => {
                  createBasicUser();
                  hide();
                }}
                css={buttonCSS}
              >
                <LoginSVG css={css`margin-right: 1em;`} />
                <span>Login with new account</span>
              </button>
            )}
            {devTools && (
              <button
                onClick={() => {
                  createPilot();
                  hide();
                }}
                css={buttonCSS}
              >
                <LoginSVG css={css`margin-right: 1em;`} />
                <span>Login as pilot</span>
              </button>
            )}
            {devTools && (<>
              <button
                onClick={() => {
                  createOpsUser();
                  hide();
                }}
                css={buttonCSS}
              >
                <LoginSVG css={css`margin-right: 1em;`} />
                <span>Login with new ops account</span>
              </button>
              <button
                onClick={() => {
                  createProUser();
                  hide();
                }}
                css={buttonCSS}
              >
                <LoginSVG css={css`margin-right: 1em;`} />
                <span>Login with new pro account</span>
              </button>
            </>)}
            {id && (
              <button
                onClick={() => {
                  setMaskModalOpen(true);
                  hide();
                }}
                css={buttonCSS}
              >
                <MaskSVG css={(theme: BirdiTheme) => css`color: ${theme.white}; margin-right: 1em;`} />
                <span>Mask account</span>
              </button>
            )}
            {/* <button
              onClick={() => {
                toggleOpsUI(!opsUIVisible)
              }}
              css={buttonCSS}
            >
              <ShieldSVG css={css`color: white; margin-right: 1em;`} />
              <span>Show ops only UI</span>
              {opsUIVisible && <CheckSVG />}
            </button> */}
            <button css={buttonCSS} onClick={toggleTheme}>
              <SunSVG css={(theme: BirdiTheme) => css`color: ${theme.white}; margin-right: 1em;`} />
              <span>Experimental Dark Mode</span>
              {darkMode && <CheckSVG />}
            </button>
            {/* <button css={buttonCSS}>Mission permission masking</button> */}
            {devTools && (
              <a href={`${process.env.APP_URL}/dev/mission`} css={buttonCSS} onClick={hide}>
                <SettingsSVG css={css`margin-right: 1em;`} />
                Test tools
              </a>
            )}
            {devTools && (
              <button
                type="button"
                onClick={() => {
                  assessmentType.mockAssessmentTypes(stroot('woce6v'))
                    .then(() => { window.location.href = '/assessment-types'; })
                    .catch((err) => console.error(err));
                  hide();
                }}
                css={buttonCSS}
              >
                <span>Add assessment types</span>
              </button>
            )}
            <h4 css={(theme: BirdiTheme) => css`
              margin: 0.5em 1em;
              color: ${theme.white};
              font-weight: 600;
            `}
            >
              External links

            </h4>
            <a
              href={`${process.env.OPS_APP_URL}`}
              target="_blank"
              rel="noopener noreferrer"
              css={buttonCSS}
              onClick={hide}
            >
              <LayoutSVG css={css`margin-right: 1em;`} />
              ops.birdi.io
            </a>
            <hr css={(theme) => css`
              margin: 0.5em 1em 1em 1em;
              border-top: 1px solid ${theme.nightSky};
            `}
            />
            <div css={(theme: BirdiTheme) => css`
              padding: 0 1em 1em 1em;
              color: ${theme.white};
            `}
            >
              <div css={css`
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 100%;
              `}
              />
              {scopeRole && <div>{`${scopeRole} @ ${organisation.name}`}</div>}
              {missionRoleOverride && <div>{`Mission override: ${missionRoleOverride}`}</div>}
              {id ? (
                <>
                  <span css={css`user-select: none`}>ID: </span>
                  <span>{id}</span>
                </>
              ) : 'Anonymous'}
            </div>
          </div>
        )}
        Button={(props) => (
          <button
            onClick={props.toggle}
            ref={props.toggleRef}
            css={(theme: BirdiTheme) => css`
              color: ${theme.darkGrey};
              ${props.open && 'svg { color: white; }'}
              &:hover svg {
                color: white;
              }
          `}
          >
            <MenuAlt
              css={css`
              position: relative;
              top: 1.5px;
            `}
            />
          </button>
        )}
      />
      {masked && (
        <button
          type="button"
          onClick={unmask}
          css={(theme) => css`
            margin-right: 0.5em;
            color: ${theme.mono70};
            &:hover svg {
              color: white;
            }
          `}
        >
          <MaskSVG />
        </button>
      )}
    </div>
  );
}

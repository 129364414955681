import React from 'react';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/react';
import { stroot, subscription as subscriptionAPI } from '@birdi/js-sdk';
import history from '../state/history';
import { loadSeats } from './seats-reducer';
import { useMetaSelector } from '../meta/reducer';
import { changePlanButtonStyle } from './checkout-button';

export const CancelButton: React.FC<{ currentPlan: string, differentInterval: boolean, planIsCanceled: boolean }> =
  ({ currentPlan, differentInterval, planIsCanceled }) => {
  const dispatch = useDispatch();
  const meta = useMetaSelector();

  // for plus plan orgs there is already just one paid user
  const alertMessage = (currentPlan === 'plus')
    ? 'Are you sure you want to cancel your subscription?'
    : 'Are you sure you want to cancel your subscription? All organisation users except you will become guest users.';

  function cancelPlan() {
    const confirmed = window.confirm(alertMessage);
    if (!confirmed) return;
    localStorage.setItem('localOldPlan', meta.organisation.plan);
    subscriptionAPI.cancelSubscription(stroot('echo7n'))
      .then(() => {
        localStorage.setItem('cancelPlan', 'yes');
        loadSeats(dispatch);
        history.push('/billing/plan');
        window?.Intercom(
          "trackEvent",
          "subscription-downgraded",
          metadata
        );
      })
      .catch((err) => console.log(err));
  }
  return (
    <button
      disabled={differentInterval || planIsCanceled}
      onClick={cancelPlan}
      css={changePlanButtonStyle}
      type="button"
    >
      Downgrade
    </button>
  );
};

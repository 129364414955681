interface Config {
  API_URL: string;
  MEDIA_URL: string;
  JWT_NAME: string;
  POINTS_URL: string;
  COOKIE_DOMAIN?: string; // Not required in development
}

export const config: Config = {
  API_URL: '',
  MEDIA_URL: '',
  JWT_NAME: 'jwt',
  POINTS_URL: '',
};

export function setOpts(partialOptsObject: Partial<Config>): void {
  Object.assign(config, partialOptsObject);
}

import BackSVG from '@birdi/icons/arrow-left.svg';
import { css } from '@emotion/react';
import React from 'react';
import { Link } from 'react-router-dom';
import { contextualHeader } from '@birdi/theme/blocks';
import { BirdiTheme } from '@birdi/theme';

interface BackBarProps {
  children?: React.ReactNode;
  to: string;
}

export const BackBar = ({ children, to }: BackBarProps) => (
  <div css={(theme: BirdiTheme) => css`
    ${contextualHeader(theme)};
    margin: 0 0.25em 0.5em 0.75em;
    border-radius: 6px;
    width: calc(100% - 1.5em);
  `}>
    <Link
      css={(theme) => css`
      margin-right: auto;
      display: flex;
      align-items: center;
      color: inherit;
      padding: 0.25rem 0.5rem;
      border-radius: 3px;
      font-weight: 600;
      &:hover {
        background: ${theme.mono20};
      }
    `}
      to={to}
    >
      <BackSVG css={(theme: BirdiTheme) => css`
        width: 1em;
        height: 2em;
        color: ${theme.mono70};
        margin-right: 0.5em;
      `}
      />
      <span>Back</span>
    </Link>
    <>
      {children}
    </>
  </div>
);

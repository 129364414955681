import React from 'react';
import { CloudHeader } from '../common/Header';
import { CloudHeaderV2 } from '../common/HeaderV2';

export const DefaultLayout: React.FC = ({ children }) => (
  <main>
    <CloudHeaderV2 />
    {children}
  </main>
);

import React, { useState } from 'react';
import { format } from 'date-fns';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';
import { BirdiTheme } from '@birdi/theme';
import { itemsStyle } from './styles';
import { CurrentPlan, PlanType, planIntervalType } from '../types';
import { SeatsState, totalSeats } from '../seats-reducer';
import { ManageSeatsButton } from '../add-seats-modal';
import { numberToCurrencyStr } from '../utils';

const planNames = {
  free: 'Free',
  pro: 'Pro',
  plus: 'Plus',
  growth: 'Growth',
  enterprise: 'Ultimate',
};

interface PlanInfoBlockProps {
  currentPlan: CurrentPlan | null;
  plan: PlanType;
  externalBilling: boolean;
}

export const PlanInfoBlock: React.FC<PlanInfoBlockProps> = ({ currentPlan, plan, externalBilling }) => {
  const name = planNames[plan];
  return (
    <div css={(theme: BirdiTheme) => css`${itemsStyle}; border-right: solid 1px ${theme.mono40};`}>
      <h4 css={css`margin: 0;`}>
        {name}
      </h4>
      <div css={css`padding: 0.5em;`}>
        {!externalBilling && (
          <Link
            to="/billing/options"
            css={theme => css`
              text-decoration: underline;
              color: ${theme.deepOcean} !important;
              font-size: 14px;
            `}
          >
            Change plan
          </Link>
        )}
        {externalBilling && (
          'Please contact Birdi to change your plan'
        )}
      </div>
    </div>
  );
};

interface PriceInfoBlockProps {
  currentPlan: CurrentPlan | null;
  plan: PlanType;
  planInterval: planIntervalType | null;
}

export const PriceInfoBlock: React.FC<PriceInfoBlockProps> = ({ currentPlan, plan, planInterval }) => {
  if (plan === 'free') {
    return (
      <div css={itemsStyle}>
        <span css={css`font-weight: bold;`}>Renewal Fee</span>
        <span>$0</span>
      </div>
    );
  }
  if (currentPlan) {
    return (
      <div css={itemsStyle}>
        <span css={css`font-weight: bold;`}>
          {`Base ${planInterval} fee`}
        </span>
        <span>
          {`${numberToCurrencyStr(currentPlan.monthlyBaseCost, currentPlan.currency, {
            showGST: true,
          })}`}
        </span>
        {currentPlan.status !== 'canceled' && (
          <span css={css`font-size: 0.8em;`}>Your plan renews on</span>
          )}
        {currentPlan.status == 'canceled' && (
          <span css={css`font-size: 0.8em; `}>Your subscription <span css={(theme) => css`color: ${theme.deepOcean} !important;`}>has been cancelled</span> and is active until</span>
        )}
        <span css={css`font-size: 0.8em;`}>
          {format(currentPlan.currentPeriodEnd * 1000, 'dd MMM yyyy')}
        </span>
      </div>
    );
  }
  return (
    <div css={css`${itemsStyle}; padding: 0.5em;`}>
      <span>Billing managed externally</span>
    </div>
  );
};

interface SeatsBlockProps {
  seats: SeatsState;
  externalBilling: boolean;
}

export const SeatsBlock: React.FC<SeatsBlockProps> = ({ seats, externalBilling }) => {
  const [seatsModal, setSeatsModal] = useState(false);

  if (seats && seats.loaded && seats.plan) {
    return (
      <div css={(theme: BirdiTheme) => css`${itemsStyle}; border-right: solid 1px ${theme.mono40};`}>
        <span css={css`font-weight: bold`}>Seats</span>
        <span>
          {seats.unlimitedSeats ? 'Unlimited' : totalSeats(seats)}
        </span>
        {seats.plan === 'growth' && !seats.unlimitedSeats && !externalBilling && <ManageSeatsButton modalOpen={seatsModal} setModalOpen={setSeatsModal} />}
        {(seats.plan === 'free' || seats.plan === 'plus') && 'Upgrade to add more seats'}
      </div>
    );
  }
  return (<div>Loading</div>);
};

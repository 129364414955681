/* global fetch */
import querystring from 'querystring';
import { config } from '../config';
import { headers, stleaf, fetchAPIJSON, SpanTag } from '../helpers';

export const getMissions = (st: SpanTag, opts) => {
  const queryString = querystring.stringify(opts);
  const query = queryString ? `?${queryString}` : '';
  const url = new URL(`${config.API_URL}/v1/organisation/missions/${query}`);
  return fetchAPIJSON(stleaf(st, 'alah5i'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

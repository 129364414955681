import React, { useState } from 'react';
import { Theme } from '@emotion/react';

export const NUMBER_OF_CATEGORIES = 6;
export const KNOWLEDGE_BASE_LINK = 'https://help.birdi.io/en/articles/6695800-asset-assessment';

export interface AssessmentTypeOption {
  label: string;
  value: string;
}
export interface CategoryItem {
  id: string;
  name: string;
  sortKey: string;
}

export interface CategoryContextType {
  selectedAssessmentType: AssessmentTypeOption | null;
  setSelectedAssessmentType: React.Dispatch<React.SetStateAction<AssessmentTypeOption | null>>;
  path: (CategoryItem | {})[];
  defaultPath: (CategoryItem | {})[];
  setPathFragment: (index: number, value: CategoryItem | {}) => void;
  setFullPath: React.Dispatch<React.SetStateAction<(CategoryItem | {})[]>>;
  selectItem: (categoryIndex: number, categoryItem: CategoryItem) => void;
  nameError: boolean;
  setNameError: React.Dispatch<React.SetStateAction<boolean>>;
}

type UseArrayReturnType = [
  value: (CategoryItem | {})[],
  setArray: (index: number, newValue: CategoryItem | {}) => void,
  setValue: React.Dispatch<React.SetStateAction<(CategoryItem | {})[]>>,
];
export function useArray(initialValue: (CategoryItem | {})[]): UseArrayReturnType {
  const [value, setValue] = useState(initialValue);
  function setArray(index: number, newValue: CategoryItem | {}) {
    setValue((prevArray) => {
      const newArray = [...prevArray];
      newArray[index] = newValue;
      return newArray;
    });
  }
  return [value, setArray, setValue];
}

import path from 'pathe';
import mime from 'mime/lite.js';

export function getComponentsFromS3Key(srcKey: string) {
  return {
    key: srcKey,
    path: path.dirname(srcKey),
    filename: path.basename(srcKey),
    ext: /\w+.(\w+)$/.exec(path.basename(srcKey))[1].toLowerCase(),
    mimeType: mime.getType(srcKey),
  };
}

export function getS3ComponentsFromPath(s3path) {
  const s3PathComponents = s3path.slice(1).split('/'); // Remove initial slash first
  const bucket = s3PathComponents[0];
  const key = s3PathComponents.slice(1).join('/'); // eslint-disable-line prefer-template
  return { Bucket: bucket, Key: key };
}

// Regex written by Berners-Lee, et al.
const urlRegex = /^(([^:/?#]+):)?(\/\/([^/?#]*))?([^?#]*)(\?([^#]*))?(#(.*))?/;

export function getS3ComponentsFromUri(s3Uri) {
  const s3Path = urlRegex.exec(s3Uri)[5];
  return getS3ComponentsFromPath(s3Path);
}

// Retrieve an S3 key which potentially has DUMB characters
// in its file name and format it for URL access
export function encodeKey(key) {
  const keyComponents = getComponentsFromS3Key(key);
  // eslint-disable-next-line prefer-template
  return keyComponents.path + '/' + encodeURIComponent(keyComponents.filename);
}

export function httpToS3(httpURL: string): string {
  return httpURL.replace(/^https:\/\/([^.]+)[^\/]*\/(.*)$/, 's3://$1/$2');
}

/**
 * Extracts bucket & key from canonical s3 style URL
 * @param {string} s3URLString e.g. s3://bucket/key/path
 * @returns {object} s3URLObject
 */
function parseS3Key(s3URLString: string) {
  const matches = /s3:\/\/([\w\d\-_]+)\/(.*)/g.exec(s3URLString);
  if (!matches) throw Error(`Failed to pass ${s3URLString}`);
  return {
    bucket: matches[1],
    path: matches[2],
  };
}

/* globals window */

import React, { useEffect, useState } from 'react';
import { stroot, subscription as subscriptionAPI } from '@birdi/js-sdk';
import { css } from '@emotion/react';
import { breakpoints } from '@birdi/theme/variables';
import CurrentPlanSVG from '@birdi/icons/current-plan.svg';
import FreePlanSVG from '@birdi/icons/free-plan-2.svg';
import PlusPlanSVG from '@birdi/icons/plus-plan-2.svg';
import GrowthPlanSVG from '@birdi/icons/growth-plan-2.svg';
import EnterprisePlanSVG from '@birdi/icons/ultimate-plan-2.svg';
import { BirdiTheme } from '@birdi/theme';
import { TabNavigation, SubscriptionTabs } from './tab-navigation';
import { CurrentUsers } from './current-users';
import { boxStyle, itemsStyle } from './styles';
import { useSeatsSelector } from '../seats-reducer';
import { useMetaSelector } from '../../meta/reducer';
import { CurrentPlan } from '../types';
import { PlanInfoBlock, PriceInfoBlock, SeatsBlock } from './plan-blocks';
import { StorageInfo } from './storage-info';
import { PlanChangedModal } from './plan-changed-modal';

// TODO: Improve separation of externally billed vs internally billed component
export const PlanOverview = (): React.ReactElement => {
  const [currentPlan, setCurrentPlan] = useState<CurrentPlan | null>(null);
  const [planChangedModal, setPlanChangedModal] = useState<boolean>(false);
  const [oldPlan, setOldPlan] = useState('');
  const [cancellingPlan, setCancellingPlan] = useState('no');
  const meta = useMetaSelector();
  const seats = useSeatsSelector();

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const sessionId = searchParams.get('session_id');
    const oldPlan = searchParams.get('old_plan');

    if (sessionId) {
      // TODO: This will race with a webhook, but should return 200 regardless
      subscriptionAPI.saveSubscriptionData(stroot('poobu2'), sessionId)
        .then((res) => {
          localStorage.setItem('localOldPlan', oldPlan || 'free');
          window.location.replace(location.pathname);
        });
    } else {
      if (meta.organisation.externalBilling) return;

      subscriptionAPI.getCurrentPlan(stroot('phahc4'))
        .then((res) => {
          setCurrentPlan(res.body);
        })
        .catch(() => {
          // Expecting this to catch during processing preview or when
          // external billing is true, for now
        });
    }
  }, []);

  useEffect(() => {
    let localOldPlan = localStorage.getItem('localOldPlan');
    let cancelPlan = localStorage.getItem('cancelPlan');
    if ((localOldPlan) &&
        (meta.organisation.plan) &&
        ((localOldPlan !== meta.organisation.plan) || (cancelPlan === 'yes') || (localOldPlan === meta.organisation.plan && currentPlan?.status === 'canceled'))
      ) {
      setOldPlan(localOldPlan);
      if (cancelPlan === 'yes') setCancellingPlan(cancelPlan);
      setPlanChangedModal(true);
    }
  }, [meta.organisation.plan || currentPlan?.status]);

  const planIcon = () => {
    switch (seats.plan) {
      case 'free':
        return <FreePlanSVG />;
      case 'plus':
        return <PlusPlanSVG />;
      case 'growth':
        return <GrowthPlanSVG />;
      case 'pro':
        return <GrowthPlanSVG />;
      case 'enterprise':
        return <EnterprisePlanSVG />;
      default:
        return <CurrentPlanSVG />;
    }
  };

  return (
    <div>
      <TabNavigation activeTab={SubscriptionTabs.PlanOverview} />
      <div css={(theme: BirdiTheme) => css`
        border-top: solid 1px ${theme.mono30};
        padding: 1em 1.5em;
      `}
      >
        <h3>Plan Details</h3>
        <div css={boxStyle}>
          <div css={css`
            ${itemsStyle}
            @media (max-width: ${breakpoints.xs}) {
              display: none;
            }
            svg { font-size: 8em; }
          `}
          >
            {planIcon()}
          </div>
          <PlanInfoBlock
            currentPlan={currentPlan}
            plan={meta.organisation.plan}
            externalBilling={meta.organisation.externalBilling}
          />
          <SeatsBlock
            seats={seats}
            externalBilling={meta.organisation.externalBilling}
          />
          <PriceInfoBlock
            currentPlan={currentPlan}
            plan={meta.organisation.plan}
            planInterval={meta.organisation.planInterval}
          />
        </div>

        <h3>Current Users</h3>
        <CurrentUsers />

        <h3>Storage</h3>
        <StorageInfo />
      </div>
      <div css={css`padding: 0.5em 1.5em;`}>
        <h3>Need Help?</h3>
        <p>
          For more information about billing and pricing, visit our
          {' '}
          <span css={(theme: BirdiTheme) => css`
            text-decoration: underline;
            color: ${theme.deepOcean};
          `}
          >
            <a
              href="https://help.birdi.io/en"
              target="_blank"
              rel="noopener noreferrer"
              css={(theme: BirdiTheme) => css`
                color: ${theme.deepOcean};
              `}
            >
              knowledge base
            </a>
          </span>
          {' '}
          or contact us via chat.
        </p>
      </div>
      {planChangedModal && oldPlan && (
        <PlanChangedModal
          open={planChangedModal}
          close={() => setPlanChangedModal(false)}
          oldPlan={oldPlan}
          newPlan={meta.organisation.plan}
          cancelPlan={!!(cancellingPlan === 'yes')}
        />
      )}
    </div>
  );
};

import { stroot } from '@birdi/js-sdk'
import React from 'react';
import AsyncSelect from 'react-select/async';
import { css } from '@emotion/react';
import { organisation as organisationAPI } from '@birdi/js-sdk/src/public';

const Option = (props) => (
  <button
    type="button"
    onClick={() => props.selectOption(props.data)}
    disabled={props.isDisabled}
    css={(theme) => css`
      text-align: left;
      padding: 0.5em;
      margin: 0;
      width: 100%;
      &:disabled {
        background: ${theme.mono30};
      }
      &:hover:not:disabled {
        background: ${theme.mono20};
      }
      &:not(:last-child) {
        border-bottom: 1px solid ${theme.mono20};
      }
    `}
  >
    {console.log()}
    <h4
      css={(theme) => css`margin: 0 0 0.5em 0; color: ${theme.midnight};`}
    >
      {props.data.label}
    </h4>
    <p
      css={(theme) => css`margin: 0; color: ${theme.mono50};`}
    >
      {props.data.description}
    </p>
  </button>
);

export function loadOptions(selected, guestsOnly) {
  return organisationAPI.getRoles(stroot('ohw4ae'))
    .then((response) =>
      response.body.items
        .filter((option) => (option.name !== 'Creator'))
        .map((role) =>
          ({
            label: role.name,
            value: role.id,
            description: role.description,
          })));
}

export default ({
  onChange, defaultValue, placement = 'bottom', isDisabled,
  guestsOnly = false, isAdmin = false,
}) => {
  console.log(guestsOnly);
  return (
    <AsyncSelect
      menuPlacement={placement}
      defaultOptions
      cacheOptions
      components={{ Option }}
      defaultValue={defaultValue}
      onChange={(option) => onChange(option)}
      loadOptions={() => loadOptions(defaultValue, guestsOnly)}
      isOptionDisabled={(options) => {
        // TODO: Add an explicit attribute on the Guest role
        return guestsOnly && options.label !== 'Guest';
      }}
      placeholder="Select role"
      css={css`flex-grow: 1; min-width: 8em;`}
      isDisabled={isDisabled}
      maxMenuHeight={180}
    />
  );
}

import React from 'react';
import { css, Theme } from '@emotion/react';
import {
  planOptions, planTitle, planSubTitle, currentPlanTitle,
  planSection1, planSection2, planSection3, planOptionsColumn,
  planTitleHolder, planSection4,
} from '@birdi/theme/blocks';
import EnterprisePlanSVG from '@birdi/icons/ultimate-plan-2.svg';
import GreenCheckCircleSVG from '@birdi/icons/green-check-circle.svg';
import { useMetaSelector } from '../meta/reducer';
import { changePlanButtonStyle } from './checkout-button';

interface EnterprisePlanOptionProps {
  paymentInterval: 'monthly' | 'annual';
}

export const EnterpriseOption = (props: EnterprisePlanOptionProps): JSX.Element => {
  const meta = useMetaSelector();
  const { paymentInterval } = props;
  const title = 'ultimate';
  const features = [
    'Ground Control Point (GCPs) processing',
    'Volumetric reporting with surveyor sign off',
    'Advanced industry-specific reports',
    'Personalized onboarding',
    'Operational workflows & actions',
    'Enterprise grade security',
    '99.99999% SLA guarantee',
    'Single Sign On (SSO)',
    'Phone support',
  ];

  return (
    <div css={planOptionsColumn}>
      <span className='option-header'>Mid to large businessess</span>
      <div css={planOptions}>
        <div css={planSection1}>
          <EnterprisePlanSVG width="7em" height="7em" />
          <div css={(theme) => planTitleHolder(theme.lightOcean)}>
            <span css={planTitle}>{title}</span>
          </div>
          <span>For mid to large organisations looking to scale and optimize their digital capture and embed multi-unit workflows.</span>
          <span css={css`font-weight: bold`}>As many users as needed</span>
        </div>
        <div css={planSection2}>
          <span css={planSubTitle}>Let&apos;s talk</span>
          <span>Processing included</span>
          <span> </span>
        </div>
        <div css={planSection3}>
          {meta.organisation.plan === 'enterprise' && (
            <div css={currentPlanTitle}>Current plan</div>
          )}
          {meta.organisation.plan !== 'enterprise' && (
            <a
              href="https://www.birdi.io/contact-for-pricing"
              css={changePlanButtonStyle}
            >
              Book a Demo
            </a>
          )}
        </div>
        <div css={planSection4}>
          <span css={css`font-weight: bold; margin-bottom: 1em; text-align: center; min-height: 2.5em`}>
            All the Growth plan features plus:
          </span>
          {features.map((feature) => (
            <div
              key={feature}
              css={css`
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                margin-top: 0.25em;
                span {
                  display: block;
                  text-align: left;
                }
              `}
            >
              <GreenCheckCircleSVG
                css={(theme: Theme) => css`
                  color: ${theme.flora};
                  min-width: 1.25em;
                  width: 1.25em;
                  height: 1.25em;
                  margin-right: 0.5em;
                  margin-top: 0.25em;
                `}
              />
              <span>{feature}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

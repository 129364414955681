import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { itemsTable } from '@birdi/theme/blocks';
import { Asset } from '@birdi/types';
import { assets as assetsAPI } from '@birdi/js-sdk';
import { format, parseISO } from 'date-fns';
import { Pagination } from '@birdi/pagination';
import { BirdiTheme } from '@birdi/theme';

const itemsPerPage = 10;

const columns = [
  {
    title: 'id',
    component: (asset: Asset.Asset) => (
      <td key="orgUniqueId">
        <div>{asset.orgUniqueId}</div>
        <div css={css`font-size: 0.9rem;`}>{asset.id}</div>
      </td>
    ),
  },
  {
    title: 'Name',
    component: (asset: Asset.Asset) => <td key="name">{asset.name}</td>,
  },
  {
    title: 'Created At',
    component: (asset: Asset.Asset) => (
      <td key="createdAt">
        {format(parseISO(asset.createdAt), 'yyyy-MM-dd HH:mm')}
      </td>
    ),
  },
];

export default () => {
  const [start, setStart] = useState(1);
  const [itemsCount, setItemsCount] = useState(null);
  const [items, setItems] = useState(null);

  const getItems = (from) => {
    setStart(from);
    assetsAPI.getList({
      start: from,
      limit: itemsPerPage,
    }).then((res) => {
      setItemsCount(res.body.count);
      setItems(res.body.items);
    });
  };

  useEffect(() => {
    getItems(1);
  }, []);

  if (itemsCount === null || items === null) {
    return <div />;
  }

  if (itemsCount === 0) {
    return (
      <div css={css`padding 1em`}>
        <h1>Assets</h1>
        <span>There are no assets yet</span>
      </div>
    );
  }

  return (
    <div css={css`padding 1em`}>
      <h1>Assets</h1>
      <table css={(theme: BirdiTheme) => css(itemsTable(theme))}>
        <thead>
          <tr>
            {columns.map((col) => <th key={col.title}>{col.title}</th>)}
          </tr>
        </thead>
        <tbody>
          {items.map((asset) => (
            <tr key={asset.id}>
              {columns.map((col) => col.component(asset))}
            </tr>
          ))}
        </tbody>
      </table>
      <section css={() => css`padding: 1em 0; flex-grow: 1;`}>
        <Pagination
          onChange={(currentPage) => getItems(currentPage)}
          itemCount={itemsCount}
          itemsPerPage={itemsPerPage}
          currentPage={start}
        />
      </section>
    </div>
  );
};

import { useSelector } from 'react-redux';

export const AUTHORISING = 'auth/AUTHORISING';
export const AUTHORISE = 'auth/AUTHORISE';
export const ANONYMOUS = 'auth/ANONYMOUS';
export const ERROR = 'auth/ERROR';
export const UPDATE_FIELD = 'auth/UPDATE_FIELD';

type AuthStatus = 'initial' | 'authenticating' | 'authenticated' | 'anonymous' | 'error';

// Authorisation screens state (e.g. login, sign up, password reset)
export interface AuthState {
  status: AuthStatus,
  fname: string;
  lname: string;
  email: string;
  // Password stored in local state
  awaitingEmailVerification: boolean;
  // Reset password props
  pendingCode: boolean;
  code: string;
  // General error (easier to manage than component state)
  error: any;
}

export const initialState: AuthState = {
  status: 'authenticating',
  fname: '',
  lname: '',
  email: '',
  awaitingEmailVerification: false,
  pendingCode: false,
  code: '',
  error: null,
};

export default (state: AuthState = initialState, { type, payload }: {
  type: string;
  payload: Partial<AuthState>;
}): AuthState => {
  switch (type) {
    case AUTHORISING:
      return { ...state, status: 'authenticating' };
    case AUTHORISE:
      return { ...initialState, ...payload, status: 'authenticated' };
    case ANONYMOUS:
      return { ...initialState, status: 'anonymous' };
    case ERROR:
      return { ...state, status: 'error', error: payload };
    case UPDATE_FIELD:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export const useAuthSelector = () =>
  useSelector<{ auth: AuthState }, AuthState>((state) => state.auth);

export type IconsId =
  | "arrow-down"
  | "arrow-left"
  | "arrow-right"
  | "arrow-up"
  | "check-circle"
  | "chevron"
  | "circle"
  | "close"
  | "crosshair"
  | "drag-handle"
  | "edit-new"
  | "eye-off"
  | "eye"
  | "filter"
  | "info"
  | "paperclip"
  | "pie-chart"
  | "search"
  | "settings"
  | "x-circle";

export type IconsKey =
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowUp"
  | "CheckCircle"
  | "Chevron"
  | "Circle"
  | "Close"
  | "Crosshair"
  | "DragHandle"
  | "EditNew"
  | "EyeOff"
  | "Eye"
  | "Filter"
  | "Info"
  | "Paperclip"
  | "PieChart"
  | "Search"
  | "Settings"
  | "XCircle";

export enum Icons {
  ArrowDown = "arrow-down",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  ArrowUp = "arrow-up",
  CheckCircle = "check-circle",
  Chevron = "chevron",
  Circle = "circle",
  Close = "close",
  Crosshair = "crosshair",
  DragHandle = "drag-handle",
  EditNew = "edit-new",
  EyeOff = "eye-off",
  Eye = "eye",
  Filter = "filter",
  Info = "info",
  Paperclip = "paperclip",
  PieChart = "pie-chart",
  Search = "search",
  Settings = "settings",
  XCircle = "x-circle",
}

export const ICONS_CODEPOINTS: { [key in Icons]: string } = {
  [Icons.ArrowDown]: "61697",
  [Icons.ArrowLeft]: "61698",
  [Icons.ArrowRight]: "61699",
  [Icons.ArrowUp]: "61700",
  [Icons.CheckCircle]: "61701",
  [Icons.Chevron]: "61702",
  [Icons.Circle]: "61703",
  [Icons.Close]: "61704",
  [Icons.Crosshair]: "61705",
  [Icons.DragHandle]: "61706",
  [Icons.EditNew]: "61707",
  [Icons.EyeOff]: "61708",
  [Icons.Eye]: "61709",
  [Icons.Filter]: "61710",
  [Icons.Info]: "61711",
  [Icons.Paperclip]: "61712",
  [Icons.PieChart]: "61713",
  [Icons.Search]: "61714",
  [Icons.Settings]: "61715",
  [Icons.XCircle]: "61716",
};

import React from 'react';
import { css, Theme } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { breakpoints } from '@birdi/theme';
// import SideNavigation from '../common/SideNavigation';
import SideNavigationDashboard from '../common/SideNavigationDashboard';
import { CloudHeaderV2 } from '../common/HeaderV2';
import { useMetaSelector } from '../meta/reducer';

export const layoutCSS = (theme: Theme) => css`
  @media (min-width: ${breakpoints.m}) {
    background-color: ${theme.sand};
    display: grid;
    grid-template-areas:
      "header  header"
      "sidebar content";
    grid-template-columns: 260px 1fr;
    height: 100vh;
    .header-wrapper {
      grid-area: header;
    }
    .sidebar-wrapper {
      grid-area: sidebar;
      margin: 0 10px 10px 10px;
      background-color: white;
      border-radius: 15px;
      overflow: hidden;
    }
    .content-wrapper {
      grid-area: content;
      min-width: 0;
      overflow-y: auto;
      background-color: white;
      border-radius: 15px;
      margin: 0 10px 10px 0;
    }
  }
`;

export const SidebarLayoutDashboard: React.FC = ({ children }) => {
  const meta = useMetaSelector();
  const location = useLocation();
  return (
    <main css={layoutCSS}>
      <div className="header-wrapper">
        <CloudHeaderV2 />
      </div>
      <div className="sidebar-wrapper">
        <SideNavigationDashboard />
      </div>
      <div className="content-wrapper">
        {children}
      </div>
    </main>
  );
};

import { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import { pageDescription } from '@birdi/theme/blocks';
import CredentialsCard from '@birdi/credentials/Card';
import AddModal from '@birdi/credentials/AddModal';
import { uploadOne, fileToUppyOpts } from '@birdi/uppy-s3';
import {
  add as addCredentials,
  edit as editCredentials,
  get as getCredentials,
  remove as deleteCredentials,
  useCredentialsSelector,
} from './credentials-reducer';
import { useDispatch } from 'react-redux';
import { CredentialsType } from '@birdi/js-sdk/src/public/credentials';
import { BirdiTheme } from '@birdi/theme';

export const Credentials = () => {
  const credentials = useCredentialsSelector();
  const dispatch = useDispatch();
  const [state, setState] = useState<{ open: boolean, item: CredentialsType | null }>({ open: false, item: null });

  useEffect(() => {
    getCredentials(dispatch);
  }, []);

  const setOpen = (open: boolean) => setState({ open, item: null });

  const editModal = (credentials: CredentialsType) => {
    setState({ item: credentials, open: true });
  };

  const addCredential = async (values) => {
    const attrs = { ...values };
    if (values.upload) {
      // TODO: Convert to s3 style url;
      const upload = await uploadOne({ ...fileToUppyOpts(values.upload) });
      attrs.upload = upload.s3Url;
      attrs.uploadTitle = values.upload.name;
    }
    addCredentials(dispatch, attrs);
    setState((prevState) => ({ ...prevState, open: false }));
  };
  const editCredential = async (values) => {
    const attrs = { ...values };
    attrs.uploadTitle = state.item.uploadTitle;
    // Upload exists
    if (values.uploadHasChanged === true && values.upload) {
      const upload = await uploadOne({ ...fileToUppyOpts(values.upload) });
      attrs.upload = upload.s3Url;
      attrs.uploadTitle = values.upload.name;
    }
    // Upload has been removed
    if (values.uploadHasChanged === true && !values.upload) {
      attrs.upload = '';
      attrs.uploadTitle = '';
    }
    // Upload hasn't changed
    if (!values.uploadHasChanged) {
      delete attrs.upload, delete attrs.uploadTitle;
    }
    delete values.uploadHasChanged;
    if (state.item && (values.expiryDate !== state.item.expiryDate
      || values.upload !== state.item.upload
      || values.number !== state.item.number)) {
      editCredentials(dispatch, values.id, attrs);
    }
    setState((prevState) => ({ ...prevState, open: false }));
  };

  return (
    <div>
      <h2 css={css`margin: 1em`}>Credentials</h2>
      <div css={pageDescription}>
        <p>Add, update and manage your credentials.</p>
        <div>
          <a
            href="http://help.birdi.io/en/articles/6648077-how-do-i-add-and-manage-my-credentials"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn how to add your credentials</span>
          </a>
          <a
            href="https://help.birdi.io/en/articles/6648063-getting-started-as-a-pilot"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BookOpenSVG />
            <span>Learn how to become a verified Birdi pilot</span>
          </a>
        </div>
      </div>
      <div css={css`margin: 1.5em`} />
      <AddModal
        item={state.item}
        open={state.open}
        setOpen={setOpen}
        addCredential={addCredential}
        editCredential={editCredential}
        credentialsList={credentials}
      />
      <p css={css`max-width: 48rem; padding: 0 1.5em`}>
        Birdi receives requests for aerial capture &amp; survey around Australia. We require pilots to have qualifications relevant to the type of drone they are operating and the area in which the drone is operating. A valid Public Liability insurance certificate of $20 million is also required.
      </p>
      <div css={(theme: BirdiTheme) => css`border-top: solid 1px ${theme.mono30}; padding: 0 1.5em`}>
        <ul
          css={css`
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            list-style-type: none;
            padding: 0;
            width: calc(100% + 1rem);
          `}
        >
          {credentials.items.map((credential) => (
            <CredentialsCard
              key={credential.id}
              credential={credential}
              deleteCredential={deleteCredentials}
              editCredential={editModal}
            />
          ))}
        </ul>
        {!credentials.items.length && (<p>No credentials found</p>)}
      </div>
    </div>
  );
};

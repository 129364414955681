/* global fetch */

import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag
} from '../helpers';

export interface AssessmentType {
  id: string;
  name: string;
  organisationId: string;
  organisation: { id: string, name: string };
  archived: boolean;
  categories: { id: string, name: string, sortKey: string }[];
  createdAt: string;
}

export interface AssessmentTypeCategory {
  id: string;
  typeId: string;
  sortKey: string;
  archived: boolean;
  children: { id: string, name: string, sortKey: string }[];
  level: number;
  path: string
  createdAt: string;
}

export const getAssessmentTypeList = (st: SpanTag, opts?): Promise<APIResponse<AssessmentType[]>> => {
  let url = new URL(`${config.API_URL}/v1/assessment-types`);
  const searchParams = new URLSearchParams(opts);
  url.search = searchParams.toString();
  return fetchAPI(stleaf(st, 'ooz6ga'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const getAssessmentTypeById = (st: SpanTag, id: string): Promise<APIResponse<AssessmentType>> => {
  const url = `${config.API_URL}/v1/assessment-types/${id}`;
  return fetchAPI(stleaf(st, 'wae5zi'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const createAssessmentType = (st: SpanTag, fields): Promise<APIResponse<{ id: string, name: string }>> => {
  const url = `${config.API_URL}/v1/assessment-types`;
  return fetchAPI(stleaf(st, 'eaj4co'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const getCategoryById = (st: SpanTag, id: string): Promise<APIResponse<AssessmentTypeCategory>> => {
  const url = `${config.API_URL}/v1/assessment-types/category/${id}`;
  return fetchAPI(stleaf(st, 'va2kiw'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const createCategory = (st: SpanTag, typeId: string, fields): Promise<APIResponse<Partial<AssessmentTypeCategory>>> => {
  const url = `${config.API_URL}/v1/assessment-types/${typeId}`;
  return fetchAPI(stleaf(st, 'oob0xu'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const updateCategory = (st: SpanTag, id: string, fields): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/assessment-types/category/${id}`;
  return fetchAPI(stleaf(st, 'eec2ie'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

export const deleteCategory = (st: SpanTag, categoryId: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v1/assessment-types/category/${categoryId}`;
  return fetchAPI(stleaf(st, 'eixae0'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

export interface UploadCsvProps {
  name: string,
  categories: string,
}

export const uploadAssessmentCSV = (st: SpanTag, fields: UploadCsvProps): Promise<APIResponse<{ id: string, name: string }>> => {
  const url = `${config.API_URL}/v1/assessment-types/upload-csv`;
  return fetchAPI(stleaf(st, 'aik5ii'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(fields),
  });
};

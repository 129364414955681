import React from 'react';
import { css } from '@emotion/react';
import { BirdiTheme, breakpoints, fonts } from '@birdi/theme';

export const SideBar = ({ children }) => (
  <div css={(theme: BirdiTheme) => css`
      overflow-y: auto;
      font-family: ${fonts.display};
      position: relative;
      display: none;
      box-sizing: border-box;
      flex-shrink: 0;
      z-index: 2;
      width: 100%;
      height: calc(100vh - 80px);
      padding: 1em;
      padding-bottom: 3.5em;
      .item {
        display: flex;
        width: 100%;
        gap: 1rem;
        color: ${theme.displayFont};
        padding: 0.5em;
        font-size: 0.9375em;
        align-items: center;
        border-radius: 0.625em;
        box-sizing: border-box;
        .icon-chevron {
          margin-left: auto;
        }
        svg {
          font-size: 1.5em;
        }
        .icon-external {
          font-size: 1em;
        }
        &.selected {
          color: ${theme.deepOcean};
        }
        &:hover {
          background-color: ${theme.mono20};
        }
        &.main {
          font-size: 1em;
          font-weight: 700;
        }
        &.sub {
          padding-left: 3rem;
        }
        &.link-disabled {
          pointer-events: none;
          color: ${theme.mono40};
        }
      }
      .divider {
        border-top: 2px solid ${theme.mono20};
      }
      @media (min-width: ${breakpoints.m}) {
        display: block;
      }
    `}
  >
    {children}
  </div>
);

import React from 'react';
import { css } from '@emotion/react';
import ShieldSVG from '@birdi/icons/shield.svg';
import { themeLight } from '@birdi/theme/theme';

const statusMap = {
  active: {
    withPilot: {
      label: 'In Progress',
      color: '#2287FA',
      background: '#EBF4FF',
    },
    withoutPilot: {
      label: 'Pending',
      color: '#FC7543',
      background: '#FEF0EB',
    },
  },
  completed: {
    label: 'Completed',
    color: '#4AD294',
    background: '#EFFBF5',
  },
  cancelled: {
    label: 'Cancelled',
    color: '#D24A4A',
    background: '#FFE7F0',
  },
};

function getProp(status: string, attr, pilot: boolean) {
  switch (status) {
    case 'active':
      if (!pilot) { return statusMap.active.withoutPilot[attr]; }
      return statusMap.active.withPilot[attr];
    case 'completed':
      return statusMap.completed[attr];
    case 'cancelled':
      return statusMap.cancelled[attr];
    default:
      return false;
  }
}

export const MarketplaceStatus = ({ status, pilot }) => {
  if (!status) return <></>;
  return (
    <div css={css`
      display: inline-block;
      width: 8rem;
      padding: 0.5rem;
      border-radius: 2px;
      white-space: nowrap;
      background: ${getProp(status, 'background', pilot)};
      color: ${getProp(status, 'color', pilot)};
      font-weight: 700;
      `}
    >
      <span data-testid="status">
        {getProp(status, 'label', pilot)}
      </span>
    </div>
  );
};

export const InsuranceBadge = () => (
  <div css={(theme) => css`margin-top: auto; color: ${theme.mono70}; white-space: nowrap;`}>
    <ShieldSVG css={css`position: relative; top: 2px; margin-right: 2px;`} />
    <span>Insured Mission</span>
  </div>
);

export const ClientBadge = ({ client }) => (
  <div>
    {`Posted by: ${client.fname} ${client.lname}`}
  </div>
);

export const PilotBadge = ({ pilot }) => (
  <div>
    {`Pilot: ${pilot.fname} ${pilot.lname}`}
  </div>
);

import React from 'react';
import { format, parse, isToday } from 'date-fns';
import { Jobs } from '@birdi/types';
import { PreferredDate } from '@birdi/types/jobs';

function formatDateString(isoString) {
  try {
    return format(parse(isoString, 'yyyy/MM/dd', new Date()), 'dd MMM yyyy');
  } catch (err) {
    // TODO: Catch this
    console.log(err);
    return '';
  }
}

export const preferredDate = (dateRange: Jobs.PreferredDate): string => {
  // Need !dateRange in order to support legacy marketplace missions
  if (!dateRange || (dateRange && dateRange.length === 0)) return 'ASAP';
  if (dateRange.length === 1) {
    return formatDateString(dateRange[0]);
  }
  if (!Array.isArray(dateRange)) {
    return formatDateString(dateRange);
  }
  return `${formatDateString(dateRange[0])} - ${formatDateString(dateRange[1])}`;
};

export const preferredDate2 = (dateRange: Jobs.PreferredDate): string => {
  if (typeof dateRange === 'string') {
    return formatDateString(dateRange);
  }
  if (Array.isArray(dateRange)) {
    if (dateRange.length === 1) {
      return formatDateString(dateRange[0]);
    }

    if (dateRange.length === 2) {
      return `${formatDateString(dateRange[0])} - ${formatDateString(dateRange[1])}`;
    }
  }
  return '';
};

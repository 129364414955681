/* global fetch */

import { config } from '../config';
import { headers, stleaf, fetchAPI, SpanTag } from '../helpers';

export const getContext = (st: SpanTag, id: string): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/share/mission/${id}`;
  return fetchAPI(stleaf(st, 'eigh7u'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

interface AddSharedUserOpts {
  userScopeId: string,
  role: string,
  email?: string, // TODO: email and userScopeId are exclusive!
  readOnly?: boolean,
  message?: string,
}

export const addUser = (st: SpanTag, id: string, options: AddSharedUserOpts): Promise<APIResponse> => {
  const url = `${config.API_URL}/v2/share/mission/${id}`;
  return fetchAPI(stleaf(st, 'otah1a'), url, {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify(options),
  });
};

export const updateRole = (st: SpanTag, id: string, obj: any): Promise<APIResponse>=> {
  const url = `${config.API_URL}/v2/share/mission/${id}/role`;
  return fetchAPI(stleaf(st, 'aqu5ke'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(obj),
  });
};

export const deleteUser = (st: SpanTag, id: string) => {
  const url = `${config.API_URL}/v2/share/mission/${id}`;
  return fetchAPI(stleaf(st, 'eec5ea'), url, {
    method: 'DELETE',
    credentials: 'include',
    headers,
  });
};

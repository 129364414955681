import { stroot, organisation as organisationAPI } from '@birdi/js-sdk';
import React from 'react';
import AsyncSelect from 'react-select/async';
import { reactSelectLight } from '@birdi/theme/blocks';

export function loadOptions(scope) {
  return organisationAPI.getRoles(stroot('quae3o'), { scope })
    .then((response) =>
      response.body.items.map((option) =>
        ({
          label: `${option.name} (${option.id})`,
          value: option.id,
        })));
}

export default ({
  onChange, scope = 'organisation',
  inputId, theme = reactSelectLight,
}) => (
  <AsyncSelect
    inputId={inputId}
    isClearable
    defaultOptions
    cacheOptions
    onChange={(option) => onChange(option ? option.value : null)}
    loadOptions={() => loadOptions(scope)}
    theme={theme}
    placeholder="Select role"
  />
);

/**
 *
 * @deprecacted Use context-menu-2
 */

import React from 'react';
import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';
import DropDownSVG from '@birdi/icons/more-horizontal.svg';
import { ContextMenuHOC } from './hoc';
import { overlayMenuContainerCSS } from '../theme/blocks';
import { themeLight } from '../theme/theme';

function DefaultButtonComponent({
  toggle, toggleRef, visible,
}) {
  return (
    <button
      type="button"
      css={(theme) => css`
        display: flex;
        align-items: center;
        border-radius: 2px;
        width: 2rem;
        height: 2rem;
        ${visible ? 'opacity: 1;' : 'opacity: 0;'}
        margin-left: 0.5em;
        &:hover {
          background: ${theme.mono20};
        }
      `}
      ref={toggleRef}
      onClick={toggle}
    >
      <DropDownSVG color="#777" />
    </button>
  );
}

export const contextStyles = (theme) => `
min-width: 6em;
background: ${themeLight.mono0};
button, a {
  color: ${themeLight.mono98};
  display: flex;
  text-align: left;
  width: 100%;
  padding: 1rem;
  transition: background 0.2s;
  margin: 0;
  &:hover {
    color: ${themeLight.mono98};
    background: ${themeLight.mono20};
  }
}
`;

/**
 *
 * @deprecacted Use context-menu-2
 */
const Internal = (props) => (
  <div css={css(overlayMenuContainerCSS)}>
    <props.ButtonComponent
      toggleRef={props.toggleRef}
      toggle={props.toggle}
      open={props.open}
      visible={props.visible}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
        props.toggle()
      }}
    />
    <CSSTransition
      in={props.open}
      timeout={150}
      classNames="drop-menu"
      unmountOnExit
    >
      <div
        css={(theme) => css`
          position: absolute;
          display: flex;
          flex-direction: column;
          z-index: 2;
          ${props.width ? `width: ${props.width};` : ''}
          ${props.alignRight ? 'right: 0;' : 'left: 0;'}
          top: calc(100% + 0.25rem);
          color: ${theme.mono50};
          box-shadow: rgba(9, 30, 66, 0.1) 0px 1px 1px,
            rgba(9, 30, 66, 0.16) 0px 0px 1px 0px;
          border-radius: 3px;
          overflow: hidden;
          .item {
            padding: 1rem 1rem 1rem 0.3rem;
            white-space: nowrap;
            text-align: left;
            border-bottom: 1px solid rgba(9, 30, 66, 0.16);
          }
          `}
        ref={props.wrapperRef}
      >
        {props.children(props)}
      </div>
    </CSSTransition>
  </div>
);

Internal.defaultProps = {
  visible: true,
  ButtonComponent: DefaultButtonComponent,
};

/**
 *
 * @deprecacted Use context-menu-2
 */
export const ContextMenu = ContextMenuHOC(Internal);

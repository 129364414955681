import { stroot, user } from '@birdi/js-sdk';

export const FETCHING = 'account/FETCHING';
export const UPDATE_FIELD = 'account/UPDATE_FIELD';
export const UPDATE_PROFILE_FIELD = 'account/UPDATE_PROFILE_FIELD';
export const UPDATING = 'account/UPDATING';
export const UPDATED = 'account/UPDATED';
export const FETCH = 'account/FETCH';
export const FETCH_ERROR = 'account/FETCH_ERROR';
export const GET_EXPERIENCE = 'account/GET_EXPERIENCE';

export const initialState = {
  loaded: false,
  id: '',
  fname: '',
  lname: '',
  phone: '',
  email: '',
  bio: '',
  assetTags: '',
  pilot: true,
  experience: [],
  software: [],
  profilePic: '',
  coverPic: '',
  organisation: null,
  jobNotifications: false,
  platform: null,
  insured: false,
  company: '',
  flyDrones: 'DontFly',
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCHING:
      return { ...initialState, loaded: false };
    case FETCH:
      return { ...state, ...payload, loaded: true };
    case FETCH_ERROR:
      return { ...payload, loaded: false };
    case UPDATE_FIELD:
      return { ...state, ...payload, loaded: true };
    case UPDATE_PROFILE_FIELD:
      return { ...state, ...payload, loaded: true };
    case GET_EXPERIENCE: {
      const experience = payload.map((item) => (
        { value: item.itemId, label: item['experience.name'] }
      ));
      return { ...state, experience };
    }
    default:
      return state;
  }
};

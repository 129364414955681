import React, { useState } from 'react';
import { QueryClient, QueryClientProvider, useInfiniteQuery } from 'react-query';
import { stroot, sites as sitesAPI } from '@birdi/js-sdk';
import { buttonBlue, buttonOutlineBlue, BirdiTheme } from '@birdi/theme';
import {
  cardList, headerContainer, listContainer, pageDescription, input,
} from '@birdi/theme/blocks';
import BookOpenSVG from '@birdi/icons/book-open.svg';
import PlayCircleSVG from '@birdi/icons/play-circle.svg';
import PlusSVG from '@birdi/icons/plus.svg';
import { css } from '@emotion/react';
import { NewSiteModal } from './NewSiteModal';
import { SiteCard } from './SiteCard';
import { INTERCOM_TOUR_CODES } from '../common/consts';

const queryClient = new QueryClient();

const LOAD_AMOUNT = 12;

function SitesList() {
  const [search, setSerach] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage } = useInfiniteQuery(
    ['sitesData', search],
    ({ pageParam = 1 }) => {
      return sitesAPI.searchSites(stroot('kaj6or'), {
        start: pageParam,
        limit: LOAD_AMOUNT,
        ...(search && { search }),
      }).then((res) => res.body?.sites || []);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.length === LOAD_AMOUNT ? allPages.length + 1 : undefined;
      },
      keepPreviousData: true,
    }
  );

  const handleLoadMore = () => {
    fetchNextPage();
  };

  const refreshSites = () => {
    queryClient.invalidateQueries(['sitesData', search]);
  };

  const updateSearchParams = (search) => {
    setSerach(search);
    queryClient.invalidateQueries(['sitesData', search]);
  };

  return (
    <div>
      <div>
        <div css={css`${headerContainer}; margin: 0; flex-direction: row`}>
          <input
            type="text"
            value={search || undefined}
            placeholder="Search sites by name ..."
            css={(theme: BirdiTheme) => css`${input(theme)}; width: 20em`}
            onChange={(event) => {
              event.preventDefault();
              updateSearchParams(event.target.value);
            }}
          />
          <button css={buttonBlue} onClick={() => setAddModalOpen(true)}>
            Create a Site
            <PlusSVG css={css`margin-left: 1rem;`}/>
          </button>
        </div>
        <div css={listContainer}>
          <div css={css`${cardList}; gap: 1rem;`}>
            {data && data.pages.map((pageData) => (
              pageData.map((site) => (
                <SiteCard
                  key={site.id}
                  {...site}
                  refreshSites={refreshSites}
                />
              ))
            ))}
          </div>
          {hasNextPage ? (
            isFetchingNextPage ? (
              <span>Loading more...</span>
            ) : (
              <button
                css={(theme) => css`${buttonOutlineBlue(theme)}; width: 100%;`}
                onClick={handleLoadMore}
              >
                Load More ...
              </button>
            )
          ) : null}
          {data && data.pages[0].length === 0 && (
            <p css={css`width: calc(100% - 1rem);`}>No sites found</p>
          )}
        </div>
      </div>
      <NewSiteModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
        refreshSites={refreshSites}
      />
    </div>
  )
}

export const Sites = () => (
  <div>
    <h2 css={css`margin: 1em`}>All Sites</h2>
    <div css={pageDescription}>
      <p>These are sites you have created.</p>
      <div>
        {/* TODO: swap for correct info */}
        <button
          type="button"
          onClick={() => {
            window?.Intercom?.('startTour', INTERCOM_TOUR_CODES.CREATE_SITE);
          }}
        >
          <PlayCircleSVG />
          <span>Take a quick tour on creating a site</span>
        </button>
        <a
          href="https://help.birdi.io/en/articles/9006888-how-to-create-a-site"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BookOpenSVG />
          <span>How to create a site</span>
        </a>
      </div>
    </div>
    <QueryClientProvider client={queryClient}>
      <SitesList />
    </QueryClientProvider>
  </div>
)

/* global zxcvbn */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BirdiLogoSVG from '@birdi/icons/birdi-logo-navy.svg';
import { css } from '@emotion/react';
import queryString from 'querystring';
import { formStyles } from '@birdi/theme/blocks';
import { BirdiTheme, buttonBlue } from '@birdi/theme';
import ErrorDisplay from '../common/ErrorDisplay';
import {
  updateField, resetPassword,
  sendPasswordResetCodeToEmail,
} from './actions';
import EnterCode from './EnterCode';
import { getZxcvbnResult } from './resetpasswordutils';

const EnterEmail = ({
  form, onSubmit, _updateField,
  email, error,
}) => (
  <form
    ref={form}
    onSubmit={onSubmit}
    css={(theme: BirdiTheme) => css(formStyles(theme))}
  >
    <div>
      <label htmlFor="email">
        Email
        <input
          id="email"
          type="email"
          value={email}
          onChange={(event) => _updateField({ email: event.target.value })}
          required
        />
      </label>
    </div>
    <ErrorDisplay error={error} path="email" />
    <input
      type="submit"
      css={(theme: BirdiTheme) => css`${buttonBlue(theme, { baseSize: 'md', fullWidth: true })}; font: inherit;`}
      value="Reset Password"
    />
    <ErrorDisplay error={error} />
  </form>
);

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      zxcvbnResult: null,
    };
    this.form = React.createRef();
  }
  componentWillUnmount() {
    const { _updateField } = this.props;
    this.setState(() => ({ password: '' }));
    _updateField({
      pendingCode: false,
      code: '',
      error: null,
    });
  }
  setPassword = (value) => {
    const { auth } = this.props;
    if (typeof zxcvbn === 'function') {
      const zxcvbnResult = getZxcvbnResult(value, auth.email, auth.name);
      this.setState(() => ({ zxcvbnResult }));
    }
    this.setState({ password: value });
  };
  handleEmailSubmit = (event) => {
    const { _sendPasswordResetCodeToEmail, auth } = this.props;
    event.preventDefault();
    if (!this.form.current.checkValidity()) {
      return this.forceUpdate.current.reportValiditity();
    }
    return _sendPasswordResetCodeToEmail(auth.email);
  };
  handlePasswordReset = (event) => {
    event.preventDefault();
    const { password } = this.state;
    const { auth, _resetPassword, location } = this.props;
    const newSearch = location.search.replace('?', '');
    const queryObject = queryString.parse(newSearch);
    const { id } = queryObject;
    const code = queryObject.code ? queryObject.code : auth.code;
    if (!this.form.current.checkValidity()) return this.form.current.reportValidity();
    return _resetPassword({
      id: auth.codeId,
      code,
      password,
    });
  };
  render() {
    const { auth, _updateField, location } = this.props;
    const { password, zxcvbnResult } = this.state;
    // Initial state: Enter email
    if (!auth.pendingCode && !location.search) {
      return (
        <div>
          <div css={css`text-align: center; margin-bottom: 1rem;`}>
            <BirdiLogoSVG css={css`width: 7em; height: 5em;`} />
          </div>
          <h1 css={css`text-align: center;`}>
            Reset Password
          </h1>
          <p>
            Enter your email address below and we&#8217;ll send you a code to reset your password.
          </p>
          <EnterEmail
            form={this.form}
            email={auth.email}
            error={auth.error}
            _updateField={_updateField}
            onSubmit={this.handleEmailSubmit}
          />
        </div>
      );
    }
    // State 2: Enter code and new password
    return (
      <div>
        <div css={css`text-align: center; margin-bottom: 1rem;`}>
          <BirdiLogoSVG css={css`width: 7em; height: 5em;`} />
        </div>
        <h1 css={css`text-align: center;`}>
          Reset Password
        </h1>
        {!location.search && (
        <p>
          We&#8217;ve sent an email to
          {` ${auth.email}. `}
          Enter the code below and your new password.
        </p>
        )}
        {location.search && (
          <p>Enter your new password.</p>
        )}
        <EnterCode
          form={this.form}
          auth={auth}
          _updateField={_updateField}
          onSubmit={this.handlePasswordReset}
          setPassword={this.setPassword}
          password={password}
          zxcvbnResult={zxcvbnResult}
          search={location.search}
          label="Enter new password"
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    auth: state.auth,
  }),
  (dispatch) => ({
    _updateField: (field) => dispatch(updateField(field)),
    _sendPasswordResetCodeToEmail: (email) => dispatch(sendPasswordResetCodeToEmail(email)),
    _resetPassword: (obj) =>
      dispatch(resetPassword(obj)),
  }),
)(withRouter(ResetPassword));

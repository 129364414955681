import { config } from '../config';
import {
  headers, APIResponse, stleaf, fetchAPI, SpanTag,
} from '../helpers';

export interface OrganisationSeverityScale {
  organisationId: string;
  level: number;
  label: string;
  colour: string;
}

export interface SeverityScaleInput {
  label: string;
  colour: string;
}

export const getAll = (st: SpanTag): Promise<APIResponse<{
  severities: OrganisationSeverityScale[]
}>> => {
  const url = `${config.API_URL}/v1/organisation/severities`;
  return fetchAPI(stleaf(st, 'eesh1s'), url, {
    method: 'GET',
    credentials: 'include',
    headers,
  });
};

export const update = (st: SpanTag, input: SeverityScaleInput[]): Promise<APIResponse<null>> => {
  const url = `${config.API_URL}/v1/organisation/severities`;
  return fetchAPI(stleaf(st, 'abom7o'), url, {
    method: 'PUT',
    credentials: 'include',
    headers,
    body: JSON.stringify(input),
  });
};

import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { css } from '@emotion/react';
import { formatName } from '@birdi/utils/src/text'
import {
  stroot, organisationUsers as organisationUsersAPI,
} from '@birdi/js-sdk/src/public';

const Option = ({ ...props }) => {
  const user = props.data.data;
  return (
    <components.Option {...props}>
      <div>{formatName(user.fname, user.lname)}</div>
      <div css={theme => css`color: ${theme.mono70};`}>
        {user.email}
      </div>
    </components.Option>
  );
}

const SingleValue = ({ ...props }) => {
  const user = props.data;
  return (
    <components.SingleValue {...props}>
      <div css={css`div { margin: 0.25em 0; }`}>
        <div>{formatName(user.fname, user.lname)}</div>
        <div css={theme => css`color: ${theme.mono70};`}>
          {user.email}
        </div>
      </div>
    </components.SingleValue>
  );
};

export function OrganisationUsersSelect({
  onChange, isLoading, theme, styles,
  isDisabled = false, value
}) {
  const [organisationUsers, setOrganisationUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [user, setUserData] = useState(null);

  useEffect(() => {
    organisationUsersAPI.getCurrentOrganisationUsers(stroot('anz5ai'), searchTerm)
      .then((res) => {
        let orgUsers = res.body.items;
        const formattedUser = orgUsers.map((orgUser) => {
          return ({
            label: formatName(orgUser.fname, orgUser.lname),
            value: orgUser.scopeId,
            data: orgUser,
          });
        });
        setOrganisationUsers(formattedUser);
      });
  }, [searchTerm]);
  return (
    <Select
      value={value}
      onChange={(val, action) => {
        setUserData(val)
        onChange(val, action)
      }}
      options={organisationUsers}
      isClearable
      isSearchable
      filterOption={null}
      onInputChange={(inputValue) => setSearchTerm(inputValue)}
      isLoading={isLoading}
      placeholder="Search users"
      valueKey="id"
      labelKey="label"
      theme={theme}
      css={css`min-width: 18rem; font-weight: normal`}
      styles={{
        valueContainer: (base) => ({
          ...base,
          height: '4em',
        }),
        ...styles
      }}
      components={{
        SingleValue,
        Option,
      }}
      isDisabled={isDisabled}
    />
  );
}

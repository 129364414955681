import React, { useState } from 'react';
import { Link, LinkProps, withRouter } from 'react-router-dom';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import BirdiIcon, { Icons } from '@birdi/icons';
import CompanySVG from '@birdi/icons/2022-company-info.svg';
import HelpSVG from '@birdi/icons/2022-help.svg';
import HomeSVG from '@birdi/icons/2022-home.svg';
import InspectionSVG from '@birdi/icons/2022-inspection.svg';
import MissionsSVG from '@birdi/icons/2022-mission.svg';
import ExternalLinkSVG from '@birdi/icons/external-link.svg';
import SitesSVG from '@birdi/icons/sites.svg';
import BillingSVG from '@birdi/icons/2022-billing-subscription.svg';
import SettingsSVG from '@birdi/icons/settings-2.svg';
import { FeatureFlagNames } from '@birdi/js-sdk/src/public/organisation-features';
import { BirdiTheme } from '@birdi/theme';
import { overlayMenuContainerCSS } from '@birdi/theme/blocks';
import { hasPermission } from '@birdi/utils/src/helpers';
import { css } from '@emotion/react';
import { PoweredByBirdi } from '@birdi/branding/powered-by-birdi';
import { SideBar } from './side-bar';
import { useMetaSelector } from '../meta/reducer';
import BackSVG from '@birdi/icons/back.svg';

export const TitleHolder = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.75em;
  padding: 0.75em 0.25em;
`;
export const iconStyle = css`
  width: 1em;
  height: 1em;
`;
export const TitleStyle = css`
  font-weight: bold;
`;
export const poweredByBirdiStyle = (theme: BirdiTheme) => css`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2.5em;
  z-index: 3;
  background-color: ${theme.mono0};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BackButton = ({ label, id } : { label: string, id: string }) => (
  <>
    <Link
      css={(theme) => css`
        padding: 0;
        display: flex;
        align-items: center;
        color: inherit;
        padding: 0.5rem 0.5rem;
        border-radius: 5px;
        font-weight: bold;
        &:hover {
          background: ${theme.lightOcean};
        }
      `}
      to="/"
    >
      <BackSVG css={css`margin-right: 1em;`} />
      <span id={id}>{label}</span>
    </Link>
  </>
);

function SidebarLink({
  to, match, children, missions = false, className = '', id = null,
}) {
  const otherProps: Partial<LinkProps> = {};
  if (id) otherProps.id = id;
  return (
    <Link
      className={cx(className, { selected: to === match && !missions })}
      to={to}
      {...otherProps}
    >
      {children}
    </Link>
  );
}

const SideNavigation = withRouter(({ history }) => {
  const meta = useMetaSelector();
  // console.log('meta side nav: ', meta);
  const [expandedMenuItem, setExpandedMenuItem] = useState({
    missions: false,
    inspection: false,
    processing: false,
    companyInfo: false,
    billing: false,
    settings: false,
    help: false,
  });
  const manageUsers = meta.organisation && hasPermission(meta.permissions, 'org:manage-users');
  const inspection = meta.orgFeatureFlags.includes(FeatureFlagNames.PowercorInspectionDashboard)
    && meta.permissions.includes('org:manage-missions');
  const costCalculator = meta.orgFeatureFlags.includes(FeatureFlagNames.GigapixelCalculator);
  const customBranding = meta.orgFeatureFlags.includes(FeatureFlagNames.CustomBranding);
  const hasProcessing = meta.organisation.processingFlavour !== 'none';
  const canViewProcessing = hasPermission(meta.permissions, 'org:manage-missions') && hasProcessing;
  const sites = meta.orgFeatureFlags.includes(FeatureFlagNames.Sites);
  return (
    <div css={css`position: relative;`}>
      <SideBar>
        <SidebarLink to="/" match={history.location.pathname} className={cx('item', 'main')}>
          <HomeSVG />
          <span>Dashboard</span>
        </SidebarLink>
        <hr className="divider" />
        <button
          className={cx('item', 'main')}
          type="button"
          onClick={() => setExpandedMenuItem((prev) => ({ ...prev, missions: !prev.missions }))}
          css={css`padding: 0;`}
        >
          <MissionsSVG />
          <span>Missions</span>
          <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.missions ? '180' : '90'} />
        </button>
        <div css={css`${overlayMenuContainerCSS}`}>
          <CSSTransition
            in={expandedMenuItem.missions}
            timeout={150}
            classNames="drop-menu"
            unmountOnExit
          >
            <div>
              {meta.jobNotifications && (
                <SidebarLink to="/job-requests/incoming" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span>Capture Requests</span>
                </SidebarLink>
              )}
              {meta.permissions.includes('org:default') && (
                <SidebarLink to="/missions/mine" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span>My Missions</span>
                </SidebarLink>
              )}
              <SidebarLink to="/missions/shared" match={history.location.pathname} className={cx('item', 'sub')}>
                <span>Shared With Me</span>
              </SidebarLink>
              <SidebarLink to="/missions/organisation" match={history.location.pathname} className={cx('item', 'sub')}>
                <span>All Missions</span>
              </SidebarLink>
              <SidebarLink to="/missions/demo-missions" match={history.location.pathname} className={cx('item', 'sub')}>
                <span>Demo Missions</span>
              </SidebarLink>
            </div>
          </CSSTransition>
        </div>
        <hr className="divider" />
        {sites && <>
          <SidebarLink to="/sites" match={history.location.pathname} className={cx('item', 'main')}>
            <SitesSVG />
            <span>Sites</span>
          </SidebarLink>
          <hr className="divider" />
        </>}
        <button
          className={cx('item', 'main')}
          type="button"
          onClick={() => setExpandedMenuItem((prev) => ({ ...prev, inspection: !prev.inspection }))}
          css={css`padding: 0;`}
        >
          <InspectionSVG />
          <span>Inspection</span>
          <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.inspection ? '180' : '90'} />
        </button>
        <div css={css`${overlayMenuContainerCSS}`}>
          <CSSTransition
            in={expandedMenuItem.inspection}
            timeout={150}
            classNames="drop-menu"
            unmountOnExit
          >
            <div>
              <SidebarLink id="assessment-types" to="/assessment-types" match={history.location.pathname} className={cx('item', 'sub')}>
                <span css={css`margin-right: 0.25rem;`}>Assessment Types</span>
              </SidebarLink>
              <SidebarLink id="assessment-severity" to="/assessment-severity" match={history.location.pathname} className={cx('item', 'sub', 'severity-scale')}>
                <span>Assessment Severity</span>
              </SidebarLink>
              <SidebarLink to={inspection ? '/inspections' : '#'} match={history.location.pathname} className={cx('item', 'sub', { 'link-disabled': !inspection })}>
                <span id="inspection-summary">Inspection Summary</span>
              </SidebarLink>
            </div>
          </CSSTransition>
        </div>
        {canViewProcessing && (
          <>
            <hr className="divider" />
            <button
              className={cx('item', 'main')}
              type="button"
              onClick={() =>
                setExpandedMenuItem((prev) => ({ ...prev, processing: !prev.processing }))}
              css={css`padding: 0;`}
            >
              <BirdiIcon icon={Icons.PieChart} css={css`font-size: 1.5em !important;`} />
              <span>Processing</span>
              <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.processing ? '180' : '90'} />
            </button>
            <div css={css`${overlayMenuContainerCSS}`}>
              <CSSTransition
                in={expandedMenuItem.processing}
                timeout={150}
                classNames="drop-menu"
                unmountOnExit
              >
                <div>
                  <SidebarLink to="/processing" match={history.location.pathname} className={cx('item', 'sub')}>
                    <span>Processing Dashboard</span>
                  </SidebarLink>
                  {costCalculator && (
                    <SidebarLink to="/cost-calculator" match={history.location.pathname} className={cx('item', 'sub')}>
                      <span>Processing Cost Calculator</span>
                    </SidebarLink>
                  )}
                </div>
              </CSSTransition>
            </div>
          </>
        )}
        <hr className="divider" />
        <button
          className={cx('item', 'main')}
          type="button"
          onClick={() =>
            setExpandedMenuItem((prev) => ({ ...prev, companyInfo: !prev.companyInfo }))}
          css={css`padding: 0;`}
        >
          <CompanySVG />
          <span>Company Information</span>
          <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.companyInfo ? '180' : '90'} />
        </button>
        <div css={css`${overlayMenuContainerCSS}`}>
          <CSSTransition
            in={expandedMenuItem.companyInfo}
            timeout={150}
            classNames="drop-menu"
            unmountOnExit
          >
            <div>
              <SidebarLink to="/user/details" match={history.location.pathname} className={cx('item', 'sub')}>
                <span>Account</span>
              </SidebarLink>
              {manageUsers && (
                <SidebarLink to="/users" match={history.location.pathname} className={cx('item', 'sub')}>
                  <span>User Management</span>
                </SidebarLink>
              )}
              <SidebarLink to="/equipment" match={history.location.pathname} className={cx('item', 'sub')}>
                <span>Equipment</span>
              </SidebarLink>
              <SidebarLink to="/user/credentials" match={history.location.pathname} className={cx('item', 'sub')}>
                <span>Credentials</span>
              </SidebarLink>
            </div>
          </CSSTransition>
        </div>
        {(meta.permissions.includes('org:manage-org') || meta.opsActions.includes('access_ops')) && (
          <>
            <hr className="divider" />
            <button
              className={cx('item', 'main')}
              type="button"
              onClick={() =>
                setExpandedMenuItem((prev) => ({ ...prev, billing: !prev.billing }))}
              css={css`padding: 0;`}
            >
              <BillingSVG />
              <span>Billing & Subscription</span>
              <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.billing ? '180' : '90'} />
            </button>
            <div css={css`${overlayMenuContainerCSS}`}>
              <CSSTransition
                in={expandedMenuItem.billing}
                timeout={150}
                classNames="drop-menu"
                unmountOnExit
              >
                <div>
                  <SidebarLink to="/billing/plan" match={history.location.pathname} className={cx('item', 'sub')}>
                    <span>Plan Overview</span>
                  </SidebarLink>
                </div>
              </CSSTransition>
            </div>
            <hr className="divider" />
            <button
              className={cx('item', 'main')}
              type="button"
              onClick={() =>
                setExpandedMenuItem((prev) => ({ ...prev, settings: !prev.settings }))}
              css={css`padding: 0;`}
            >
              <SettingsSVG />
              <span>Settings</span>
              <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.settings ? '180' : '90'} />
            </button>
            <div css={css`${overlayMenuContainerCSS}`}>
              <CSSTransition
                in={expandedMenuItem.settings}
                timeout={150}
                classNames="drop-menu"
                unmountOnExit
              >
                <div>
                  <SidebarLink to="/settings/customisation/branding" match={history.location.pathname} className={cx('item', 'sub', { 'link-disabled': !customBranding })}>
                    <span id="custom-branding">Customization</span>
                  </SidebarLink>
                </div>
              </CSSTransition>
            </div>
          </>
        )}
        <hr className="divider" />
        <button
          className={cx('item', 'main')}
          type="button"
          onClick={() =>
            setExpandedMenuItem((prev) => ({ ...prev, help: !prev.help }))}
          css={css`padding: 0;`}
        >
          <HelpSVG />
          <span>Help</span>
          <BirdiIcon icon={Icons.Chevron} rotate={expandedMenuItem.help ? '180' : '90'} />
        </button>
        <CSSTransition
          in={expandedMenuItem.help}
          timeout={150}
          classNames="drop-menu"
          unmountOnExit
        >
          <div>
            <Link to={{ pathname: 'https://help.birdi.io/' }} target="_blank" className={cx('item', 'sub')}>
              <span>Knowledge Base</span>
              <ExternalLinkSVG className="icon-external" />
            </Link>
            <Link to={{ pathname: 'https://www.birdi.io/terms-and-conditions' }} target="_blank" className={cx('item', 'sub')}>
              <span>Terms & Conditions</span>
              <ExternalLinkSVG className="icon-external" />
            </Link>
          </div>
        </CSSTransition>
      </SideBar>
      <div css={(theme: BirdiTheme) => css`
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2.5em;
        z-index: 3;
        background-color: ${theme.mono0};
        // border-top: solid 1px ${theme.mono30};
        display: flex;
        align-items: center;
        justify-content: center;
      `}
      >
        <PoweredByBirdi mode="dark" />
      </div>
    </div>
  );
});

export default SideNavigation;
